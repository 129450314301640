import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { SubHead3, SubText } from "./FontStyle";
import Default from "../Assets/img/General/default.png";

const VillageImage = ({ props }) => {
  const [imgUrl, setImgUrl] = useState("");

  const getData = async (id) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${id}`;
    try {
      const response = await axios.get(url);
      return response.data.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    console.log(props);
    if (props?.thumbnail_name) {
      getData(props?.thumbnail_name).then((dd) => setImgUrl(dd));
    } else {
      setImgUrl(Default);
    }
  }, []);

  return (
    <>
      <Image
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundPosition: "center",
        }}
      />
    </>
  );
};

const HomeRecentVillage = (props) => {
  return (
    <Container>
      <VillageImage props={props?.item} />
      <div className="p-md-3 p-2">
        <SubHead3>
          <span>{props.Place} </span>
        </SubHead3>
        <SubText>{props.State}</SubText>
      </div>

      <StatusBar
        style={{
          display: "grid",
          gridTemplateColumns: `${props.Percent ? props.Percent : 0}% 1fr`,
        }}
      >
        <div
          style={{
            backgroundColor: "#75CF8F",
            gridColumn: 1,
          }}
        />
        <div
          style={{
            backgroundColor: "#C4C4C4",
            gridColumn: 2,
          }}
        />
      </StatusBar>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 0.75rem;
  overflow: hidden;
  background-color: white;
`;

const Image = styled.div`
  width: 100%;
  padding-bottom: 88%;
  background-size: cover;
`;

const StatusBar = styled.div`
  height: 0.4rem;
`;

export default HomeRecentVillage;
