import React from "react";
import styled from "styled-components";
import { Caption1, Heading1 } from "./FontStyle";

const HomeStatsCard = (props) => {
  return (
    <>
      <CardDiv
        className={`${props.className}`}
        style={{ backgroundImage: `url(${props.backgroundImage})` }}
      >
        <Heading1 id={props.numberId} className="text-white">
          {props.count ? props.count : 0}
        </Heading1>
        <Caption1 className="text-white">
          {props.captionText}
        </Caption1>
        <IconImage style={{ backgroundImage: `url(${props.icon})` }} />
      </CardDiv>
    </>
  )
}
export default HomeStatsCard;

const CardDiv = styled.div`
  border-radius: 0.75rem;
  background-size: cover;
  position: relative;
  overflow: hidden;
`;

const IconImage = styled.div`
  background-size: contain;
  height: 2.5rem;
  width: 2.5rem;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  @media only screen and (min-width: 768px) {
    height: 3rem;
    width: 3rem;
  }
`;
