import React, { useEffect, useState } from "react";
import firebase from "../../../firebase";
import MyLayout from "../../Components/MyLayout";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";

import FirstPlace from "../../Assets/img/LeaderBoard/FirstPlace.svg";
import FirstRank from "../../Assets/img/LeaderBoard/firstRank.svg";
import SecondRank from "../../Assets/img/LeaderBoard/secondRank.svg";
import ThirdRank from "../../Assets/img/LeaderBoard/thirdRank.svg";
import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import "../Leaderboard/index.css";
import axios from "axios";
import { Tooltip } from "@mui/material";

const ImageComponent = ({ id }) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/100x100/filters:quality(90)/images/${id}`;
        const response = await axios.get(url);
        const data = response?.data?.data;
        console.log(data);
        setImageData(data);
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    };

    fetchData();
  }, [id]);

  return imageData ? (
    <img
      alt="media-image"
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "50%",
      }}
      onError={(e) => {
        e.target.src = AvatarDefault;
      }}
      src={imageData}
    />
  ) : (
    ""
  );
};
class LeaderBoard extends React.Component {
  state = {
    leaderboardData: [],
    mobileLeaderboardData: [],
    loadMoredata: [],
    loadMoredataMobile: [],
    showViewMore: true,
  };
  componentDidMount = () => {
    this.fetchUserboard();
  };
  fetchUserboard = (params) => {
    this.setState({ loading: true });
    firebase
      .firestore()
      .collection("users")
      .orderBy("count", "desc")
      .limit(10)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          let list = [];
          snapshots.forEach((snapshot, index) => {
            list.push(snapshot.data());
          });

          // this.setState({ loadMoredata: list.slice(3, 7) });
          this.setState({ loadMoredataMobile: list.slice(1, 10) });
          this.setState({ leaderboardData: list, loading: false }, () => {});
        }
      });
  };

  numberPostion(n) {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  }

  handleViewMore = () => {
    this.setState({
      loadMoredata: this.state.leaderboardData.slice(10),
      showViewMore: false,
    });
    this.setState({
      loadMoredataMobile: this.state.leaderboardData.slice(1),
      showViewMore: false,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <MyLayout loading={this.state.loading}>
        <MyDesktop>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h1 className="th-40 fw-700 th-fair-play text-success">
              Mera Gaon Meri Dharohar
            </h1>
            <h1 className="th-20 fw-700">Leaderboard</h1>
            <p style={{ fontStyle: "italic", textAlign: "center" }}>
              Want to know who is leading the way in having visited the most
              Indian villages? Find out here.
            </p>
          </div>
          <div className="col-md-12  th-leaderboard-height-scroll pb-3">
            <div className="d-flex justify-content-center text-center pt-3 pb-5">
              <div className="text-center pb-4" style={{ width: "70%" }}>
                <div className="mb-2 th-bg-white th-br-12 first-part">
                  <div className="d-flex py-3 px-4 justify-content-between align-items-center leaderboard-design">
                    <div className="part-one-desk d-flex align-items-center">
                      <div className="pr-3">
                        <div
                          className="d-flex flex-column"
                          style={{ width: "24px", height: "24px" }}
                        >
                          <div
                            className="th-primary th-fw-600"
                            style={{ lineHeight: "normal", fontSize: "14px" }}
                          >
                            1st
                          </div>
                        </div>
                      </div>
                      <div className="image-part">
                        <div className="image-inner">
                          <div
                            className={
                              `text-center mx-auto rounded-circle leader leader-image ` +
                              (this.state.leaderboardData &&
                              this.state.leaderboardData.length > 0 &&
                              localStorage.getItem("user")?.email ===
                                this.state.leaderboardData[0]?.values?.email
                                ? "border border-success"
                                : "")
                            }
                            style={{
                              backgroundPosition: "center",
                              width: "80px",
                              height: "80px",
                              backgroundRepeat: "no-repeat",
                              marginLeft: "50px",
                              marginRight: "20px",
                            }}
                          >
                            <ImageComponent
                              id={
                                this.state.leaderboardData
                                  ? this.state.leaderboardData[0]?.avatar
                                    ? this.state.leaderboardData[0]?.avatar
                                    : AvatarDefault
                                  : AvatarDefault
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-left th-16 th-fw-500 th-grey-4 text-part">
                        {this.state.leaderboardData &&
                          this.state.leaderboardData.length > 0 &&
                          this.state.leaderboardData[0]?.values?.name}
                      </div>
                    </div>

                    <div className="part-two-1">
                      <img
                        src={FirstRank}
                        alt="first rank"
                        className="part-img"
                      />
                      <div
                        className="text-left th-fw-500 th-grey-5"
                        style={{
                          paddingLeft: "7px",
                          marginTop: "3px",
                          fontSize: "12px",
                        }}
                      >
                        @
                        {this.state.leaderboardData &&
                          this.state.leaderboardData.length > 0 &&
                          this.state.leaderboardData[0]?.values?.email.split(
                            "@"
                          )[0]}
                      </div>
                      <div className="ml-auto th-primary th-14 th-fw-600">
                        {" "}
                        {this.state.leaderboardData &&
                          this.state.leaderboardData.length > 0 &&
                          this.state.leaderboardData[0]?.count}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  key={
                    this.state.leaderboardData &&
                    this.state.leaderboardData.length > 1 &&
                    this.state.leaderboardData[1].uid
                  }
                  className={
                    `mb-2 th-bg-white th-br-12 second-part` +
                    (this.state.leaderboardData &&
                    this.state.leaderboardData.length > 1 &&
                    localStorage.getItem("user")?.email ===
                      this.state.leaderboardData[1]?.values.email
                      ? "border border-success"
                      : "")
                  }
                >
                  <div className="d-flex py-3 px-4 justify-content-between align-items-center leaderboard-design">
                    <div className="part-one-desk d-flex align-items-center">
                      <div className="pr-3">
                        <div
                          className="d-flex flex-column"
                          style={{ width: "24px", height: "24px" }}
                        >
                          <div
                            className="th-primary th-fw-600"
                            style={{ lineHeight: "normal", fontSize: "14px" }}
                          >
                            2nd
                          </div>
                        </div>
                      </div>
                      <div
                        className="text-center rounded-circle leader2 web-part"
                        style={{
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          width: "40px",
                          height: "40px",
                          backgroundRepeat: "no-repeat",
                          marginLeft: "50px",
                          marginRight: "20px",
                        }}
                      >
                        <ImageComponent
                          id={
                            this.state.leaderboardData
                              ? this.state.leaderboardData[1]?.avatar
                                ? this.state.leaderboardData[1]?.avatar
                                : AvatarDefault
                              : AvatarDefault
                          }
                        />
                      </div>
                      <div className="text-left th-16 th-fw-500 th-grey-4">
                        {this.state.leaderboardData?.length > 1
                          ? this.state.leaderboardData[1]?.values?.name
                          : "-"}
                      </div>
                    </div>
                    <div className="part-two-1">
                      <img
                        src={SecondRank}
                        alt="second rank"
                        className="part-img"
                      />
                      <div
                        className="text-left th-fw-500 th-grey-5"
                        style={{
                          paddingLeft: "7px",
                          marginTop: "3px",
                          fontSize: "12px",
                        }}
                      >
                        @
                        {this.state.leaderboardData?.length > 1
                          ? this.state.leaderboardData[1]?.values?.email.split(
                              "@"
                            )[0]
                          : ""}
                      </div>
                      <div className="ml-auto th-primary th-14 th-fw-600">
                        {this.state.leaderboardData &&
                          this.state.leaderboardData.length > 1 &&
                          this.state.leaderboardData[1]?.count}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  key={
                    this.state.leaderboardData &&
                    this.state.leaderboardData.length > 1 &&
                    this.state.leaderboardData[2].uid
                  }
                  className={
                    `mb-2 th-bg-white th-br-12 thired-part ` +
                    (this.state.leaderboardData &&
                    this.state.leaderboardData.length > 1 &&
                    localStorage.getItem("user")?.email ===
                      this.state.leaderboardData[2]?.values.email
                      ? "border border-success"
                      : "")
                  }
                >
                  <div className="d-flex py-3 px-4 justify-content-between align-items-center leaderboard-design">
                    <div className="part-one-desk d-flex align-items-center">
                      <div className="pr-3">
                        <div
                          className="d-flex flex-column"
                          style={{ width: "24px", height: "24px" }}
                        >
                          <div
                            className="th-primary th-fw-600"
                            style={{ lineHeight: "normal", fontSize: "14px" }}
                          >
                            3rd
                          </div>
                        </div>
                      </div>
                      <div
                        className="text-center rounded-circle leader2 web-part"
                        style={{
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          width: "40px",
                          height: "40px",
                          backgroundRepeat: "no-repeat",
                          marginLeft: "50px",
                          marginRight: "20px",
                        }}
                      >
                        <ImageComponent
                          id={
                            this.state.leaderboardData
                              ? this.state.leaderboardData[2]?.avatar
                                ? this.state.leaderboardData[2]?.avatar
                                : AvatarDefault
                              : AvatarDefault
                          }
                        />
                      </div>
                      <div className="text-left th-16 th-fw-500 th-grey-4">
                        {this.state.leaderboardData &&
                        this.state.leaderboardData.length > 2 &&
                        this.state.leaderboardData[2]?.values?.name
                          ? this.state.leaderboardData[2]?.values?.name
                          : "-"}
                      </div>
                    </div>
                    <div className="part-two-1">
                      <img
                        src={ThirdRank}
                        alt="Thired rank"
                        className="part-img"
                      />
                      <div
                        className="text-left th-fw-500 th-grey-5"
                        style={{
                          paddingLeft: "7px",
                          marginTop: "3px",
                          fontSize: "12px",
                        }}
                      >
                        @
                        {this.state.leaderboardData &&
                          this.state.leaderboardData.length > 2 &&
                          this.state.leaderboardData[2]?.values?.email.split(
                            "@"
                          )[0]}
                      </div>
                      <div className="ml-auto th-primary th-14 th-fw-600">
                        {this.state.leaderboardData &&
                          this.state.leaderboardData.length > 1 &&
                          this.state.leaderboardData[2]?.count}
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.leaderboardData &&
                  this.state.leaderboardData.length > 0 &&
                  this.state.leaderboardData.slice(3, 10)?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          `mb-2 th-bg-white th-br-12 ` +
                          (localStorage.getItem("user")?.email ===
                          item.values?.email
                            ? "border border-success"
                            : "")
                        }
                      >
                        <div className="d-flex py-3 px-4 justify-content-between align-items-center leaderboard-design">
                          <div className="art-one-desk d-flex align-items-center part-one-desk">
                            <div className="pr-3">
                              <div
                                className="d-flex flex-column"
                                style={{ width: "24px", height: "24px" }}
                              >
                                <div
                                  className="th-primary th-fw-600"
                                  style={{
                                    lineHeight: "normal",
                                    fontSize: "14px",
                                  }}
                                >
                                  {i + 4}
                                  {this.numberPostion(i + 4)}
                                </div>
                              </div>
                            </div>
                            <div
                              className="text-center rounded-circle leader2 web-part"
                              style={{
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                width: "40px",
                                height: "40px",
                                backgroundRepeat: "no-repeat",
                                marginLeft: "50px",
                                marginRight: "20px",
                              }}
                            >
                              {" "}
                              <ImageComponent
                                id={item?.avatar ? item?.avatar : AvatarDefault}
                              />
                            </div>
                            <div className="text-left th-16 th-fw-500 th-grey-4">
                              {item?.values?.name}
                            </div>
                            <div></div>
                          </div>
                          <div className="part-two-desk">
                            <div></div>
                            <div
                              className="text-left th-fw-500 th-grey-5"
                              style={{
                                paddingLeft: "7px",
                                marginTop: "3px",
                                fontSize: "12px",
                              }}
                            >
                              @{item?.values?.email.split("@")[0]}
                            </div>
                            <div className="ml-auto th-primary th-14 th-fw-600">
                              {item?.count}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {this.state.loadMoredata &&
                  this.state.loadMoredata.length > 0 &&
                  this.state.loadMoredata?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          `mb-2 th-bg-white th-br-12 ` +
                          (localStorage.getItem("user")?.email ===
                          item.values?.email
                            ? "border border-success"
                            : "")
                        }
                      >
                        <div className="d-flex py-3 px-4 justify-content-between align-items-center leaderboard-design">
                          <div className="art-one-desk d-flex align-items-center part-one-desk">
                            <div className="pr-3">
                              <div
                                className="d-flex flex-column"
                                style={{ width: "24px", height: "24px" }}
                              >
                                <div
                                  className="th-primary th-fw-600"
                                  style={{
                                    lineHeight: "normal",
                                    fontSize: "14px",
                                  }}
                                >
                                  {i + 11}
                                  {this.numberPostion(i + 4)}
                                </div>
                              </div>
                            </div>
                            <div
                              className="text-center rounded-circle leader2 web-part"
                              style={{
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                width: "40px",
                                height: "40px",
                                backgroundRepeat: "no-repeat",
                                marginLeft: "50px",
                                marginRight: "20px",
                              }}
                            >
                              {" "}
                              <ImageComponent
                                id={item?.avatar ? item?.avatar : AvatarDefault}
                              />
                            </div>
                            <div className="text-left th-16 th-fw-500 th-grey-4">
                              {item?.values?.name}
                            </div>
                            <div></div>
                          </div>
                          <div className="part-two-desk">
                            <div></div>
                            <div
                              className="text-left th-fw-500 th-grey-5"
                              style={{
                                paddingLeft: "7px",
                                marginTop: "3px",
                                fontSize: "12px",
                              }}
                            >
                              @{item?.values?.email.split("@")[0]}
                            </div>
                            <div className="ml-auto th-primary th-14 th-fw-600">
                              {item?.count}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {this.state.leaderboardData &&
                this.state.leaderboardData?.length > 10 &&
                this.state.showViewMore ? (
                  <div
                    className="d-flex justify-content-center align-items-center text-center th-fw-500 th-grey-5 th-pointer"
                    onClick={this.handleViewMore}
                  >
                    <div>View More</div>
                    <i className="fa fa-angle-down px-3 "></i>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </MyDesktop>
        <MyMobile loading={this.state.loading}>
          <div className="col-12 th-90-mobile-scroll pt-3">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 className="th-25 fw-700 th-fair-play text-success">
                Mera Gaon Meri Dharohar
              </h1>
              <h1 className="th-20 fw-700">Leaderboard</h1>
              <p style={{ fontStyle: "italic", textAlign: "center" }}>
                Want to know who is leading the way in having visited the most
                Indian villages? Find out here.
              </p>
            </div>
            <div className="pt-2 mb-5 pb-4 searchcard ">
              <div
                key={
                  this.state.leaderboardData &&
                  this.state.leaderboardData[0]?.uid
                }
                className={`mb-2 th-bg-white th-br-12 first-part `}
              >
                <div className="d-flex py-3 px-4 justify-content-between align-items-center leaderboard-design">
                  <div className="part-one d-flex align-items-center">
                    <div className="pr-4">
                      <div
                        className="d-flex flex-column"
                        style={{
                          width: "20px",
                          height: "20px",
                          alignItems: "center",
                          marginLeft: "-6px",
                        }}
                      >
                        <div
                          className="th-primary th-fw-600"
                          style={{
                            lineHeight: "normalloadMoredataMobile",
                            fontSize: "12px",
                          }}
                        >
                          1st
                        </div>
                      </div>
                    </div>
                    <div
                      className="text-center rounded-circle leader2 web-part"
                      style={{
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        width: "30px",
                        height: "30px",
                        backgroundRepeat: "no-repeat",
                        marginRight: "10px",
                      }}
                    >
                      {" "}
                      <ImageComponent
                        id={
                          this.state.leaderboardData &&
                          this.state.leaderboardData[0]?.avatar
                            ? this.state.leaderboardData[0]?.avatar
                            : AvatarDefault
                        }
                      />
                    </div>
                    <div>
                      <div className="name text-left th-16 th-fw-500 th-grey-4">
                        {this.state.leaderboardData &&
                          this.state.leaderboardData.length > 0 &&
                          this.state.leaderboardData[0]?.values?.name}
                      </div>
                    </div>
                  </div>
                  <div className="part-two-1">
                    <img
                      src={FirstRank}
                      alt="first rank"
                      className="part-img"
                    />
                    <div
                      className="email text-left th-fw-500 th-grey-5"
                      style={{ fontSize: "10px" }}
                    >
                      @
                      {this.state.leaderboardData &&
                        this.state.leaderboardData[0]?.values?.email?.split(
                          "@"
                        )[0]}
                    </div>
                    <div className="ml-auto th-primary th-14 th-fw-600">
                      {this.state.leaderboardData &&
                        this.state.leaderboardData[0]?.count}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.loadMoredataMobile &&
                this.state.loadMoredataMobile.length > 0 &&
                this.state.loadMoredataMobile?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        `mb-2 th-bg-white th-br-12 ` +
                        (localStorage.getItem("user")?.email ===
                        item.values?.email
                          ? "border border-success "
                          : " ") +
                        (i === 0 ? `second-part ` : "") +
                        (i === 1 ? `thired-part ` : "")
                      }
                    >
                      <div className="d-flex py-3 px-4 justify-content-between align-items-center leaderboard-design">
                        <div className="part-one d-flex align-items-center">
                          <div className="pr-3">
                            <div
                              className="d-flex flex-column"
                              style={{
                                width: "20px",
                                height: "20px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="th-primary th-fw-600"
                                style={{
                                  lineHeight: "normal",
                                  fontSize: "12px",
                                }}
                              >
                                {i + 2}
                                {this.numberPostion(i + 2)}
                              </div>
                            </div>
                          </div>
                          <div
                            className="text-center rounded-circle leader2 web-part"
                            style={{
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              width: "30px",
                              height: "30px",
                              backgroundRepeat: "no-repeat",
                              marginLeft: "2px",
                              marginRight: "10px",
                            }}
                          >
                            {" "}
                            <ImageComponent
                              id={item?.avatar ? item?.avatar : AvatarDefault}
                            />
                          </div>
                          <div>
                            <div className="name text-left th-16 th-fw-500 th-grey-4">
                              {item?.values?.name}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`part-two${
                            i < 2 ? "-1" : " mobile-leader-part"
                          }`}
                        >
                          {i < 2 && (
                            <>
                              <img
                                src={i === 0 ? SecondRank : ThirdRank}
                                alt="second rank"
                                className="part-img web-part"
                              />
                              <div
                                className="email text-left th-fw-500 th-grey-5"
                                style={{ fontSize: "10px" }}
                              >
                                @
                                {item?.values?.email
                                  ? item?.values?.email.split("@")[0]
                                  : "-"}
                              </div>
                            </>
                          )}
                          {i >= 2 && (
                            <>
                              <div></div>
                              <div
                                className="remain-list text-left th-fw-500 th-grey-5"
                                style={{ fontSize: "10px" }}
                              >
                                @
                                {item?.values?.email
                                  ? item?.values?.email.split("@")[0]
                                  : "-"}
                              </div>
                            </>
                          )}
                          <div className="ml-auto th-primary th-14 th-fw-600">
                            {item?.count}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {this.state.loadMoredataMobile &&
              this.state.loadMoredataMobile.length > 9 &&
              this.state.showViewMore ? (
                <div
                  className="d-flex justify-content-center align-items-center text-center th-fw-500 th-grey-5 th-pointer"
                  onClick={this.handleViewMore}
                >
                  <div>View More</div>
                  <i className="fa fa-angle-down px-3 "></i>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </MyMobile>
      </MyLayout>
    );
  }
}

export default LeaderBoard;
