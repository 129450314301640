import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import firebase from "../../../firebase";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import styled from "styled-components";

import MyDesktop from "../MyComponents/MyDesktop";
import MyMobile from "../MyComponents/MyMobile";
import Dropdown from "react-bootstrap/Dropdown";

import LogoWhite from "../../../App/Assets/img/HeaderLogos/LogoWhite.svg";
import mocLogoW from "../../../App/Assets/img/HeaderLogos/mocLogoW.svg";
import IgncaLogoW from "../../../App/Assets/img/HeaderLogos/Ignca-white.svg";
import akamLogoW from "../../../App/Assets/img/HeaderLogos/akamLogoW.svg";
import mocLogo from "../../../App/Assets/img/HeaderLogos/mocLogo.svg";
import akamLogo from "../../../App/Assets/img/HeaderLogos/akamLogo.svg";
import IgncaLogo from "../../../App/Assets/img/HeaderLogos/IgncaLogo.png";
import LogoBlack from "../../../App/Assets/img/HeaderLogos/LogoBlack.svg";
import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import "./header.scss";
import SideHeader from "../SideHeader";
import Footer from "../Footer";
import axios from "axios";
import g20 from "../../../App/Assets/img/General/g20.png";
import DownloadButton from "../Downloadcomponent";
class Header extends Component {
  state = {
    drawerOpen: false,
    isDropDown: false,
    fireuser: null,
    loadingLanguage: true,
    width: "",
    user_data: {},
    showToaster: false,
    isUser: localStorage.getItem("userType"),
  };

  handleLanguage = () => {
    this.setState({ isDropDown: !this.state.isDropDown });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document
      .querySelector(".th-90-height-scroll")
      ?.removeEventListener("scroll", this.handleScroll);
    document
      .querySelector("body")
      ?.removeEventListener("scroll", this.handleScroll);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleScroll(event) {
    if (document.querySelector("div#dropToggle")?.classList?.contains("show")) {
      document.querySelector(".dropdown-toggle.btn").click();
    }
  }

  componentDidMount = () => {
    this.fetchStatData();
    window.addEventListener("resize", this.updateWindowDimensions);
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("userdata"));
      this.setState({ fireuser: user });
    }
    document
      .querySelector(".th-90-height-scroll")
      ?.addEventListener("scroll", this.handleScroll);
    document
      .querySelector("body")
      ?.addEventListener("scroll", this.handleScroll);
    window.addEventListener("storage", (e) => {
      try {
        let user = JSON.parse(localStorage.getItem("userdata"));
        this.setState({ fireuser: user });
      } catch (e) {}
    });
  };

  handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ showToaster: true });
        // history.push("/")
        setTimeout(() => {
          localStorage.clear();

          localStorage.removeItem("user");
          localStorage.removeItem("userdata");
          localStorage.removeItem("userType");
          localStorage.removeItem("village-visited");
          window.location.href = "/";
          this.setState({ showToaster: false });
        }, 500);
      });
  };

  fetchStatData = async () => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).uid
    ) {
      await firebase
        .firestore()
        .collection("users")
        .doc(
          localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).uid
        )
        .get()
        .then(async (doc) => {
          let docData = doc.data();
          if (docData?.avatar) {
            try {
              const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/100x100/images/${docData.avatar}`;
              const response = await axios.get(url);
              const data = response?.data?.data;
              docData.avatar = data;
              this.setState({ user_data: docData });
            } catch (error) {
              console.error("Error fetching image data:", error);
            }
          } else {
            this.setState({ user_data: docData });
          }
        });
    }
  };

  handleName = () => {
    let user = JSON.parse(localStorage.getItem("userdata"));
    this.setState({ fireuser: user });
  };
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ToastContainer position="top-end" className="p-3">
          <Toast
            onClose={() => this.setState({ showToaster: false })}
            show={this.state.showToaster}
            className="text-white th-bg-primary"
          >
            <Toast.Body>
              {this.state.isUser === "artist" ? "Artist" : "User"} logged out
              successfully.
            </Toast.Body>
          </Toast>
        </ToastContainer>
        {/* DESKTOP HEADER SECTION */}
        <MyDesktop>
          <div
            className={
              "fixed-top " + (this.props.transparent ? "" : "bg-white")
            }
            style={{ height: "auto" }}
          >
            <>
              {window.location.pathname != "/signup" &&
                window.location.pathname != "/login" && <></>}
            </>
            <div className="container-fluid">
              <div className="row mb-2 py-2">
                <div
                  className={`col-12 ${
                    this.props.transparent
                      ? "header-transparent-part"
                      : "header-part"
                  }`}
                >
                  {this.props.transparent ? (
                    <div className="d-flex align-items-center mt-2">
                      <div
                        className="my-auto py-2 logoSection"
                        style={{ marginLeft: "1%" }}
                      >
                        <Link to="/explore">
                          <img
                            alt="mocLogoW"
                            className="my-auto img-fluid mx-3 cursor-pointer tablet-responsive last-image"
                            src={mocLogoW}
                            style={{ height: 40 }}
                          />
                        </Link>
                        <Link to="/explore">
                          <img
                            alt="LogoBlack"
                            className="my-auto img-fluid mx-2 tablet-responsive"
                            src={g20}
                            style={{ height: 40 }}
                          />
                        </Link>
                      </div>

                      <Link to="/explore">
                        <img
                          alt="akamLogoW"
                          className="my-auto img-fluid mx-2 tablet-responsive"
                          src={akamLogoW}
                          style={{
                            height: 40,
                          }}
                        />
                      </Link>
                      <a href="https://ignca.gov.in/">
                        <img
                          alt="logo"
                          className="my-auto img-fluid mx-2 tablet-responsive"
                          src={IgncaLogoW}
                          style={{
                            height: 40,
                          }}
                        />
                      </a>

                      <div
                        className="row align-items-center my-2 py-2"
                        style={{ marginRight: "1%" }}
                      ></div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="my-auto py-2 logoSection">
                        <Link to="/explore">
                          <img
                            alt="mocLogo"
                            className="my-auto img-fluid mx-2 tablet-responsive"
                            src={mocLogoW}
                            style={{
                              height: 35,
                            }}
                          />
                        </Link>
                        <Link to="/explore">
                          <img
                            alt="Logo"
                            className="my-auto mx-2 img-fluid tablet-responsive"
                            src={g20}
                            style={{
                              height: 35,
                            }}
                          />
                        </Link>
                        <Link to="/explore">
                          <img
                            alt="akamLogo"
                            className="my-auto img-fluid mx-3 cursor-pointer tablet-responsive last-image"
                            src={akamLogoW}
                            style={{
                              height: 35,
                            }}
                          />
                        </Link>
                        <a href="https://ignca.gov.in/">
                          <img
                            alt="IgncaLogo"
                            className="my-auto img-fluid mx-2 tablet-responsive"
                            src={IgncaLogoW}
                            style={{
                              height: 40,
                              paddingTop: 5,
                              paddingRight: 10,
                            }}
                          />
                        </a>
                      </div>
                      {window.innerWidth > 1130 ? <SideHeader /> : <Footer />}
                      <div
                        className="row align-items-center my-2 py-2"
                        style={{ marginRight: "1%" }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </MyDesktop>
        {/* MONILE HEADER SECTION */}

        <MyMobile>
          <div
            className={
              "fixed-top " + (this.props.transparent ? "" : "bg-white")
            }
          >
            {window.location.pathname.includes("/signup") ||
            window.location.pathname.includes("/login") ? (
              ""
            ) : (
              <></>
            )}
            <div
              className="container"
              style={{ maxWidth: this.state.width <= 767 ? "unset" : "" }}
            >
              <div
                className={`row align-items-center ${
                  this.props.transparent ? "header-transparent-part" : ""
                }`}
              >
                {this.props.transparent ? (
                  <div className=" col-12 py-3 d-flex align-item-center mt-2">
                    <div className="my-auto text-nowrap d-flex">
                      <Link to="/explore">
                        <img
                          alt="mocLogoW"
                          className="my-auto img-fluid mx-1"
                          src={mocLogoW}
                          style={{ height: "27px" }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="LogoWhite"
                          className="my-auto img-fluid logo"
                          src={g20}
                          style={{ height: "27px" }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="akamLogoW"
                          className="my-auto mx-1 img-fluid"
                          src={akamLogoW}
                          style={{ height: "27px" }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="logo"
                          className="my-auto img-fluid mx-1 tablet-responsive"
                          src={IgncaLogoW}
                          style={{ height: "27px" }}
                        />
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className=" col-12 py-3 d-flex align-item-center">
                    <div className="my-auto text-nowrap">
                      <Link to="/explore">
                        <img
                          alt="mocLogo"
                          className="my-auto img-fluid mx-1"
                          src={this.props.transparent ? mocLogoW : mocLogoW}
                          style={{ height: "24px" }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="Logo"
                          className="my-auto img-fluid"
                          src={this.props.transparent ? g20 : g20}
                          style={{ height: "23px" }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="akamLogo"
                          className="my-auto img-fluid mx-1"
                          src={akamLogoW}
                          style={{ height: "24px" }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="IgncaLogo"
                          className="my-auto img-fluid mx-1 tablet-responsive"
                          src={IgncaLogoW}
                          style={{ height: 35, paddingRight: 0 }}
                        />
                      </Link>
                    </div>
                    <></>
                  </div>
                )}
              </div>
            </div>
          </div>
        </MyMobile>
      </React.Fragment>
    );
  }
}
const ProfileImage = styled.div`
  margin: 0 auto;
  height: 3rem;
  width: 3rem;
  border: solid 1px #f0f0f3;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  justify-self: center;
  align-self: center;
  box-shadow: -4.4012px -4.4012px 17.6048px #ffffff,
    4.4012px 4.4012px 17.6048px rgba(174, 174, 192, 0.5);
  grid-row: 1;
`;
export default withRouter(Header);
