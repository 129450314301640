import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import LeaderboardIcon from "../../Assets/img/sidebar/leaderboard.svg";
import DiscoverIcon from "../../Assets/img/sidebar/explore.svg";
import VideoGall from "../../Assets/img/sidebar/VideoGall.svg";
import DiscoverIconColored from "../../Assets/img/sidebar/explore-colored.svg";
import VideoColored from "../../Assets/img/sidebar/VideoColored.svg";
import LeaderboardIconColored from "../../Assets/img/sidebar/leaderboard-colored.svg";
import Vectormapsearch from "../../Assets/img/sidebar/Vectormapsearch.svg";
import Vectortintedmapsearch from "../../Assets/img/sidebar/VectortintedMapSearch.svg";
import Headerlogo from "../../Assets/img/Artist/Headerlogo.svg";
import Headerlogocolor from "../../Assets/img/Artist/Headerlogocolor.svg";
import GoodStories from "../../Assets/img/sidebar/good-stories.svg";
import GoodStoriesColored from "../../Assets/img/sidebar/good-stories-colored.svg";

let FooterData = [
  {
    id: 0,
    text: "Explore",
    icon: DiscoverIcon,
    iconActive: DiscoverIconColored,
    to: "/explore",
  },
  {
    id: 9,
    text: "Virtual Tours",
    icon: VideoGall,
    iconActive: VideoColored,
    to: "/video-gallery",
  },
  {
    id: 10,
    text: "Unique Stories",
    icon: GoodStories,
    iconActive: GoodStoriesColored,
    to: "/good-stories",
  },
  {
    id: 1,
    text: "Leaderboard",
    icon: LeaderboardIcon,
    iconActive: LeaderboardIconColored,
    to: "/leaderboard",
  },
  {
    id: 5,
    text: "Search",
    icon: Vectormapsearch,
    iconActive: Vectortintedmapsearch,
    to: "/searchonmap",
  },
];

const Footer = () => {
  const [currentTab, setCurrentTab] = useState(2);

  useEffect(() => {
    if (
      window.location.pathname.includes("/explore") ||
      window.location.pathname.includes("/village-details")
    ) {
      setCurrentTab(0);
    } else if (window.location.pathname.includes("/leaderboard")) {
      setCurrentTab(1);
    } else if (window.location.pathname.includes("/home")) {
      setCurrentTab(2);
    } else if (
      window.location.pathname.includes("/profile") ||
      window.location.pathname.includes("/artist-profile")
    ) {
      setCurrentTab(4);
    } else if (window.location.pathname.includes("/searchonmap")) {
      setCurrentTab(5);
    } else if (
      window.location.pathname.includes("/artist-explore") ||
      window.location.pathname.includes("/view-profile")
    ) {
      setCurrentTab(6);
    } else if (window.location.pathname.includes("/art-gallery")) {
      setCurrentTab(8);
    } else if (window.location.pathname.includes("/video-gallery")) {
      setCurrentTab(9);
    } else if (window.location.pathname.includes("/good-stories")) {
      setCurrentTab(10);
    }
  }, [currentTab]);

  return (
    <>
      <React.Fragment>
        <div className="w-100 fixed-bottom container-fluid th-footer">
          <div className="row py-2">
            {FooterData.map((item, index) => (
              <>
                {localStorage.getItem("user") ? (
                  <div className="col" key={index}>
                    <Link
                      to={
                        item.id === 4
                          ? localStorage.getItem("user")
                            ? "/profile"
                            : "/login"
                          : item.id === 1
                          ? localStorage.getItem("user")
                            ? "/leaderboard"
                            : "/login"
                          : item.id === 2
                          ? localStorage.getItem("user")
                            ? "/home"
                            : "/login"
                          : item.to
                      }
                    >
                      <div className="th-pointer text-center">
                        <img
                          className="img-fluid"
                          style={{ width: "25px", height: "25px" }}
                          src={
                            currentTab === item.id
                              ? item.iconActive
                              : item.id === 9
                              ? currentTab !== item.id
                                ? VideoGall
                                : VideoColored
                              : item.icon
                          }
                        />
                        <div
                          className={
                            "th-12  " +
                            (currentTab === item.id
                              ? " th-primary"
                              : item.id === 5
                              ? " th-grey-3 pt-1"
                              : item.id === 6
                              ? " th-grey-3 "
                              : " th-grey-3")
                          }
                        >
                          {item.text}
                        </div>
                      </div>
                    </Link>
                  </div>
                ) : item.id !== 4 && item.id !== 2 ? (
                  <div className="col" key={index}>
                    <Link
                      to={
                        item.id === 1
                          ? localStorage.getItem("user")
                            ? "/leaderboard"
                            : "/login"
                          : item.id === 6
                          ? localStorage.getItem("user")
                            ? "/artist-explore"
                            : "/login"
                          : item.to
                      }
                    >
                      <div className="th-pointer text-center">
                        <img
                          className="img-fluid"
                          style={{ width: "25px", height: "25px" }}
                          src={
                            currentTab === item.id ? item.iconActive : item.icon
                          }
                        />
                        <div
                          className={
                            "th-12  " +
                            (currentTab === item.id
                              ? " th-primary"
                              : item.id === 5
                              ? " th-grey-3 pt-1"
                              : item.id === 6
                              ? " th-grey-3 "
                              : " th-grey-3")
                          }
                        >
                          <p
                            className={
                              item.id === 5 ? "custom-padding-for-search" : ""
                            }
                          >
                            {item.text}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default Footer;
