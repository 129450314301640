import React from "react";
import styled from "styled-components";
import { Caption1 } from "./FontStyle";

const HomeIconCard = ({ icon, title }) => {
  return (
    <>
      <CardDiv className="bg-white" style={{ backgroundImage: `url(${icon})` }}>
        <TextDiv className="w-100 px-2">
          <Caption1 className="text-center">{title}</Caption1>
        </TextDiv>
      </CardDiv>
    </>
  );
};
export default HomeIconCard;

const CardDiv = styled.div`
  border-radius: 0.75rem;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 80%;
  @media (max-width: 1400px) {
    padding-bottom: 80%;
  }
  @media (max-width: 1382px) {
    padding-bottom: 80%;
  }
  @media (max-width: 1322px) {
    padding-bottom: 88%;
  }
  @media (max-width: 1140px) {
    padding-bottom: 93%;
  }
`;

const TextDiv = styled.div`
  position: absolute;
  bottom: 0.5rem;
`;
