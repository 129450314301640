
import { Link } from "react-router-dom";
import MyLayout from "../../Components/MyLayout/index";
import ExploreImg from "../../Assets/img/General/explore.svg";

const Achievements = () => {
  return (
    <>
      <MyLayout>
        <>
          <div className="col-md-2 p-3">
            <h5>KM's Travelled</h5>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <div
              className="th-bg-white p-3 th-box-shadow th-br-15"
              style={{ height: "50vh" }}
            >
              <div className="row">
                <div className="col-6  th-fw-600 th-16">Travelled</div>
                <div className="col-6 text-right th-fw-400 th-lt-blue">
                  12.5%
                </div>
                <div className="col-6 th-grey-4">Out of Total</div>
                <div className="col-6 text-right th-fw-400 th-lt-blue">
                  50,000 Kilometers
                </div>
              </div>
              <div className="col-12 th-fw-500 text-center text-uppercase">
                Kilometers
              </div>
              <div className="col-12 text-center th-fw-500 text-uppercase">
                Travelled
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-3 th-12">
            <Link to="/explore">
              {" "}
              <button className="th-bg-green th-white border-0 th-br-3 px-3 py-2">
                <span>
                  <img src={ExploreImg} alt="ExploreImg" height="18px" />
                </span>
                <span className="pl-2 th-16">Explore</span>
              </button>
            </Link>
          </div>
        </>
      </MyLayout>
    </>
  )

}
export default Achievements;
