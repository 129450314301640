import React from 'react';
import OverviewCard from './OverviewCard';

const NewCard = ({ topicsData, keyy, villageData, jsonField, subtitle, title, icon }) => {
    return (
      <>
        {!topicsData || /^(na|-|no|n\/a)$/i.test(topicsData) ? (
          <div className="col-lg-6 col-md-12 col-md-12">
            <div className="bedu my-2 primary-part">
              <OverviewCard
                village_key={keyy}
                village_name={villageData.data.village_name}
                blockName={villageData.data?.block_name}
                districtName={villageData.data?.district_name}
                jsonField={jsonField}
                subtitle={subtitle}
                title={title}
                total_people={"No Data Available"}
                state_name={villageData.data.state_name}
                answer={"No Data Available"}
                icon={icon}
              />
            </div>
          </div>
        ) : (
          <div className="col-lg-6 col-md-12 col-md-12">
            <div className="bedu my-2  primary-part">
              <OverviewCard
                village_key={keyy}
                village_name={villageData.data.village_name}
                blockName={villageData.data?.block_name}
                districtName={villageData.data?.district_name}
                jsonField={jsonField}
                subtitle={subtitle}
                title={title}
                total_people={topicsData}
                state_name={villageData.data.state_name}
                answer={topicsData}
                icon={icon}
              />
            </div>
          </div>
        )}
      </>
    );
}

export default NewCard;