import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./index.scss";
import transparent from "./../../Assets/img/General/transparent.png";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const YoutubeEmbed = ({ embedId, thumbnail }) => {
  const iframeRef = useRef(null);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    iframeRef.current.addEventListener("load", onIframeLoad);

    function onIframeLoad(event) {
      setLoad(true);
      // Code to execute when the iframe is ready
    }

    return () => {
      iframeRef?.current?.removeEventListener("load", onIframeLoad);
    };
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          style={{
            display: !load ? "" : "none",
            width: "40px",
            height: "40px",
          }}
          className="load"
        ></div>
      </div>
      <VillageImageDiv className="video-responsive">
        <div className="video_overlay">
          <iframe
            ref={iframeRef}
            src={`https://www.youtube.com/embed/${embedId}?theme=light&color=red&autoplay=0&autohide=0&cc_load_policy=1&modestbranding=1&fs=0&showinfo=0&rel=0&iv_load_policy=3&mute=0&loop=1`}
            frameBorder="0"
            sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
            title="Embedded youtube"
            poster={thumbnail}
            className="px-2 py-2 img-fluid border-none"
            style={{
              borderRadius: "20px",
              display: load ? "" : "none",
            }}
            allow="encrypted-media"
            allowFullScreen={true}
          />
          <img
            style={{
              opacity: "1",
              width: "-webkit-fill-available",
              position: "absolute",
              top: "10px",
              height: "20%",
              zIndex: "0",
              visibility: "hidden",
              boder: "none",
            }}
            id="close-overlay"
            src={transparent}
          />
        </div>
      </VillageImageDiv>
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  background-size: cover;
  background-position: center;
`;
