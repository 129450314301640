import React from "react";
import firebase from "../../../firebase";
import { Alert, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { history } from "../../..";
import Modal from "react-bootstrap/Modal";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import MyLayout from "../../Components/MyLayout/index";
import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import AWS from "aws-sdk";
import axios from "axios";
import { async } from "q";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    AWS.config.update({
      accessKeyId: "AKIA6JXYGZ42T6UQGT4S",
      secretAccessKey: "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",
      region: "ap-south-1",
    });

    this.s3 = new AWS.S3();
  }
  state = {
    values: {
      name: "",
      email: "",
      state: "",
      city: "",
      bio: "",
      username: "",
    },
    isEdit: false,
    avatar: null,
    sucessUpdated: "",
    loading: false,
    imageUpload: false,
    imageData: "",
    errorImage: false,
    isimageValid: false,
    processing: false,
  };
  fetchData = async (id) => {
    try {
      const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/250x250/images/${id}`;
      const response = await axios.get(url);
      const data = response?.data?.data;

      this.setState({ imageData: data });
    } catch (error) {
      console.error("Error fetching image data:", error);
    }
  };
  componentDidMount = () => {
    if (localStorage.getItem("user")) {
      if (
        localStorage.getItem("userType") != "undefined" &&
        localStorage.getItem("userType") == "artist"
      ) {
        history.push("/artist-profile");
      }

      firebase
        .firestore()
        .collection("users")
        .doc(
          localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).uid
        )
        .get()
        .then(async (response) => {
          const obj = response.data();
          obj["username"] = JSON.parse(localStorage.getItem("user")).uid;
          obj.isimageValid = false;

          this.setState(obj);
          console.log(this.state.isimageValid);

          try {
            const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${obj.avatar}`;
            const response = await axios.get(url);
            const data = response?.data?.data;

            this.setState({ imageData: data });
          } catch (error) {
            console.error("Error fetching image data:", error);
          }
        })
        .catch((err) => {
          console.log("err:", err);
        });
    } else {
      history.push("/login");
    }
  };

  handleNameChange = (e) => {
    var { values = {} } = this.state;
    values.name = e.target.value;

    this.setState({
      values,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      isEdit: true,
      values: { ...this.state.values, [e.target.name]: e.target.value },
    });
  };

  handleSubmit = (event) => {
    console.log("heyyy");
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let db = firebase.firestore();
    const objToBeStored = {
      values: {
        name: this.state.values.name || "",
        state: this.state.values.state || "",
        city: this.state.values.city || "",
        bio: this.state.values.bio || "",
      },
      avatar: this.state.avatar || "",
      sucessUpdated: this.state.sucessUpdated || "",
    };

    db.collection("users")
      .doc(`${JSON.parse(localStorage.getItem("user")).uid}`)
      .set(objToBeStored, { merge: true })
      .then((response) => {
        this.setState({});
        setTimeout(() => {
          localStorage.setItem(
            "userdata",
            JSON.stringify(objToBeStored.values)
          );
          this.setState({
            sucessUpdated: "Profile Update successfully",
            loading: false,
            isEdit: false,
          });
        }, 1000);
        setTimeout(() => {
          this.setState({
            sucessUpdated: "",
          });
        }, 2000);
      });
  };

  handleFileUpload = (e) => {
    console.log("inside");
    //whitelist for extensions
    const extensionChecker = new RegExp(
      "(" + [".jpg", ".jpeg", ".png"].join("|").replace(/\./g, "\\.") + ")$"
    ).test(e.target.files[0].name);

    var size = Math.round(e.target.files[0].size / 1024);
    if (extensionChecker) {
      if (size > 1024) {
        this.setState({ isimageValid: true });
        setTimeout(() => {
          this.setState({ isimageValid: false });
        }, [1500]);
      } else {
        const fileName = `${Date.now()}.${e.target.files[0].name
          .split(".")
          .pop()}`;

        this.setState({
          processing: true,
        });
        const params = {
          Bucket: "static.mgmd.gov.in",
          Key: `images/${fileName}`,
          Body: e.target.files[0],
          ContentType: "image/jpg",
        };

        //it will be null if the normal media is being uploaded

        this.s3.upload(params, (err, data) => {
          if (err) {
            console.error(err);
            this.setState({
              processing: false,
            });
            this.setState({ errorImage: true });
            setTimeout(() => {
              this.setState({ errorImage: false });
            }, 1500);
            // reject(err);
          } else {
            console.log("Image uploaded successfully:");

            firebase
              .firestore()
              .collection("users")
              .doc(JSON.parse(localStorage.getItem("user")).uid)
              .update({ avatar: fileName })
              .then((response) => {
                this.setState({
                  avatar: fileName,
                  imageUpload: true,
                  processing: false,
                });
                this.fetchData(fileName);
                setTimeout(() => {
                  this.setState({ imageUpload: false });
                }, 2000);
              });
          }
        });
      }
    } else {
      this.setState({ errorImage: true });
      setTimeout(() => {
        this.setState({ errorImage: false });
      }, 1500);
    }

    e.target.value = "";
  };

  render() {
    const { t } = this.props;

    return (
      <MyLayout>
        <MyDesktop>
          <div className="col-md-8">
            <div className="d-flex  th-br-15 th-bg-white pb-3 px-5 align-item-center justify-content-center">
              <div className="col-md-6" style={{ paddingLeft: "20px" }}>
                {this.state.isimageValid && (
                  <Alert key={"danger"} variant={"danger"} className="mt-3">
                    {"Please select image size less than 1 MB"}
                  </Alert>
                )}
                {
                  <Modal show={this.state.sucessUpdated} centered>
                    <Modal.Body className="text-center text-success">
                      Profile Updated Successfully
                    </Modal.Body>
                  </Modal>
                }

                {this.state.processing && (
                  <Alert key={"success"} variant={"success"} className="mt-3">
                    Image Uploading ....
                  </Alert>
                )}

                {this.state.imageUpload && (
                  <Alert key={"success"} variant={"success"} className="mt-3">
                    Image Updated Successfully
                  </Alert>
                )}

                {this.state.errorImage && (
                  <Alert key={"danger"} variant={"danger"} className="mt-3">
                    Please select valid Image !
                  </Alert>
                )}
                <div className="th-fw-500 th-20 th-grey-4 pt-3">
                  Edit Profile
                </div>

                <div className="col-12">
                  <div className="pt-3">
                    <div className="th-12">Full Name</div>
                    <div>
                      <input
                        value={this.state.values?.name}
                        onChange={this.handleInputChange}
                        type="text"
                        placeholder=""
                        className="w-100 form-control-plaintext shadow-none th-dk-grey px-1"
                        name="name"
                      />
                      <hr className="th-hr mb-0"></hr>
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="th-12">Email</div>
                    <div>
                      <input
                        type="text"
                        placeholder=""
                        className="w-100 form-control-plaintext shadow-none th-lt-grey th-bg-grey th-br-2 px-1"
                        name="email"
                        value={this.state.values?.email}
                        readOnly
                      />
                      <hr className="th-hr mb-0"></hr>
                    </div>
                  </div>

                  <div className="pt-3">
                    <div className="th-12">Unique ID</div>
                    <div>
                      <input
                        type="text"
                        placeholder=""
                        className="w-100 form-control-plaintext shadow-none th-lt-grey th-bg-grey th-br-2 px-1"
                        name="username"
                        value={this.state.username}
                        readOnly
                      />
                      <hr className="th-hr mb-0"></hr>
                    </div>
                  </div>

                  <div className="pt-3">
                    <div className="th-12">City</div>
                    <div>
                      <input
                        type="text"
                        placeholder=""
                        className="w-100 form-control-plaintext shadow-none th-dk-grey px-1"
                        name="city"
                        value={this.state.values?.city}
                        onChange={this.handleInputChange}
                      />
                      <hr className="th-hr mb-0"></hr>
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="th-12">State/UT</div>
                    <div>
                      <select
                        value={this.state.values?.state}
                        name="state"
                        className="w-100 form-control-plaintext shadow-none th-dk-grey px-1"
                        onChange={this.handleInputChange}
                      >
                        <option value="AN">Andaman and Nicobar Islands</option>
                        <option value="AP">Andhra Pradesh</option>
                        <option value="AR">Arunachal Pradesh</option>
                        <option value="AS">Assam</option>
                        <option value="BR">Bihar</option>
                        <option value="CH">Chandigarh</option>
                        <option value="CT">Chhattisgarh</option>
                        <option value="DN">Dadra and Nagar Haveli</option>
                        <option value="DD">Daman and Diu</option>
                        <option value="DL">Delhi</option>
                        <option value="GA">Goa</option>
                        <option value="GJ">Gujarat</option>
                        <option value="HR">Haryana</option>
                        <option value="HP">Himachal Pradesh</option>
                        <option value="JK">Jammu and Kashmir</option>
                        <option value="JH">Jharkhand</option>
                        <option value="KA">Karnataka</option>
                        <option value="KL">Kerala</option>
                        <option value="LA">Ladakh</option>
                        <option value="LD">Lakshadweep</option>
                        <option value="MP">Madhya Pradesh</option>
                        <option value="MH">Maharashtra</option>
                        <option value="MN">Manipur</option>
                        <option value="ML">Meghalaya</option>
                        <option value="MZ">Mizoram</option>
                        <option value="NL">Nagaland</option>
                        <option value="OR">Odisha</option>
                        <option value="PY">Puducherry</option>
                        <option value="PB">Punjab</option>
                        <option value="RJ">Rajasthan</option>
                        <option value="SK">Sikkim</option>
                        <option value="TN">Tamil Nadu</option>
                        <option value="TG">Telangana</option>
                        <option value="TR">Tripura</option>
                        <option value="UP">Uttar Pradesh</option>
                        <option value="UT">Uttarakhand</option>
                        <option value="WB">West Bengal</option>
                      </select>
                      <hr className="th-hr mb-0"></hr>
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="th-12">Bio</div>
                    <div>
                      <textarea
                        rows="2"
                        placeholder=""
                        className="w-100 form-control-plaintext shadow-none th-12 th-dk-grey px-1"
                        name="bio"
                        maxLength="120"
                        value={this.state.values?.bio}
                        onChange={this.handleInputChange}
                      />
                      <hr className="th-hr mb-0"></hr>
                    </div>
                  </div>
                  {!this.state.isEdit ? (
                    <button
                      className={`mt-3 px-4 th-white py-2 th-box-shadow th-btn-primary-br-grey th-box-shadow notAllow`}
                    >
                      <span className="th-white th-12">Save Changes</span>
                    </button>
                  ) : (
                    <button
                      className={`mt-3 px-4 th-white py-2 th-btn-primary-br-green th-box-shadow  th-box-shadow ${
                        !this.state.isEdit && "notAllow"
                      }`}
                    >
                      {this.state.loading ? (
                        <span
                          className="th-white th-12"
                          onClick={this.handleSubmit}
                        >
                          <span
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Wait...
                        </span>
                      ) : (
                        <span
                          className="th-white th-12"
                          onClick={this.handleSubmit}
                        >
                          Save Changes
                        </span>
                      )}
                    </button>
                  )}
                </div>
              </div>

              <div className="col-md-7 pt-4 text-center d-flex align-items-center justify-content-center">
                <div className="row">
                  <div
                    className="media-main-image"
                    id="my-image"
                    style={{
                      backgroundImage: `url(${
                        !this.state.imageData && AvatarDefault
                      })`,
                      backgroundSize: "contain",
                      backgroundPosition: "top",
                      height: "250px",
                      width: "100%",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: this.state.imageData
                        ? "#fff"
                        : "#bababa",
                    }}
                  >
                    <img
                      src={
                        this.state.imageData
                          ? this.state.imageData
                          : AvatarDefault
                      }
                      style={{
                        width: "auto",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                      onError={(e) => (e.target.src = AvatarDefault)}
                    />
                  </div>
                  {/* <div
                    className="text-center mx-auto rounded-circle pt-4"
                    style={{
                      height: "250px",
                      width: "250px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <img
                      src={
                        this.state.imageData
                          ? this.state.imageData
                          : AvatarDefault
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </div> */}
                  <div className="image_upload mt-2">
                    <input
                      style={{ display: "none" }}
                      id="file-input"
                      type="file"
                      accept="image/*"
                      title=""
                      onChange={(e) => this.handleFileUpload(e)}
                    />
                    <label
                      htmlFor="file-input"
                      className="px-4 py-3 mt-4 th-btn-primary-br-grey th-box-shadow h-white th-12 th-fw-600"
                      role="button"
                    >
                      Upload Picture
                    </label>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </MyDesktop>
        <MyMobile>
          {
            <Modal show={this.state.isimageValid} centered>
              <Modal.Body className="text-center text-danger">
                {"Please select image size less than 1 MB"}
              </Modal.Body>
            </Modal>
          }
          {
            <Modal show={this.state.sucessUpdated} centered>
              <Modal.Body className="text-center text-success">
                Profile Updated Successfully
              </Modal.Body>
            </Modal>
          }
          {
            <Modal show={this.state.processing} centered>
              <Modal.Body className="text-center text-success">
                Image Uploading ...
              </Modal.Body>
            </Modal>
          }
          {
            <Modal show={this.state.imageUpload} centered>
              <Modal.Body className="text-center text-success">
                Image Updated Successfully
              </Modal.Body>
            </Modal>
          }

          <div className="col-md-12">
            <div className="th-fw-500 th-22 th-grey-4 pt-1 pb-1 text-center">
              Edit Profile
            </div>
            <div className="th-br-15 pt-1">
              <div
                className="media-main-image"
                id="my-image"
                style={{
                  backgroundImage: `url(${
                    !this.state.imageData && AvatarDefault
                  })`,
                  backgroundSize: "contain",
                  backgroundPosition: "top",
                  height: "120px",
                  width: "100%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  backgroundRepeat: "no-repeat",
                  // backgroundColor: this.state.imageData ? "#fff" : "#bababa",
                }}
              >
                <img
                  src={
                    this.state.imageData ? this.state.imageData : AvatarDefault
                  }
                  style={{
                    width: "auto",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  onError={(e) => (e.target.src = AvatarDefault)}
                />
              </div>
              {/* <div
                className="text-center mx-auto rounded-circle pt-4"
                style={{
                  height: "120px",
                  width: "120px",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                {" "}
                <img
                  src={
                    this.state.imageData ? this.state.imageData : AvatarDefault
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                  onError={(e) => (e.target.src = AvatarDefault)}
                />
              </div> */}
              <div className="image_upload text-center">
                <input
                  style={{ display: "none" }}
                  id="file-input"
                  type="file"
                  accept="image/*"
                  title=""
                  onChange={(e) => this.handleFileUpload(e)}
                />
                <label
                  htmlFor="file-input"
                  className="px-3 py-2 my-2 th-btn-primary-br-grey th-box-shadow h-white th-12 th-fw-600"
                  role="button"
                >
                  Upload Picture
                </label>
              </div>
              <div className="th-fw-500 th-22 th-grey-4  pt-3 text-center">
                {this.state.values?.name}
              </div>
              <div className="th-16 th-grey-5 th-fw-500 text-center">
                @{this.state.values?.email?.split("@")[0]}
              </div>
              <div className="th-10 pt-2 mx-2 th-grey-2 text-center">
                <span style={{ overflowWrap: "break-word" }}>
                  {this.state.values?.bio}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12 pt-4" style={{ paddingBottom: "80px" }}>
            <div className="pt-1 pb-2">
              <div className="col-md-5">
                <div className="pt-1">
                  <div className="th-18">Full Name</div>
                  <div>
                    <input
                      value={this.state.values?.name}
                      onChange={this.handleInputChange}
                      type="text"
                      placeholder=""
                      className="w-100 form-control-plaintext shadow-none th-dk-grey px-1"
                      name="name"
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
                <div className="pt-1">
                  <div className="th-18">Email</div>
                  <div>
                    <input
                      type="text"
                      placeholder=""
                      className="w-100 form-control-plaintext shadow-none th-lt-grey"
                      name="email"
                      value={this.state.values?.email}
                      readOnly
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
                <div className="pt-1">
                  <div className="th-18">Unique ID</div>
                  <div>
                    <input
                      type="text"
                      placeholder=""
                      className="w-100 form-control-plaintext shadow-none th-lt-grey  th-bg-grey th-br-2 px-1"
                      name="username"
                      value={this.state.username}
                      readOnly
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>

                <div className="pt-1">
                  <div className="th-18">City</div>
                  <div>
                    <input
                      type="text"
                      placeholder=""
                      className="w-100 form-control-plaintext shadow-none th-lt-grey"
                      name="city"
                      value={this.state.values?.city}
                      onChange={this.handleInputChange}
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>

                <div className="pt-1 pb-2">
                  <div className="th-18">State/UT</div>
                  <div>
                    <select
                      value={this.state.values?.state}
                      name="state"
                      className="w-100 form-control-plaintext shadow-none th-dk-grey px-1"
                      onChange={this.handleInputChange}
                    >
                      <option value="AN">Andaman and Nicobar Islands</option>
                      <option value="AP">Andhra Pradesh</option>
                      <option value="AR">Arunachal Pradesh</option>
                      <option value="AS">Assam</option>
                      <option value="BR">Bihar</option>
                      <option value="CH">Chandigarh</option>
                      <option value="CT">Chhattisgarh</option>
                      <option value="DN">Dadra and Nagar Haveli</option>
                      <option value="DD">Daman and Diu</option>
                      <option value="DL">Delhi</option>
                      <option value="GA">Goa</option>
                      <option value="GJ">Gujarat</option>
                      <option value="HR">Haryana</option>
                      <option value="HP">Himachal Pradesh</option>
                      <option value="JK">Jammu and Kashmir</option>
                      <option value="JH">Jharkhand</option>
                      <option value="KA">Karnataka</option>
                      <option value="KL">Kerala</option>
                      <option value="LA">Ladakh</option>
                      <option value="LD">Lakshadweep</option>
                      <option value="MP">Madhya Pradesh</option>
                      <option value="MH">Maharashtra</option>
                      <option value="MN">Manipur</option>
                      <option value="ML">Meghalaya</option>
                      <option value="MZ">Mizoram</option>
                      <option value="NL">Nagaland</option>
                      <option value="OR">Odisha</option>
                      <option value="PY">Puducherry</option>
                      <option value="PB">Punjab</option>
                      <option value="RJ">Rajasthan</option>
                      <option value="SK">Sikkim</option>
                      <option value="TN">Tamil Nadu</option>
                      <option value="TG">Telangana</option>
                      <option value="TR">Tripura</option>
                      <option value="UP">Uttar Pradesh</option>
                      <option value="UT">Uttarakhand</option>
                      <option value="WB">West Bengal</option>
                    </select>
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
                <div className="pt-1 pb-2 mb-3">
                  <div className="th-18">Bio max 120 characters</div>
                  <div>
                    <textarea
                      rows="2"
                      placeholder=""
                      className="w-100 form-control-plaintext shadow-none th-12 th-dk-grey px-1"
                      name="bio"
                      maxLength="120"
                      value={this.state.values?.bio}
                      onChange={this.handleInputChange}
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
                {!this.state.isEdit ? (
                  <button
                    className={`px-3 py-2  th-box-shadow th-btn-primary-br-grey notAllow`}
                    style={{ width: "100%" }}
                  >
                    <span className="th-white th-18">Save Changes</span>
                  </button>
                ) : this.state.loading ? (
                  <button
                    className="px-3 py-2 th-btn-primary-br-green th-box-shadow"
                    style={{ width: "100%" }}
                    onClick={this.handleSubmit}
                  >
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Wait...
                  </button>
                ) : (
                  <button
                    className={`px-3 py-2 th-btn-primary-br-green  th-box-shadow ${
                      !this.state.isEdit && "notAllow"
                    }`}
                    style={{ width: "100%" }}
                    onClick={this.handleSubmit}
                  >
                    <span className="th-white th-18">Save Changes</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </MyMobile>
      </MyLayout>
    );
  }
}

export default withRouter(Profile);
