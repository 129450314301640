import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import firebase from "../../../../firebase";
import { withRouter } from "react-router-dom";

import EditGrey from "../../../Assets/img/icons/EditGrey.svg";
class OverviewCard extends Component {
  state = {
    isHovered: true,
    aboutsuccess: false,
    editEnabled: false,
    text: this.props.answer,
    errorText: false
  };

  handleEditText() {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.displayName
    ) {
      this.setState({
        text: this.props?.total_people
          ? this.props?.total_people
          : this.props.answer,
      });
      this.setState({
        male: this.props?.pop_male ? this.props?.pop_male?.match(/\d+/)[0] : 0,
      });
      this.setState({
        female: this.props?.pop_female
          ? this.props?.pop_female?.match(/\d+/)[0]
          : 0,
      });
    } else {
      this.props.history.push("/login");
    }
  }

  render() {
    const handleCardInput = (e) => {
      if (this.props.sub_field == "male_population" || this.props.sub_field == "female_population" || this.props.sub_field == "other_population" ||
        this.props.sub_field == "total_population" || this.props.sub_field == "status" || this.props.sub_field == "longitude_id" || this.props.sub_field == "latitude_id") {
        this.setState({ text: this.props.total_people })
      } else {
        this.setState({ text: this.props.answer })
      }
      this.setState({ text: e.target.value });
    };

    const handleCardInputMale = (e, val) => {
      if (val == "male") {
        this.setState({ male: e.target.value });
      } else {
        this.setState({ female: e.target.value });
      }
    };

    const handleCardSubmit = (type) => {
      if (this.state.text == "" || this.props?.pop_male == "" || this.props?.pop_female == "") {
        this.setState({ errorText: true })
        setTimeout(() => {
          this.setState({ errorText: false })
        }, 2000)
      } else {
        let value = this.state.text;
        let sub_f = this.props.jsonField;
        if (type == "male") {
          value = this.state?.male;
          sub_f = "male_population";
        }
        if (type == "female") {
          value = this.state?.female;
          sub_f = "female_population";
        }
        const numericSubFields = ["male_population", "female_population", "other_population", "total_population", "status", "toilet",
          "primary_health_centre", "gas", "tab_water", "post_office", "nearest_railway_station",
          "college", "school", "bank", "telephone"];

        if (numericSubFields.includes(sub_f)) {
          value = this.state.text?.replace(/[^0-9]/g, "");
        }
        if (sub_f == "longitude_id" || sub_f == "latitude_id") {
          value = this.state.text?.replace(/[^\d\.]*/g, '')
        }
        const data = {
          timestamp: Date.now(),
          type: "about_edit",
          main_field: this.props.subtitle,
          sub_field: sub_f,
          value: value,
          village_key: this.props.village_key,
          block_name: this.props.blockName,
          district_name: this.props.districtName,
          village_name: this.props.village_name,
          state_name: this.props.state_name,
          created_by: JSON.parse(localStorage.getItem("user"))?.displayName,
          status: "no action",
        };
        firebase
          .firestore()
          .collection("large_village_suggest_edit")
          .add(data)
          .then((res) => {
            this.setState({ aboutsuccess: true });
            setTimeout(() => {
              this.setState({ aboutsuccess: false });
              this.setState({ editEnabled: false });
              document.body.click();
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    return (
      <React.Fragment>
        <div
          className="py-2 px-md-3 px-2 th-bg-white th-br-5 position-relative overview-card"
          style={{
            border: "1px solid #E0E0E0",
            width: "100%",
            marginRight: "5%",
            display: "inline-block",
          }}
        >
          <div className="pr-3" style={{ width: "95%" }}>
            <div className="th-grey-5 th-13 text-truncate">
              {this.props.title}
            </div>

            <div className="th-fw-600 th-12 text-truncate">
              {this.props?.answer}
              <div className="d-flex">
                {this.props?.pop_male && (
                  <>
                    {" "}
                    {this.props?.pop_male}
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="auto"
                      overlay={
                        <Popover
                          id="popover-basic"
                          style={{
                            height: "auto",
                          }}
                        >
                          <Popover.Header>
                            {this.state.aboutsuccess && (
                              <div className="alert alert-success" role="alert">
                                Submitted For Review
                              </div>
                            )}
                            {this.state.errorText && (
                              <div className="alert alert-danger" role="alert">
                                Please Fill Details.
                              </div>
                            )}
                          </Popover.Header>
                          <Popover.Body>
                            <div>
                              <textarea
                                name="popover"
                                cols="41"
                                rows="7"
                                style={{ width: "100%" }}
                                onChange={(e) => handleCardInputMale(e, "male")}
                                value={this.state?.male}
                              ></textarea>
                            </div>
                            <div className="d-flex flex-row justify-content-end">
                              <div
                                className="btn th-bg-primary "
                                onClick={() => handleCardSubmit("male")}
                              >
                                Submit For Review
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div
                        role="button"

                        style={{ marginTop: "-3px", padding: "0 5px" }}
                        onClick={() => this.handleEditText()}
                      >
                        <img
                          src={EditGrey}
                          height="15px"
                          width="15px"
                          alt="."
                        />
                      </div>
                    </OverlayTrigger>
                  </>
                )}
                {this.props?.pop_female && (
                  <>
                    {" "}
                    {this.props?.pop_female}
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="auto"
                      overlay={
                        <Popover
                          id="popover-basic"
                          style={{
                            height: "auto",
                          }}
                        >
                          <Popover.Header>
                            {this.state.aboutsuccess && (
                              <div className="alert alert-success" role="alert">
                                Submitted For Review
                              </div>
                            )}
                            {this.state.errorText && (
                              <div className="alert alert-danger" role="alert">
                                Please Fill Details.
                              </div>
                            )}
                          </Popover.Header>
                          <Popover.Body>
                            <div>
                              <textarea
                                name="popover"
                                id=""
                                cols="41"
                                rows="7"
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  handleCardInputMale(e, "female")
                                }
                                value={this.state?.female}
                              ></textarea>
                            </div>
                            <div className="d-flex flex-row justify-content-end">
                              <div
                                className="btn th-bg-primary "
                                onClick={() => handleCardSubmit("female")}
                              >
                                Submit For Review
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div
                        role="button"
                        style={{ marginTop: "-3px", padding: "0 5px" }}
                        onClick={() => this.handleEditText()}
                      >
                        <img
                          src={EditGrey}
                          height="15px"
                          width="15px"
                          alt="."
                        />
                      </div>
                    </OverlayTrigger>
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="position-absolute"
            style={{ bottom: "5px", right: "10px" }}
          >
            <img src={this.props.icon} height="16px" width="16px" alt="." />
          </div>
          {this.state.isHovered ? (
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="auto"
              overlay={
                <Popover
                  id="popover-basic"
                  style={{
                    height: "auto",
                  }}
                >
                  <Popover.Header>
                    {this.state.aboutsuccess && (
                      <div className="alert alert-success" role="alert">
                        Submitted For Review
                      </div>
                    )}
                    {this.state.errorText && (
                      <div className="alert alert-danger" role="alert">
                        Please Fill Details.
                      </div>
                    )}
                  </Popover.Header>
                  <Popover.Body>
                    <div>
                      <textarea
                        name="popover"
                        id=""
                        cols="41"
                        rows="7"
                        style={{ width: "100%" }}
                        onChange={handleCardInput}
                        value={this.state.text}
                      ></textarea>
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                      <div
                        className="btn th-bg-primary "
                        onClick={() => handleCardSubmit("text")}
                      >
                        Submit For Review
                      </div>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <div
                role="button"
                className="position-absolute"
                style={{ top: "2px", right: "10px" }}
                onClick={() => this.handleEditText()}
              >
                <img src={EditGrey} height="15px" width="15px" alt="." />
              </div>
            </OverlayTrigger>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(OverviewCard);
