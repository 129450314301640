import React from "react";
import MyLayout from "../../Components/MyLayout";
import NoticeImage from "../../Assets/img/notice/notice.jpg";

const Notice = () => {
  return (
    <MyLayout>
      <img src={NoticeImage} alt="Notice" style={{ width: "70%" }} />
    </MyLayout>
  );
};

export default Notice;
