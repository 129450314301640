import React, { useEffect, useState } from "react";
import firebase from "../../../firebase";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import { Link } from "react-router-dom";
import { Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import DefaultImage from "../../Assets/img/General/default.png";

const GoodStories = () => {
  const [open, setOpen] = useState(false);
  const [currentStory, setCurrentStory] = useState(null);
  const [expandedCards, setExpandedCards] = useState({});
  const [villageStories, setVillageStories] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const fetchStories = async (startAfterDoc = null) => {
    let db = firebase.firestore();
    let query = db.collection("good_stories").limit(51);

    if (startAfterDoc) {
      query = query.startAfter(startAfterDoc);
    }

    const docRef = await query.get();

    if (docRef.empty) {
      setHasMore(false);
      return;
    }

    const res = await Promise.all(
      docRef.docs.map(async (doc) => {
        let data = doc.data();
        const imageUrlResponse = await axios.get(
          `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${data.image_source}`
        );
        const imageUrl = imageUrlResponse.data?.data; // Adjust this if the response is not plain text
        return {
          ...data,
          image_source: imageUrl,
        };
      })
    );

    setVillageStories((prev) => [...prev, ...res]);
    setLastVisible(docRef.docs[docRef.docs.length - 1]);
  };

  useEffect(() => {
    fetchStories();
  }, []);

  const loadMoreStories = () => {
    if (hasMore) {
      fetchStories(lastVisible);
    }
  };

  const toggleCardExpansion = (id) => {
    setExpandedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleClickOpen = (story) => {
    setCurrentStory(story);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentStory(null);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <MyLayout>
      <div className="col-md-12 custom-list">
        <div className="pb-3 search-content justify-content-between">
          <MyDesktop>
            <div
              className="pb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 className="th-40 fw-700 th-fair-play text-success">
                Mera Gaon Meri Dharohar
              </h1>
              <h1 className="th-20 fw-700">Unique Stories</h1>
              <div style={{ textAlign: "center" }}>
                <p style={{ marginBottom: "0" }}>
                  Explore unheard tales and captivating stories from the
                  grassroots of India.
                </p>
                <p style={{ fontStyle: "italic", fontSize: "14px" }}>
                  Have more such stories to share? Visit the village and
                  contribute now!{" "}
                  <Link to="/explore">
                    <span style={{ color: "#3CAF5C", fontWeight: "600" }}>
                      Click to Explore.
                    </span>
                  </Link>
                </p>
              </div>
            </div>

            <div
              className="stories"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                alignItems: "flex-start",
                marginLeft: "4%",
              }}
            >
              {villageStories.map((story) => (
                <div
                  key={story?.id}
                  className="stories-card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "30%",
                    transition: "all 0.3s ease",
                  }}
                >
                  <div className="stories-card-image">
                    <img
                      src={story?.image_source}
                      alt={story?.village_name}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                        borderRadius: "10px",
                        transition: "all 0.3s ease",
                      }}
                      onError={(e) => (e.target.src = DefaultImage)}
                    />
                  </div>
                  <div
                    className="stories-card-story"
                    style={{ marginTop: "2%", padding: "20px" }}
                  >
                    <h3
                      style={{
                        color: "#3CAF5C",
                        fontSize: "18px",
                        minHeight: "44px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {story?.tagline}
                    </h3>
                    <h4 style={{ fontSize: "16px" }}>
                      {story?.village_name}, {story?.state_name}
                    </h4>
                    <p>{truncateText(story?.one_liner, 70)}</p>
                    <div
                      className="stories-card-story-footer"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <button
                          className="btn"
                          style={{
                            borderColor: "#3CAF5C",
                            color: "#3CAF5C",
                          }}
                          onClick={() => handleClickOpen(story)}
                        >
                          Read More
                        </button>
                      </div>
                      {story?.page_link && (
                        <Link to={story?.page_link}>
                          <button
                            className="btn"
                            style={{
                              backgroundColor: "#3CAF5C",
                              color: "#fff",
                            }}
                          >
                            Explore Village
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {hasMore && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#3CAF5C",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                  }}
                  onClick={loadMoreStories}
                >
                  Load More
                </button>
              </div>
            )}
          </MyDesktop>
          <MyMobile>
            <div
              className="pb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 className="th-25 fw-700 th-fair-play text-success">
                Mera Gaon Meri Dharohar
              </h1>
              <h1 className="th-20 fw-700">Unique Stories</h1>
              <div style={{ textAlign: "center" }}>
                <p style={{ marginBottom: "0" }}>
                  Explore unheard tales and captivating stories from the
                  grassroots of India.
                </p>
                <p style={{ fontStyle: "italic", fontSize: "14px" }}>
                  Have more such stories to share? Visit the village and
                  contribute now!{" "}
                  <Link to="/explore">
                    <span style={{ color: "#3CAF5C", fontWeight: "600" }}>
                      Click to Explore.
                    </span>
                  </Link>
                </p>
              </div>
            </div>

            <div
              className="stories"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                alignItems: "flex-start",
              }}
            >
              {villageStories.map((story) => (
                <div
                  key={story?.id}
                  className="stories-card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    transition: "all 0.3s ease",
                  }}
                >
                  <div className="stories-card-image">
                    <img
                      src={story?.image_source}
                      alt={story?.village_name}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                        borderRadius: "10px",
                        transition: "all 0.3s ease",
                      }}
                      onError={(e) => (e.target.src = DefaultImage)}
                    />
                  </div>
                  <div
                    className="stories-card-story"
                    style={{ marginTop: "2%", padding: "20px" }}
                  >
                    <h3 style={{ color: "#3CAF5C", fontSize: "18px" }}>
                      {story?.tagline}
                    </h3>
                    <h4 style={{ fontSize: "16px" }}>
                      {story?.village_name}, {story?.state_name}
                    </h4>
                    <p>{truncateText(story?.one_liner, 70)}</p>
                    <div
                      className="stories-card-story-footer"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <button
                          className="btn"
                          style={{
                            borderColor: "#3CAF5C",
                            color: "#3CAF5C",
                          }}
                          onClick={() => handleClickOpen(story)}
                        >
                          Read More
                        </button>
                      </div>
                      {story?.page_link && (
                        <Link to={story?.page_link}>
                          <button
                            className="btn"
                            style={{
                              backgroundColor: "#3CAF5C",
                              color: "#fff",
                            }}
                          >
                            Explore Village
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {hasMore && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#3CAF5C",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                  }}
                  onClick={loadMoreStories}
                >
                  Load More
                </button>
              </div>
            )}
          </MyMobile>
        </div>
      </div>
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <DialogContent>
          {currentStory && (
            <>
              <img
                src={currentStory?.image_source}
                alt={currentStory?.village_name}
                onError={(e) => (e.target.src = DefaultImage)}
                style={{
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <h4 style={{ fontSize: "18px", marginTop: "20px" }}>
                {currentStory?.village_name}
              </h4>
              <p>{currentStory?.state_name}</p>
              <p style={{ color: "#3CAF5C" }}>{currentStory?.tagline}</p>
              <p>{currentStory?.content}</p>
            </>
          )}
        </DialogContent>
      </Dialog>
    </MyLayout>
  );
};

export default GoodStories;
