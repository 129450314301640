import { useState, useEffect } from "react";
import firebase from "../../../../src/firebase";
import { Link, useHistory } from "react-router-dom";
import { Alert, Modal } from "react-bootstrap";
import MyLayout from "../../Components/MyLayout";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import MediaCard from "./MediaCard";
import { history } from "../../..";
import AddEditMedia from "./AddEditMedia";
import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import IndibadgesImg from "../../Assets/img/General/indibadges.svg";
import Plus from "../../Assets/img/Artist/plus.svg";
import "./index.scss";
import ArtSelect from "./MenuArtist";
import AWS from "aws-sdk";
import axios from "axios";

const EditProfile = () => {
  const [userData, setUserData] = useState();
  const [mediaData, setMediaData] = useState();
  const [isimageValid, setIsimageValid] = useState(true);
  const [sucessUpdated, setSucessUpdated] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hearByCheck, setHearByCheck] = useState(false);
  const [anyOther, setAnyOther] = useState("");
  const [hearbycheckbox, setHearbycheckbox] = useState();
  const [loader, setLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [imageUpload, setImageUpload] = useState(false);
  const [errorImageUpload, setErrorImageUpload] = useState(false);
  const history = useHistory();
  const [imageData, setImageData] = useState(null);
  const [allBlock, setallBlock] = useState();
  const [allStates, setallStates] = useState();
  const [allDistricts, setallDistricts] = useState();
  const [selectedValueForStateFilter, setselectedValueForStateFilter] =
    useState("select");
  const [selectedValueForDistrictFilter, setselectedValueForDistrictFilter] =
    useState("select");
  const [selectedValueForBlockFilter, setselectedValueForBlockFilter] =
    useState("select");
  const [localState, setLocalState] = useState(
    JSON.parse(localStorage.getItem("userdata"))?.state ||
      selectedValueForStateFilter
  );
  const [localBlock, setLocalBlock] = useState(
    JSON.parse(localStorage.getItem("userdata"))?.city ||
      selectedValueForBlockFilter
  );
  const [localDistrict, setLocalDistrict] = useState(
    JSON.parse(localStorage.getItem("userdata"))?.district ||
      selectedValueForDistrictFilter
  );

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (
        localStorage.getItem("userType") != "undefined" &&
        localStorage.getItem("userType") != "artist"
      ) {
        history.push("/profile");
      }
      setHearByCheck(false);
      getUserData();
      getMediaData();
    } else {
      history.push("/login");
    }
  }, []);
  const s3 = new AWS.S3();
  AWS.config.update({
    accessKeyId: "AKIA6JXYGZ42T6UQGT4S",
    secretAccessKey: "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",
    region: "ap-south-1",
  });
  useEffect(() => {
    if (userData && userData.hasOwnProperty("avatar")) {
      console.log(userData.avatar);
      const fetchData = async () => {
        try {
          const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${userData.avatar}`;
          const response = await axios.get(url);
          const data = response?.data?.data;
          console.log(data);
          setImageData(data);
        } catch (error) {
          console.error("Error fetching image data:", error);
        }
      };

      fetchData();
    }
  }, [userData?.avatar]);

  const handleFileUpload = async (e, _type) => {
    console.log("hiii");
    //whitelist for extensions
    const extensionChecker = new RegExp(
      "(" + [".jpg", ".jpeg", ".png"].join("|").replace(/\./g, "\\.") + ")$"
    ).test(e.target.files[0].name);

    if (extensionChecker) {
      console.log("in");
      var size = Math.round(e.target.files[0].size / 1024);
      if (size > 1024) {
        setIsimageValid(false);

        setTimeout(() => {
          setIsimageValid(true);
        }, 5000);
      } else {
        setIsimageValid(true);
        let uploadTask;
        const fileName = `${Date.now()}.${e.target.files[0].name
          .split(".")
          .pop()}`;
        console.log("kiii");
        const params = {
          Bucket: "static.mgmd.gov.in",
          Key: `images/${fileName}`,
          Body: e.target.files[0],
          ContentType: "image/jpg",
        };

        //it will be null if the normal media is being uploaded

        s3.upload(params, (err, data) => {
          if (err) {
            console.error(err);
            // reject(err);
          } else {
            console.log("Image uploaded successfully:");

            firebase
              .firestore()
              .collection("users")
              .doc(JSON.parse(localStorage.getItem("user")).uid)
              .update({ avatar: fileName })
              .then((response) => {
                setUserData((prevState) => ({
                  ...prevState,
                  avatar: fileName,
                }));
                setImageUpload(true);
                setTimeout(() => {
                  setImageUpload(false);
                }, 2000);
              })
              .catch((err) => {
                console.log("errr0------------------", err);
                setErrorImageUpload(true);
                setTimeout(() => {
                  setErrorImageUpload(false);
                }, 2000);
              });
          }
        });
      }
    } else {
      setErrorImageUpload(true);

      setTimeout(() => {
        setErrorImageUpload(false);
      }, 2500);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisableButton(true);
    // setLoader(true);
    let dataU = {
      values: {
        artname: userData?.values?.artname ? userData?.values?.artname : null,
        arttype: userData?.values?.arttype ? userData?.values?.arttype : null,
        arttypeAny: userData?.values?.arttypeAny
          ? userData?.values?.arttypeAny
          : null,
        bio: userData?.values?.bio ? userData?.values?.bio : null,
        city: userData?.values?.city ? userData?.values?.city : null,
        description: userData?.values?.description
          ? userData?.values?.description
          : null,
        email: userData?.values?.email ? userData?.values?.email : null,
        phonenumber: userData?.values?.phonenumber
          ? userData?.values?.phonenumber
          : null,
        experience: userData?.values?.experience
          ? userData?.values?.experience
          : null,
        name: userData?.values?.name ? userData?.values?.name : null,
        state: userData?.values?.state ? userData?.values?.state : null,
        district: userData?.values?.district
          ? userData?.values?.district
          : null,
        username: userData?.values?.username
          ? userData?.values?.username
          : null,
        upi_id: userData?.values?.upi_id ? userData?.values?.upi_id : null,
        account_number: userData?.values?.account_number
          ? userData?.values?.account_number
          : null,
        account_name: userData?.values?.account_name
          ? userData?.values?.account_name
          : null,
        ifsc_code: userData?.values?.ifsc_code
          ? userData?.values?.ifsc_code
          : null,
      },
    };
    let db = firebase.firestore();
    db.collection("users")
      .doc(`${JSON.parse(localStorage?.getItem("user"))?.uid}`)
      .set({ ...dataU }, { merge: true })
      .then((querySnapShot) => {
        // setSucessUpdated(false);
        localStorage.setItem("userdata", JSON.stringify(dataU.values));
        setLoader(false);
        setTimeout(() => {
          setSucessUpdated(true);
        }, 2500);

        setTimeout(() => {
          setSucessUpdated(false);
        }, 4500);
      })
      .catch((e) => {
        console.log("e----", e);
      });
  };

  const getMediaData = async () => {
    await firebase
      .firestore()
      .collection("artist_media")
      .where("userId", "==", JSON.parse(localStorage.getItem("user")).uid)
      .get()
      .then((doc) => {
        let data = [];
        doc.docs?.map((item) => {
          data.push(item.data());
        });
        data = data.sort((a, b) => b.created - a.created);
        setMediaData(data);
        setLoading(true);
      })
      .catch((err) => {
        console.log("err in getting media: ", err);
      });
  };

  const getStateName = (code) => {
    switch (code) {
      case "AN":
        return "Andaman and Nicobar Islands";
      case "AP":
        return "Andhra Pradesh";
      case "AR":
        return "Arunachal Pradesh";
      case "AS":
        return "Assam";
      case "BR":
        return "Bihar";
      case "CH":
        return "Chandigarh";
      case "CT":
        return "Chhattisgarh";
      case "DN":
        return "Dadra and Nagar Haveli";
      case "DD":
        return "Daman and Diu";
      case "DL":
        return "Delhi";
      case "GA":
        return "Goa";
      case "GJ":
        return "Gujarat";
      case "HR":
        return "Haryana";
      case "HP":
        return "Himachal Pradesh";
      case "JK":
        return "Jammu and Kashmir";
      case "JH":
        return "Jharkhand";
      case "KA":
        return "Karnataka";
      case "KL":
        return "Kerala";
      case "LA":
        return "Ladakh";
      case "LD":
        return "Lakshadweep";
      case "MP":
        return "Madhya Pradesh";
      case "MH":
        return "Maharashtra";
      case "MN":
        return "Manipur";
      case "ML":
        return "Meghalaya";
      case "MZ":
        return "Mizoram";
      case "NL":
        return "Nagaland";
      case "OR":
        return "Odisha";
      case "PY":
        return "Puducherry";
      case "PB":
        return "Punjab";
      case "RJ":
        return "Rajasthan";
      case "SK":
        return "Sikkim";
      case "TN":
        return "Tamil Nadu";
      case "TG":
        return "Telangana";
      case "TR":
        return "Tripura";
      case "UP":
        return "Uttar Pradesh";
      case "UT":
        return "Uttarakhand";
      case "WB":
        return "West Bengal";
      default:
        return code;
    }
  };

  const getUserData = async () => {
    await firebase
      .firestore()
      .collection("users")
      .doc(
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).uid
      )
      .get()
      .then((doc) => {
        if (doc.data() != "undefined") {
          let user = {
            avatar: doc.data()?.avatar,
            username: JSON.parse(localStorage.getItem("user")).uid,
            values: {
              username: JSON.parse(localStorage.getItem("user")).uid,
              name: doc.data()?.values?.name,
              email: doc.data()?.values?.email,
              phonenumber: doc.data()?.values?.phonenumber,
              bio: doc.data()?.values?.bio,
              city: doc.data()?.values?.city,
              state: getStateName(doc.data()?.values?.state),
              district: doc.data()?.values?.district,
              artname: doc.data()?.values?.artname,
              arttype: doc.data()?.values?.arttype,
              arttypeAny: doc.data()?.values?.arttypeAny,
              description: doc.data()?.values?.description,
              experience: doc.data()?.values?.experience,
              upi_id: doc.data()?.values?.upi_id,
              account_number: doc.data()?.values?.account_number,
              account_name: doc.data()?.values?.account_name,
              ifsc_code: doc.data()?.values?.ifsc_code,
            },
          };
          setUserData(user);
          localStorage.setItem("userdata", JSON.stringify(user.values));
          if (
            doc.data()?.values?.arttype &&
            doc.data()?.values?.arttype == "anyother"
          ) {
            setAnyOther("anyother");
          } else {
            setAnyOther("");
          }
          if (
            doc.data()?.values?.phonenumber &&
            doc.data()?.values?.phonenumber !== undefined
          ) {
            setHearbycheckbox(true);
          } else {
            setHearbycheckbox(false);
          }
        }
      })
      .catch((err) => {
        console.log("err in getting user data: ", err);
      });
  };

  const handleInputChange = (e, val) => {
    setDisableButton(false);
    if (val == "artType") {
      if (e.target.value == "anyother") {
        setAnyOther("anyother");
      } else {
        setAnyOther("");
        setUserData((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            arttypeAny: "",
          },
        }));
      }
    }
    setUserData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [e.target.name]: e.target.value,
      },
    }));
    setUserData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        state: localState,
        district: localDistrict,
        city: localBlock,
      },
    }));
  };

  const HearBy = (e) => {
    if (e.target.checked) {
      setHearByCheck(true);
      setHearbycheckbox(true);
    } else {
      setHearByCheck(false);
      setHearbycheckbox(false);
      setUserData((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          phonenumber: "",
        },
      }));
    }
  };

  useEffect(() => {
    const dbs = firebase.firestore().collection("state_district_detail");
    const blockDb = firebase.firestore().collection("district_block_detail");

    // If it have any local value then go inside this
    if (localState || localBlock || localDistrict) {
      setUserData((prevState) => ({
        ...prevState,
        values: {
          ...prevState?.values,
          state: localState,
          district: localDistrict,
          city: localBlock,
        },
      }));
      // set all state``
      firebase
        .firestore()
        .collection("state_detail")
        .get()
        .then((querySnapshot) => {
          const state = querySnapshot.docs.map((doc) => ({
            value: doc.data().state_name,
          }));
          setallStates(state);
        });

      // set district according to state
      dbs
        .where("state_name", "==", localState)
        .get()
        .then((querySnapShot) => {
          const district = querySnapShot.docs.map((doc) => ({
            value: doc.data().district_name,
          }));
          setallDistricts(district);

          // if district is not select then set block according to data present in district
          if (!localDistrict) {
            let ary = district?.map((i) => i.value);
            let index = 0;
            const batchSize = 10;

            while (index < ary.length) {
              const batch = ary.slice(index, index + batchSize);
              blockDb
                .where("district_name", "in", batch)
                .get()
                .then((querySnapShot) => {
                  const city = querySnapShot.docs.map((doc) => ({
                    value: doc.data().block_name,
                  }));
                  setallBlock(city);
                });
              index += batchSize;
            }
          }
        });

      // if district has value then display block according to that
      if (localDistrict) {
        blockDb
          .where("district_name", "==", localDistrict)
          .get()
          .then((querySnapShot) => {
            const block = querySnapShot.docs.map((doc) => ({
              value: doc.data().block_name,
            }));
            setallBlock(block);
          });
      }
    } else {
      // If it does not have local value then goen into this
      fetchStatesAndDistricts();
    }
  }, []);

  const fetchStatesAndDistricts = async () => {
    const db = firebase.firestore();

    await db
      .collection("state_detail")
      .get()
      .then((querySnapshot) => {
        const state = querySnapshot.docs.map((doc) => ({
          value: doc.data().state_name,
        }));
        setallStates(state);
      })
      .catch((err) => console.log("err fetching state------", err));

    //get districts
    await db
      .collection("state_district_detail")
      .get()
      .then((querySnapshot) => {
        const district = querySnapshot.docs.map((doc) => ({
          value: doc.data().district_name,
        }));
        const uniqueDistrict = Array.from(
          new Set(district.map((d) => d.value))
        ).map((v) => ({ value: v }));
        setallDistricts(uniqueDistrict);
      })
      .catch((err) => console.log("err fetching district------", err));

    // get block
    await firebase
      .firestore()
      .collection("district_block_detail")
      .get()
      .then((querySnapShot) => {
        const block = querySnapShot.docs.map((doc) => ({
          value: doc.data().block_name,
        }));
        const uniqueBlock = Array.from(new Set(block.map((d) => d.value))).map(
          (v) => ({ value: v })
        );
        setallBlock(uniqueBlock);
      })
      .catch((err) => console.log("err fetching block------", err));
  };
  //handle filter change
  const handleChangeFilter = async (_value, _filterToBeUpdated) => {
    setDisableButton(false);
    //db
    const db = await firebase.firestore().collection("state_district_detail");
    const blockDb = await firebase
      .firestore()
      .collection("district_block_detail");
    //temp storage
    let states = [];
    let districts = [];
    let blocks = [];
    if (_value !== "select") {
      //updating the state and district list
      if (_filterToBeUpdated == "District") {
        //query
        setselectedValueForDistrictFilter(_value);
        setLocalDistrict(_value);
        setLocalBlock("");
        db.where("district_name", "==", _value)
          .get()
          .then(async (docs) => {
            docs.forEach((doc) => {
              states.push({ value: doc.data().state_name });
            });

            await blockDb.get().then((doc) => {
              doc.forEach((dd) => {
                let ddd = dd.data();
                if (ddd["district_name"] == _value) {
                  blocks.push({
                    value: dd.data().block_name,
                  });
                }
              });
            });

            //removing duplicates
            states = states.filter(
              (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
            );
            blocks = blocks.filter(
              (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
            );
            setallBlock(blocks);
            setallStates(states);
          });
      } else if (_filterToBeUpdated == "State") {
        setselectedValueForStateFilter(_value);
        setLocalState(_value);
        //query
        db.where("state_name", "==", _value)
          .get()
          .then(async (docs) => {
            docs.forEach((doc) => {
              districts.push({
                value: doc.data().district_name,
                state_name: doc.data().state_name,
              });
            });

            // fetch block names as per district values
            let ary = districts?.map((i) => i.value);
            let index = 0;
            const batchSize = 10;

            while (index < ary.length) {
              const batch = ary.slice(index, index + batchSize);
              await blockDb
                .where("district_name", "in", batch)
                .get()
                .then((doc) => {
                  doc.forEach((dd) => {
                    let ddd = dd?.data();
                    blocks.push({
                      value: ddd.block_name,
                    });
                  });
                });

              index += batchSize;
            }

            //assigning to state
            setallBlock(blocks);
            setallDistricts(districts);
          });
      } else if (_filterToBeUpdated == "Block") {
        setselectedValueForBlockFilter(_value);
        setLocalBlock(_value);
        //query
        const dbBlock = await firebase
          .firestore()
          .collection("district_block_detail");
        dbBlock
          .where("block_name", "==", _value)
          .get()
          .then(async (docs) => {
            await docs.forEach((doc) => {
              districts.push({
                value: doc.data().district_name,
                district_name: doc.data().district_name,
              });
            });

            // fetch state as per district values
            let ary = districts?.map((i) => i.value);
            let index = 0;
            const batchSize = 10;

            while (index < ary.length) {
              const batch = ary.slice(index, index + batchSize);
              await db
                .where("district_name", "in", batch)
                .get()
                .then((doc) => {
                  doc.forEach((dd) => {
                    let ddd = dd?.data();
                    states.push({
                      value: ddd.state_name,
                    });
                  });
                });

              index += batchSize;
            }
            // //assigning to state
            setallStates(states);
            setallDistricts(districts);
          });
      }
    } else {
      console.log("value is select");
      const states = [];
      const districts = [];
      const blocks = [];
      if (_filterToBeUpdated == "State" && _value == "select") {
        setselectedValueForDistrictFilter("");
        setselectedValueForStateFilter("");
        setselectedValueForBlockFilter("");
        setLocalState("");
        setLocalDistrict("");
        setLocalBlock("");

        fetchStatesAndDistricts();
      } else if (_filterToBeUpdated == "District" && _value == "select") {
        // the state will have been updated between the clicks
        setLocalDistrict("");
        setLocalBlock("");
        setselectedValueForBlockFilter("");
        setselectedValueForDistrictFilter("");
        await db
          .where("state_name", "==", localState)
          .get()
          .then((result) => {
            result.forEach((data) => {
              districts.push({ value: data.data().district_name });
            });
            districts = districts.filter(
              (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
            );
            setallDistricts(districts);
            let ary = districts?.map((i) => i.value);
            let index = 0;
            const batchSize = 10;

            while (index < ary.length) {
              const batch = ary.slice(index, index + batchSize);
              blockDb
                .where("district_name", "in", batch)
                .get()
                .then((doc) => {
                  doc.forEach((dd) => {
                    let ddd = dd?.data();
                    blocks.push({
                      value: ddd.block_name,
                    });
                  });
                  setallBlock(blocks);
                });
              index += batchSize;
            }
          });
      } else if (_filterToBeUpdated == "Block" && _value == "select") {
        // the state will have been updated between the clicks
        setselectedValueForBlockFilter("");
        setLocalBlock("");
        await db
          .where("state_name", "==", localState)
          .where("district_name", "==", localDistrict)
          .get()
          .then((result) => {
            result.forEach((data) => {
              states.push({ value: data.data().state_name });
              districts.push({ value: data.data().district_name });
            });
          });

        await blockDb
          .where("district_name", "==", localDistrict)
          .get()
          .then((doc) => {
            doc.forEach((dd) => {
              blocks.push({
                value: dd.data().block_name,
              });
            });
          });

        states = states.filter(
          (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
        );
        setallStates(states);
        setallDistricts(districts);
        setallBlock(blocks);
      }
    }
  };

  useEffect(() => {
    setUserData((prevState) => ({
      ...prevState,
      values: {
        ...prevState?.values,
        state: localState,
        district: localDistrict,
        city: localBlock,
      },
    }));
  }, [localState, localDistrict, localBlock]);

  // to disable right click context menu on profile click
  const handleContextMenu = (event) => {
    event.preventDefault();
    const img = document.getElementById("my-image");
    // to disable copy form web
    img.addEventListener("mousedown", (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        // Optionally, you can display an error message or take other actions to prevent the user from accessing the image.
      }
    });
  };

  return (
    <MyLayout>
      <MyDesktop>
        {
          <Modal show={!isimageValid} centered>
            <Modal.Body className="text-center text-danger">
              Please select image size less than 1 MB
            </Modal.Body>
          </Modal>
        }
        {
          <Modal show={errorImageUpload} centered>
            <Modal.Body className="text-center text-danger">
              Error in uploading Image
            </Modal.Body>
          </Modal>
        }
        {
          <Modal show={imageUpload} centered>
            <Modal.Body className="text-center text-success">
              Image Uploaded Successfully
            </Modal.Body>
          </Modal>
        }
        {
          <Modal show={sucessUpdated} centered>
            <Modal.Body className="text-center text-success">
              Profile Updated Successfully
            </Modal.Body>
          </Modal>
        }
        <div className="artist_profile">
          <div className="th-bg-white pb-4 th-br-16">
            <>
              <div className="d-flex th-br-15 pb-4 edit-artist-profile web-media-responsive">
                <div className="col-md-3 pt-4 pb-4 text-center d-flex image-part">
                  <div className="d-flex flex-column m-auto">
                    <div
                      className="artist-circle text-center mx-auto rounded-circle pb-4"
                      id="my-image"
                      onContextMenu={handleContextMenu}
                      style={{
                        backgroundColor: "#fff",
                        // backgroundImage: `url(${
                        //   imageData ? imageData : AvatarDefault
                        // })`,
                        // height: "250px",
                        // width: "250px",
                        // backgroundSize: "cover",
                        // backgroundRepeat: "no-repeat",
                        // backgroundPosition: "center",
                        // border: "1px solid white",
                        // borderRadius: "50%",
                        // boxShadow:
                        //   "-5.23738px -5.23738px 20.9495px #FFFFFF, 5.23738px 5.23738px 20.9495px rgba(174, 174, 192, 0.5)",
                      }}
                    >
                      <img
                        src={imageData ? imageData : AvatarDefault}
                        style={{
                          height: "28vh",
                          width: "28vh",

                          border: "1px solid white",
                          borderRadius: "50%",
                          boxShadow:
                            "-5.23738px -5.23738px 20.9495px #FFFFFF, 5.23738px 5.23738px 20.9495px rgba(174, 174, 192, 0.5)",
                        }}
                        onError={(e) => (e.target.src = AvatarDefault)}
                      />
                    </div>
                    {/* 
                    <div
                      className="media-main-image"
                      id="my-image"
                      style={{
                        backgroundImage: `url(${!imageData && AvatarDefault})`,
                        backgroundSize: "contain",
                        backgroundPosition: "top",
                        height: "250px",
                        width: "100%",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#fff",
                      }}
                      onContextMenu={handleContextMenu}
                    >
                      <img
                        src={imageData ? imageData : AvatarDefault}
                        style={{
                          width: "auto",
                          height: "100%",
                        }}
                        onError={(e) => (e.target.src = AvatarDefault)}
                      />
                    </div> */}
                    <div className="image_upload">
                      <input
                        style={{ display: "none" }}
                        id="file-input"
                        type="file"
                        accept="image/*"
                        multiple
                        title=""
                        onChange={(e) => handleFileUpload(e, "avatar")}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                      <label
                        htmlFor="file-input"
                        className="upload-pic px-3 py-2 th-btn-primary-br-grey th-box-shadow h-white th-12 th-fw-600"
                        role="button"
                        accept="image/*"
                      >
                        Upload Picture
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 d-flex displayMediaArtist">
                  <div className="col-md-6 col-lg-4 pt-4 pb-4 account-profile">
                    <div>
                      <div className="th-12 form-label">Unique Id</div>
                      <div>
                        <input
                          value={userData?.username}
                          onChange={(e) => handleInputChange(e)}
                          type="text"
                          placeholder=""
                          className="w-70 form-control-plaintext shadow-none th-lt-grey px-1"
                          name="username"
                          disabled
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div>
                      <div className="th-12 form-label">Email</div>
                      <div>
                        <input
                          value={userData?.values?.email}
                          onChange={(e) => handleInputChange(e)}
                          type="text"
                          placeholder=""
                          className="w-70 form-control-plaintext shadow-none th-lt-grey px-1"
                          name="email"
                          readOnly
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div style={{ position: "relative" }}>
                      <div className="th-12 form-label">Phone Number</div>
                      <div>
                        <span
                          className="hearby_condition"
                          style={{ color: "#3CAF5C" }}
                        >
                          <span style={{ marginRight: "3px" }}>
                            Hereby I agree to give my contact details{" "}
                          </span>
                          <input
                            style={{ width: "14px", height: "14px" }}
                            onChange={(e) => HearBy(e)}
                            type="checkbox"
                            value={hearbycheckbox}
                            checked={hearbycheckbox}
                          />
                        </span>
                        <input
                          value={userData?.values?.phonenumber}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          placeholder=""
                          className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                          name="phonenumber"
                          disabled={!hearByCheck ? true : false}
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div>
                      <div className="th-12 form-label">State/UTs</div>
                      <div>
                        <ArtSelect
                          attribute="state"
                          f_name="State"
                          limit={100}
                          className="state_filter_artist"
                          passedDownItems={allStates}
                          onChangeFun_={handleChangeFilter}
                          selectedValue={selectedValueForStateFilter}
                          localValue={localState}
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div>
                      <div className="th-12 form-label">District</div>
                      <div>
                        <ArtSelect
                          attribute="district"
                          f_name="District"
                          limit={1000}
                          className="district_filter_artist"
                          passedDownItems={allDistricts}
                          onChangeFun_={handleChangeFilter}
                          selectedValue={selectedValueForDistrictFilter}
                          localValue={localDistrict}
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div>
                      <div className="th-12 form-label">Village</div>
                      <div>
                        <ArtSelect
                          attribute="village"
                          f_name="Block"
                          limit={1000}
                          className="village_filter_artist"
                          passedDownItems={allBlock}
                          onChangeFun_={handleChangeFilter}
                          selectedValue={selectedValueForBlockFilter}
                          localValue={localBlock}
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div>
                      <div className="th-12 form-label">
                        Years of experience
                      </div>
                      <div>
                        <input
                          value={userData?.values?.experience}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                          name="experience"
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4 pt-4 pb-4 account-profile">
                    <div>
                      <div className="th-12 form-label">Full Name</div>
                      <div>
                        <input
                          value={userData?.values?.name}
                          onChange={(e) => handleInputChange(e)}
                          type="text"
                          placeholder=""
                          className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                          name="name"
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div>
                      <div className="th-12 form-label">Bio</div>
                      <div>
                        <textarea
                          value={userData?.values?.bio}
                          onChange={(e) => handleInputChange(e)}
                          type="text"
                          placeholder=""
                          className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                          name="bio"
                          rows="4"
                          cols="50"
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>

                    <div>
                      <div className="th-12 form-label">Name of Art</div>
                      <div>
                        <input
                          value={userData?.values?.artname}
                          onChange={(e) => handleInputChange(e)}
                          type="text"
                          placeholder=""
                          className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                          name="artname"
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div>
                      <div className="th-12 form-label">Description</div>
                      <div>
                        <textarea
                          value={userData?.values?.description}
                          onChange={(e) => handleInputChange(e)}
                          type="text"
                          placeholder=""
                          className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                          name="description"
                          rows="4"
                          cols="50"
                        />
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    <div>
                      <div className="th-12 form-label">Art Form/ Art type</div>
                      <div>
                        <select
                          value={userData?.values?.arttype}
                          name="arttype"
                          className="w-100 form-control-plaintext shadow-none th-dk-grey px-1"
                          onChange={(e) => handleInputChange(e, "artType")}
                        >
                          <option value="">Select</option>
                          <option value="Folk Music">Folk Music</option>
                          <option value="Folk Dance">Folk Dance</option>
                          <option value="Folk Songs">Folk Songs</option>
                          <option value="Folk Theatre">Folk Theatre</option>
                          <option value="Folk Dance Drama">
                            Folk Dance Drama
                          </option>
                          <option value="Folk Tale Narration">
                            Folk Tale Narration
                          </option>
                          <option value="Folk Epic Singing">
                            Folk Epic Singing
                          </option>
                          <option value="Martial Arts ">Martial Arts </option>
                          <option value="Painting/Drawing">
                            Painting/Drawing
                          </option>
                          <option value="Wood Craft">Wood Craft</option>
                          <option value="Metal Craft">Metal Craft</option>
                          <option value="Paper Craft">Paper Craft</option>
                          <option value="Clay and Mud Craft">
                            Clay and Mud Craft
                          </option>
                          <option value="Stone Craft">Stone Craft</option>
                          <option value="Bone Craft">Bone Craft</option>
                          <option value="Leather Craft">Leather Craft</option>
                          <option value="Glass Craft">Glass Craft</option>
                          <option value="Cane Craft">Cane Craft</option>
                          <option value="Grass Craft">Grass Craft</option>
                          <option value="Bamboo and Basketry">
                            Bamboo and Basketry
                          </option>
                          <option value="Weaving">Weaving</option>
                          <option value="Needle and Thread work">
                            Needle and Thread work
                          </option>
                          <option value="Textile and Printing">
                            Textile and Printing
                          </option>
                          <option value="Theatre Craft">Theatre Craft</option>
                          <option value="anyother">Any Other</option>
                        </select>
                        <hr className="th-hr mb-0"></hr>
                      </div>
                    </div>
                    {anyOther && (
                      <div>
                        <div className="th-12 form-label">
                          Any Other Art Type
                        </div>
                        <div>
                          <input
                            value={userData?.values?.arttypeAny}
                            onChange={(e) => handleInputChange(e)}
                            type="text"
                            placeholder=""
                            className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                            name="arttypeAny"
                          />
                          <hr className="th-hr mb-0"></hr>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 pt-4 pb-4 d-flex position-relative justify-content-center">
                <div>
                  {disableButton ? (
                    <span
                      className="btn_browse"
                      role="button"
                      style={{
                        backgroundColor: "#dadada",
                        color: "#565656",
                        cursor: "not-allowed",
                        fontSize: "16px",
                        color: "#ffffff",
                      }}
                    >
                      Save Changes
                    </span>
                  ) : loader ? (
                    <span
                      className=" btn_browse"
                      role="button"
                      onClick={(e) => handleSubmit(e)}
                    >
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Wait...
                    </span>
                  ) : (
                    <span
                      className=" btn_browse"
                      role="button"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Save Changes{" "}
                    </span>
                  )}
                </div>
              </div>
            </>
          </div>

          <div className="th-fw-500 th-20 th-grey-4 py-3 d-flex justify-content-between align-items-center">
            <div> Your Media </div>
            <div
              className="btn_submit"
              onClick={() => setModalShow(true)}
              role="button"
            >
              <img src={Plus} alt="..." /> Add New Media
            </div>
          </div>

          {!loading ? (
            <div className="col-md-12">
              <div className="recentLoading media">Loading....</div>
            </div>
          ) : (
            <div className={"row " + (mediaData.length == 0 ? " g-0" : "p-1")}>
              {mediaData && mediaData.length > 0 ? (
                mediaData.map((hit, index) => (
                  <div
                    className="col-lg-3 col-md-12 pb-3 px-2 custom artistcard"
                    key={index}
                  >
                    <MediaCard
                      item={hit}
                      media_name={hit?.values?.title}
                      price={hit?.values?.price}
                      cover={hit?.avatar}
                      description={hit?.values?.description}
                      getMediaData={getMediaData}
                    />
                  </div>
                ))
              ) : (
                <div className="row g-0 d-flex justify-content-center">
                  <div className="bg-white th-br-16">
                    <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                      <div className="text-center">
                        <div className="w-50 mx-auto">
                          <img
                            src={IndibadgesImg}
                            className="edit-media-image"
                            alt="media-image"
                          />
                        </div>
                        <h4 className="pt-3 th-14">No Media found.</h4>
                        <div className="th-fw-500 th-20 th-grey-4 pt-3 d-flex justify-content-center">
                          <button
                            className="btn_submit"
                            onClick={() => setModalShow(true)}
                          >
                            {" "}
                            Add New Media{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </MyDesktop>
      <MyMobile>
        <div className="artist_profile mobile-theme">
          {
            <Modal show={!isimageValid} centered>
              <Modal.Body className="text-center text-danger">
                {"Please select image size less than 1 MB"}
              </Modal.Body>
            </Modal>
          }
          {
            <Modal show={errorImageUpload} centered>
              <Modal.Body className="text-center text-danger">
                Error in uploading Image
              </Modal.Body>
            </Modal>
          }
          {
            <Modal show={sucessUpdated} centered>
              <Modal.Body className="text-center text-success">
                Profile Updated Successfully
              </Modal.Body>
            </Modal>
          }
          {
            <Modal show={imageUpload} centered>
              <Modal.Body className="text-center text-success">
                Image Uploaded Successfully
              </Modal.Body>
            </Modal>
          }
          <div className="th-bg-white th-br-16">
            <div className="col-md-3 pt-4 pb-4 text-center d-flex">
              <div className="d-flex flex-column m-auto">
                <div
                  className="text-center mx-auto rounded-circle pt-4"
                  style={
                    {
                      // backgroundImage: `url(${
                      //   imageData ? imageData : AvatarDefault
                      // // })`,
                      // height: "190px",
                      // width: "190px",
                      // backgroundSize: "cover",
                      // backgroundRepeat: "no-repeat",
                      // backgroundPosition: "center",
                      // border: "1px solid white",
                      // boxShadow:
                      //   "-5.23738px -5.23738px 20.9495px #FFFFFF, 5.23738px 5.23738px 20.9495px rgba(174, 174, 192, 0.5)",
                    }
                  }
                >
                  <img
                    src={imageData ? imageData : AvatarDefault}
                    style={{
                      height: "190px",
                      width: "190px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      border: "1px solid white",
                      borderRadius: "50%",
                      boxShadow:
                        "-5.23738px -5.23738px 20.9495px #FFFFFF, 5.23738px 5.23738px 20.9495px rgba(174, 174, 192, 0.5)",
                    }}
                    onError={(e) => (e.target.src = AvatarDefault)}
                  />
                </div>
                <div className="image_upload">
                  <input
                    style={{ display: "none" }}
                    id="file-input"
                    type="file"
                    accept="image/*"
                    title=""
                    onChange={(e) => handleFileUpload(e, "avatar")}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <label
                    htmlFor="file-input"
                    className="upload-pic px-3 py-2 th-btn-primary-br-grey th-box-shadow h-white th-12 th-fw-600"
                    role="button"
                    accept="image/*"
                  >
                    Upload Picture
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 th-br-15 th-bg-white px-3 mobile edit-artist-profile">
              <div
                className="col-12 col-md-12 pt-1 mobile-theme-profile"
                // style={{ paddingRight: "15px" }}
              >
                <div>
                  <div className="th-12 form-label">Unique Id</div>
                  <div className="unique_id_display">
                    <input
                      value={userData?.username}
                      type="text"
                      placeholder=""
                      className="form-control-plaintext shadow-none th-lt-grey px-1 "
                      name="username"
                      readOnly
                      disabled
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
                <div className="th-12 form-label">Full Name</div>
                <div>
                  <input
                    value={userData?.values?.name}
                    onChange={(e) => handleInputChange(e)}
                    type="text"
                    placeholder=""
                    className="form-control-plaintext shadow-none th-dk-grey px-1"
                    name="name"
                  />
                  <hr className="th-hr mb-0"></hr>
                </div>
                <div>
                  <div className="th-12 form-label email_profile">Email</div>
                  <div>
                    <input
                      value={userData?.values?.email}
                      onChange={(e) => handleInputChange(e)}
                      type="text"
                      placeholder=""
                      className="form-control-plaintext shadow-none th-lt-grey px-1"
                      name="email"
                      readOnly
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
                <div>
                  <div className="th-12 form-label">Bio</div>
                  <div>
                    <textarea
                      value={userData?.values?.bio}
                      onChange={(e) => handleInputChange(e)}
                      type="text"
                      placeholder=""
                      className="form-control-plaintext shadow-none th-dk-grey px-1"
                      name="bio"
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <div className="th-12 form-label">Phone Number</div>
                  <div>
                    <span
                      className="hearby_condition"
                      style={{ color: "#3CAF5C" }}
                    >
                      <span style={{ marginRight: "3px" }}>
                        Hereby I agree to give my contact details{" "}
                      </span>
                      <input
                        style={{ width: "30px", height: "30px" }}
                        onChange={(e) => HearBy(e)}
                        type="checkbox"
                        value={hearbycheckbox}
                        checked={hearbycheckbox}
                      />
                    </span>
                    <input
                      value={userData?.values?.phonenumber}
                      onChange={(e) => handleInputChange(e)}
                      type="number"
                      placeholder=""
                      className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                      name="phonenumber"
                      disabled={!hearByCheck ? true : false}
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
                <div>
                  <div className="th-12 form-label">State/UTs</div>
                  <div>
                    <ArtSelect
                      attribute="state"
                      f_name="State"
                      limit={1000}
                      className="state_filter_artist"
                      passedDownItems={allStates}
                      onChangeFun_={handleChangeFilter}
                      selectedValue={selectedValueForStateFilter}
                      localValue={localState}
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 pt-2 px-3 mobile-theme-profile">
              <div>
                <div className="th-12 form-label">District</div>
                <div>
                  <ArtSelect
                    attribute="district"
                    f_name="District"
                    limit={1000}
                    className="district_filter_artist"
                    passedDownItems={allDistricts}
                    onChangeFun_={handleChangeFilter}
                    selectedValue={selectedValueForDistrictFilter}
                    localValue={localDistrict}
                  />
                  <hr className="th-hr mb-0"></hr>
                </div>
              </div>
              <div>
                <div className="th-12 form-label">Village</div>
                <div>
                  {/* <input
                    value={userData?.values?.city}
                    onChange={(e) => handleInputChange(e)}
                    type="text"
                    placeholder=""
                    className="form-control-plaintext shadow-none th-dk-grey px-1"
                    name="city"
                  /> */}
                  <ArtSelect
                    attribute="village"
                    f_name="Block"
                    limit={1000}
                    className="village_filter_artist"
                    passedDownItems={allBlock}
                    onChangeFun_={handleChangeFilter}
                    selectedValue={selectedValueForBlockFilter}
                    localValue={localBlock}
                  />
                  <hr className="th-hr mb-0"></hr>
                </div>
              </div>

              <div>
                <div className="th-12 form-label pt-2">Art Form/ Art type</div>
                <div>
                  <select
                    value={userData?.values?.arttype}
                    name="arttype"
                    className="w-100 form-control-plaintext shadow-none th-dk-grey px-1"
                    onChange={(e) => handleInputChange(e, "artType")}
                  >
                    <option value="">Select</option>
                    <option value="Folk Music">Folk Music</option>
                    <option value="Folk Dance">Folk Dance</option>
                    <option value="Folk Songs">Folk Songs</option>
                    <option value="Folk Theatre">Folk Theatre</option>
                    <option value="Folk Dance Drama">Folk Dance Drama</option>
                    <option value="Folk Tale Narration">
                      Folk Tale Narration
                    </option>
                    <option value="Folk Epic Singing">Folk Epic Singing</option>
                    <option value="Martial Arts ">Martial Arts </option>
                    <option value="Painting/Drawing">Painting/Drawing</option>
                    <option value="Wood Craft">Wood Craft</option>
                    <option value="Metal Craft">Metal Craft</option>
                    <option value="Paper Craft">Paper Craft</option>
                    <option value="Clay and Mud Craft">
                      Clay and Mud Craft
                    </option>
                    <option value="Stone Craft">Stone Craft</option>
                    <option value="Bone Craft">Bone Craft</option>
                    <option value="Leather Craft">Leather Craft</option>
                    <option value="Glass Craft">Glass Craft</option>
                    <option value="Cane Craft">Cane Craft</option>
                    <option value="Grass Craft">Grass Craft</option>
                    <option value="Bamboo and Basketry">
                      Bamboo and Basketry
                    </option>
                    <option value="Weaving">Weaving</option>
                    <option value="Needle and Thread work">
                      Needle and Thread work
                    </option>
                    <option value="Textile and Printing">
                      Textile and Printing
                    </option>
                    <option value="Theatre Craft">Theatre Craft</option>
                    <option value="anyother">Any Other</option>
                  </select>
                  <hr className="th-hr mb-0"></hr>
                </div>
              </div>
              <div>
                <div className="th-12 form-label pt-2">Name of Art</div>
                <div>
                  <input
                    value={userData?.values?.artname}
                    onChange={(e) => handleInputChange(e)}
                    type="text"
                    placeholder=""
                    className="form-control-plaintext shadow-none th-dk-grey px-1"
                    name="artname"
                  />
                  <hr className="th-hr mb-0"></hr>
                </div>
                <div>
                  <div className="th-12 form-label pt-2">
                    Years of experience
                  </div>
                  <div>
                    <input
                      value={userData?.values?.experience}
                      onChange={(e) => handleInputChange(e)}
                      type="number"
                      placeholder=""
                      className="form-control-plaintext shadow-none th-dk-grey px-1"
                      name="experience"
                    />
                    <hr className="th-hr mb-0"></hr>
                  </div>
                </div>
              </div>
              <div>
                <div className="th-12 form-label pt-2">Description</div>
                <div>
                  <textarea
                    value={userData?.values?.description}
                    onChange={(e) => handleInputChange(e)}
                    type="text"
                    placeholder=""
                    className="form-control-plaintext shadow-none th-dk-grey px-1"
                    name="description"
                  />
                  <hr className="th-hr mb-0"></hr>
                </div>

                {anyOther && (
                  <div>
                    <div className="th-12 form-label">Any Other Art Type</div>
                    <div>
                      <input
                        value={userData?.values?.arttypeAny}
                        onChange={(e) => handleInputChange(e)}
                        type="text"
                        placeholder=""
                        className="w-70 form-control-plaintext shadow-none th-dk-grey px-1"
                        name="arttypeAny"
                      />
                      <hr className="th-hr mb-0"></hr>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3 pt-4 pb-4 d-flex position-relative justify-content-center">
              <div style={{ bottom: "30px" }}>
                {disableButton ? (
                  <span
                    className="btn_browse"
                    role="button"
                    style={{
                      backgroundColor: "#dadada",
                      color: "#565656",

                      cursor: "not-allowed",
                      fontSize: "16px",
                      color: "#ffffff",
                    }}
                  >
                    Save Changes
                  </span>
                ) : loader ? (
                  <span
                    className=" btn_browse"
                    role="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Wait...
                  </span>
                ) : (
                  <span
                    className=" btn_browse"
                    role="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save Changes{" "}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="th-fw-500 th-20 th-grey-4 pt-3 d-flex justify-content-between mb-3 align-items-center">
            <div> Your Media </div>
            <div
              className="btn_submit"
              onClick={() => setModalShow(true)}
              role="button"
            >
              <img src={Plus} alt="..." />
              {mediaData && mediaData.length > 0 && (
                <button className="btn_submit_plus">Add New Media </button>
              )}
            </div>
          </div>

          {!loading ? (
            <div className="col-md-12">
              <div className="recentLoading media">Loading....</div>
            </div>
          ) : (
            <div
              className={
                "row artist-mobile-scroll " +
                (mediaData.length > 0 ? " p-1 pb-3" : " pb-3")
              }
            >
              {mediaData && mediaData.length > 0 ? (
                mediaData.map((hit, index) => (
                  <div
                    className="col-lg-3 col-md-12 pb-3 px-2 custom artistcard"
                    key={index}
                  >
                    <MediaCard
                      item={hit}
                      media_name={hit?.values?.title}
                      price={hit?.values?.price}
                      cover={hit?.avatar}
                      description={hit?.values?.description}
                      getMediaData={getMediaData}
                    />
                  </div>
                ))
              ) : (
                <div className="row g-0 d-flex justify-content-center p-3">
                  <div className="bg-white th-br-16 p-3">
                    <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                      <div className="text-center">
                        <div className="w-50 mx-auto middleimg">
                          <img
                            src={IndibadgesImg}
                            className="edit-media-image"
                            alt="media-image"
                          />
                        </div>
                        <h4 className="pt-3 th-14">No Media found.</h4>
                        <div className="th-fw-500 th-20 th-grey-4 pt-3 d-flex justify-content-center">
                          <button
                            className="btn_submit"
                            onClick={() => setModalShow(true)}
                          >
                            {" "}
                            Add New Media{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </MyMobile>
      {modalShow ? (
        <AddEditMedia
          show={modalShow}
          onHide={() => setModalShow(false)}
          edit={false}
          getMediaData={getMediaData}
        />
      ) : null}
    </MyLayout>
  );
};

export default EditProfile;
