import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import ReactPlaceholder from "react-placeholder";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { GoogleMap, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import firebase from "../../../firebase";
import MyLayout from "../../Components/MyLayout";
import closeLogo from "../../Assets/img/icons/close.svg";
import Default from "../../Assets/img/General/default.png";
import "react-placeholder/lib/reactPlaceholder.css";
import "./index.css";
import { distanceBetween, geohashQueryBounds } from "geofire-common";
import { Tooltip } from "@mui/material";

const VillageImage = React.memo(({ image_id }) => {
  const [imgUrl, setImgUrl] = useState("");
  const [process, setProcess] = useState(true);

  const getData = async () => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/75x75/images/${image_id}`;
    try {
      const response = await axios.get(url);
      return response.data.data;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  useEffect(() => {
    if (image_id) {
      getData().then((dd) => {
        setImgUrl(dd);
        setProcess(false);
      });
    } else {
      setProcess(false);
      setImgUrl(Default);
    }
  }, [image_id]);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ cursor: "pointer", display: process ? "none" : "" }}
    >
      <img
        style={{
          width: "20px",
          height: "20px",
        }}
        onError={(e) => (e.target.src = Default)}
        src={imgUrl ? imgUrl : Default}
        alt="..."
      />
    </div>
  );
});

const containerStyle = {
  width: "100%",
  height: "700px",
  borderRadius: "30px",
};

const center = {
  lat: 22.677933,
  lng: 80.461648,
};
function Index() {
  const [selected, setSelected] = React.useState(0);
  const [fireData, setFireData] = React.useState([]);
  const [isItemDetail, setIsItemDetail] = React.useState();
  const [isItemDetailImg, setIsItemDetailImg] = React.useState();

  const hasWindow = typeof window !== "undefined";

  const history = useHistory();

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    return width;
  }

  const getData = async (id) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/150x150/filters:quality(80)/images/${id}`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response.data.data;
    });
    setIsItemDetailImg(data);
  };

  let [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  const chipStyle = {
    backgroundColor: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    borderRadius: "15px",
    cursor: "pointer",
  };
  const selectedChipStyle = {
    backgroundColor: "#75CF8F",
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    borderRadius: "15px",
    cursor: "pointer",
  };

  const [topicsData, setTopicData] = useState([]);
  const [clickItem, setClickItem] = useState();
  const [dataLoaded, setLoaded] = useState(false);
  const [dataLoaded2, setLoaded2] = useState(false);
  const [map, setMap] = useState(null);
  const [viewport, setViewport] = useState({});
  const [foodData, setFoodData] = useState([]);
  const [dressData, setDressData] = useState([]);
  const [festivalData, setFestivalData] = useState([]);
  const [placeData, setPlaceData] = useState([]);
  const [ornamentData, setornamentData] = useState([]);
  const [libraries] = useState(["geometry", "drawing"]);

  //To Laod and set map refrence in state.
  useEffect(() => {
    const preventZoom = (e) => {
      e.preventDefault();
    };

    // Add event listeners when the component mounts
    document.addEventListener("gesturestart", preventZoom, { passive: false });

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener("gesturestart", preventZoom);
    };
  }, []);
  const handleLoad = (map) => {
    setMap(map);
  };

  //To get Bounds and coordinates of center  when drag or zoom.

  function handleBounds() {
    if (map) {
      // Get the bounds of the current viewport
      const bounds = map.getBounds();

      // Get the center point of the bounds
      const center = bounds?.getCenter();

      // Reverse geocode the center point to get the state name or coordinate
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: center }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            // Parse the state name or coordinate from the geocoding result
            const addressComponents = results[0]?.address_components;
            const stateName = addressComponents?.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name;
            const districtName = addressComponents?.find((component) =>
              component.types.includes("administrative_area_level_3")
            )?.long_name;
            const coordinate = center.toJSON();

            // Update the viewport state with the state name or coordinate
            setViewport({
              stateName,
              districtName,
              coordinate,
            });
          }
        }
      });
    }
  }

  useEffect(() => {
    if (topicsData) {
      if (selected == 0) {
        setClickItem(topicsData["traditional_food"]);
      } else if (selected == 1) {
        setClickItem(topicsData["festivals"]);
      } else if (selected == 2) {
        setClickItem(topicsData["heritage_places"]);
      } else if (selected == 3) {
        setClickItem(topicsData["famous_personalities"]);
      } else if (selected == 4) {
        setClickItem(topicsData["ornaments"]);
      } else if (selected == 5) {
        setClickItem(topicsData["traditional_dress"]);
      }
    }
  }, [topicsData]);

  const closeMapSidebar = () => {
    setIsItemDetail("");
  };

  //fetching data when user clicks.

  const fetchData = (key) => {
    let db = firebase.firestore();
    let docRef = db.collection("large_village_detail");
    docRef
      .doc(key)
      .get()
      .then((docc) => {
        setTopicData(docc?.data()?.data);
      });
  };

  const ImageClick = (s_item) => {
    if (s_item) {
      setIsItemDetail(s_item);
      getData(s_item.media_name);
      fetchData(s_item.key);
    }
  };

  const [currentZoom, setCurrentZoom] = useState(5); //default
  function handleZoomChanged() {
    setCurrentZoom(this.getZoom());
  }

  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  // Wrap the geoPoint function with debouncing
  // const debouncedGeoPoint = debounce(geoPoint, 500); // Adjust the delay (in milliseconds) as per your requirements

  // Usage inside the useEffect hook
  const debouncedGeoPoint = (coord) => {
    setLoaded2(false);
    let sel;
    switch (selected) {
      case 0:
        sel = "food";
        break;
      case 1:
        sel = "festivals";
        break;
      case 2:
        sel = "heritage_places";
        break;
      case 3:
        sel = "famous_personalities";
        break;
      case 4:
        sel = "ornaments";
        break;
      case 5:
        sel = "traditional_dress";
        break;
      default:
        sel = "food";
        break;
    }
    const center = [parseFloat(coord.lat), parseFloat(coord.lng)];
    const radiusInM = currentZoom > 7 ? 50 * 1000 : 100 * 1000;

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    let q;
    let promises = [];
    if (currentZoom > 6) {
      const bounds = geohashQueryBounds(center, radiusInM);

      let db = firebase.firestore();
      for (const b of bounds) {
        const q = db
          .collection("large_village_metadata3")
          .limit(50)
          .orderBy("geohash")
          .orderBy(sel)
          .startAt(b[0])
          .endAt(b[1]);

        promises.push(q.get());
      }
    }

    // Collect all the query results together into a single list
    Promise.all(promises)
      .then((snapshots) => {
        const matchingDocs = [];

        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const lat = parseFloat(doc.get("lat"));
            const lng = parseFloat(doc.get("long"));

            // We have to filter out a few false positives due to GeoHash
            // accuracy, but most will match
            const distanceInKm = distanceBetween([lat, lng], center);
            const distanceInM = distanceInKm * 1000;
            if (distanceInM <= radiusInM) {
              matchingDocs.push(doc);
            }
          }
        }

        return matchingDocs;
      })
      .then((matchingDocs) => {
        let list = [];
        matchingDocs.forEach((e) => {
          let data = e.data();
          if (Object.keys(data).includes(sel)) {
            let object = {
              position: {
                lat: parseFloat(data?.lat),
                lng: parseFloat(data?.long),
              },
              name: data?.[sel]?.[0]?.name,
              media: data?.[sel]?.[0]?.media_id,
              media_name: data?.[sel]?.[0]?.media_name,
              village_name: data.village_name,
              state_name: data.state_name,
              key: data.key,
              id: data?.[sel]?.[0]?.media_id,
            };
            if (sel == "food") {
              const result =
                foodData.filter((obj) => obj.media_name === object.media_name)
                  .length != 0;
              if (!result) {
                list.push(object);
              }
            }

            if (sel == "festivals") {
              const result = festivalData.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }

            if (sel == "heritage_places") {
              const result = placeData.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }

            if (sel == "ornaments") {
              const result = ornamentData.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }

            if (sel == "traditional_dress") {
              const result = dressData.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }
          }
          if (sel == "food") {
            setFoodData([...foodData, ...list]);
            setLoaded2(true);
          }

          if (sel == "festivals") {
            setFestivalData([...festivalData, ...list]);
            setLoaded2(true);
          }

          if (sel == "heritage_places") {
            setPlaceData([...placeData, ...list]);
            setLoaded2(true);
          }

          if (sel == "ornaments") {
            setornamentData([...ornamentData, ...list]);
            setLoaded2(true);
          }

          if (sel == "traditional_dress") {
            setDressData([...dressData, ...list]);
            setLoaded2(true);
          }
        });
      });
  };
  useEffect(() => {
    console.log("function called");
    if (viewport.coordinate && currentZoom > 6) {
      debouncedGeoPoint(viewport.coordinate);
    }

    if (viewport.coordinate && currentZoom == 6) {
      setLoaded2(false);
      console.log("inside");
      let sel;

      switch (selected) {
        case 0:
          sel = "food";

          break;
        case 1:
          sel = "festivals";

          break;
        case 2:
          sel = "heritage_places";

          break;
        case 3:
          sel = "famous_personalities";

          break;
        case 4:
          sel = "ornaments";

          break;
        case 5:
          sel = "traditional_dress";

          break;
        default:
          sel = "food";

          break;
      }
      let db = firebase.firestore();

      let docRef = db.collection("large_village_metadata3").orderBy(sel);

      docRef
        .limit(120)
        .get()
        .then((snapshots) => {
          let list;
          if (sel == "food") {
            list = foodData;
          }

          if (sel == "festivals") {
            list = festivalData;
          }

          if (sel == "heritage_places") {
            list = placeData;
          }

          if (sel == "ornaments") {
            list = ornamentData;
          }

          if (sel == "traditional_dress") {
            list = dressData;
          }

          snapshots.forEach((snapshot) => {
            let data = snapshot.data();

            let object = {
              position: {
                lat: parseFloat(data?.lat),
                lng: parseFloat(data?.long),
              },
              name: data?.[sel]?.[0]?.name,
              media: data?.[sel]?.[0]?.media_id,
              media_name: data?.[sel]?.[0]?.media_name,
              village_name: data.village_name,
              state_name: data.state_name,
              key: data.key,
              id: data?.[sel]?.[0]?.media_id,
            };
            if (sel == "food") {
              const result = list.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }

            if (sel == "festivals") {
              const result = list.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }

            if (sel == "heritage_places") {
              const result = placeData.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }

            if (sel == "ornaments") {
              const result = list.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }

            if (sel == "traditional_dress") {
              const result = list.some((obj) => obj.key === object.key);
              if (!result) {
                list.push(object);
              }
            }
          });

          if (sel == "food") {
            setFoodData([...list]);
            setLoaded(true);
            setLoaded2(true);
          }

          if (sel == "festivals") {
            setFestivalData([...list]);
            setLoaded(true);
            setLoaded2(true);
          }

          if (sel == "heritage_places") {
            setPlaceData([...list]);
            setLoaded(true);
            setLoaded2(true);
          }

          if (sel == "ornaments") {
            setornamentData([...list]);
            setLoaded(true);
            setLoaded2(true);
          }

          if (sel == "traditional_dress") {
            setDressData([...list]);
            setLoaded(true);
            setLoaded2(true);
          }
        });
    }
    // Return a cleanup function to cancel any ongoing tasks or subscriptions
    return () => {
      // Cancel any pending asynchronous tasks here, e.g., aborting an API call or clearing timers
      // For example, if you're using the Fetch API, you can use an AbortController to cancel the request
      // if (controller) controller.abort();
    };
  }, [viewport.coordinate]);

  //Geopoint

  // function geoPoint(coord) {
  //   let sel;
  //   switch (selected) {
  //     case 0:
  //       sel = "food";
  //       break;
  //     case 1:
  //       sel = "festivals";
  //       break;
  //     case 2:
  //       sel = "heritage_places";
  //       break;
  //     case 3:
  //       sel = "famous_personalities";
  //       break;
  //     case 4:
  //       sel = "ornaments";
  //       break;
  //     case 5:
  //       sel = "traditional_dress";
  //       break;
  //     default:
  //       sel = "food";
  //       break;
  //   }
  //   const center = [parseFloat(coord.lat), parseFloat(coord.lng)];
  //   const radiusInM = 50 * 1000;

  // }

  useEffect(() => {
    setLoaded2(false);
    setCurrentZoom(5);

    console.log("inside");
    let sel;

    switch (selected) {
      case 0:
        sel = "food";

        break;
      case 1:
        sel = "festivals";

        break;
      case 2:
        sel = "heritage_places";

        break;
      case 3:
        sel = "famous_personalities";

        break;
      case 4:
        sel = "ornaments";

        break;
      case 5:
        sel = "traditional_dress";

        break;
      default:
        sel = "food";

        break;
    }

    let list;
    if (sel == "food") {
      list = foodData;
    }

    if (sel == "festivals") {
      list = festivalData;
    }

    if (sel == "heritage_places") {
      list = placeData;
    }

    if (sel == "ornaments") {
      list = ornamentData;
    }

    if (sel == "traditional_dress") {
      list = dressData;
    }

    let array = {
      food: [
        {
          state_name: "Assam",
          geohash: "wh8z591h29wf",
          village_name: "NITANANDA PANBARI",
          id: 1,
          food: [
            {
              name: "Ducks on a Poultry farm",
              media_id: "4469407",
              description: "",
              media_name: "4469407.jpg",
            },
          ],
          long: 91.2099,
          lat: 26.5492,
          key: "HVHxoDVEVaPzTqpiRbtz",
        },
        {
          state_name: "West Bengal",
          geohash: "tun0p5kh656c",
          id: 1,
          village_name: "KHAJAI",
          lat: 22.5186,
          key: "K7CgUnISnJo7XozHmh6j",
          food: [
            {
              name: null,
              description: "",
              media_id: "5263325",
              media_name: "5263325.jpg",
            },
          ],
          long: 87.5007,
        },
        {
          state_name: "Jammu and Kashmir",
          geohash: "twhg5z1gx2d7",
          village_name: "WARNOW",
          id: 1,
          key: "1pLO7BPl74tAL6m3BMHq",
          food: [
            {
              name: "Rista",
              description: "",
              media_id: "6612884",
              media_name: "6612884.jpg",
            },
          ],
          long: 74.3472,
          lat: 34.3164,
        },
        {
          state_name: "Madhya Pradesh",
          geohash: "tgc5e65gn6xj",
          village_name: "KHODSEONI",
          id: 1,
          long: 80.3045,
          lat: 21.7205,
          key: "hU9j8lFAapXXbHN6lbHJ",
          food: [
            {
              name: "Bhindi ki sabzi(Ladyfiner's Vegetable)",
              description:
                "\tSabzi(vegetable) Roti(flatbread) is the traditional food in the village. Any type of sabzi can be served with flatbread, including ladyfingers, beans with potatoes, bitter gourds, cauliflower, and carrots. Wheat flour is used to make roti. To make roti, a soft dough must be created. To make the Roti tastier and healthier, Desi ghee is added to it.",
              media_id: "2505449",
              media_name: "2505449.jpg",
            },
          ],
        },
        {
          state_name: "Mizoram",
          geohash: "wh4401q8upck",
          village_name: "Zobawk South",
          id: 1,
          food: [
            {
              name: "Dawlzik bai by Rami",
              media_id: "4734650",
              description:
                "\nBai is one of the traditional dishes of the village. It is made by mixing boiled vegetables with salt and fermented meat. Sometimes the villagers add rice to the dish as well.\n",
              media_name: "4734650.jpeg",
            },
            {
              name: "Mautuai bai by Sangi",
              media_id: "4734703",
              description:
                "\nBai is one of the traditional dishes of the village. It is made by mixing boiled vegetables with salt and fermented meat. Sometimes the villagers add rice to the dish as well.\n",
              media_name: "4734703.jpeg",
            },
          ],
          lat: 22.8586,
          long: 92.8216,
          key: "Brq4YVChtgInwpfWTuwG",
        },
        {
          state_name: "Bihar",
          geohash: "tub0qn1bv5pt",
          id: 1,
          village_name: "SHAIKHPURA",
          lat: 26.7958,
          long: 79.0163,
          food: [
            {
              name: "Dal Bhat",
              media_id: "2567616",
              description:
                "\nDal Bhat also Dal Chawal is the traditional food eaten by locals in Shaikhpura. Masoor Dal or any another lentil is cooked till it becomes mushy and then served along with boiled rice. It is eaten by locals daily.\n",
              media_name: "2567616.jpg",
            },
            {
              name: "Dal Bhat",
              description:
                "\nDal Bhat also Dal Chawal is the traditional food eaten by locals in Shaikhpura. Masoor Dal or any another lentil is cooked till it becomes mushy and then served along with boiled rice. It is eaten by locals daily.\n",
              media_id: "2567633",
              media_name: "2567633.jpg",
            },
          ],
          key: "fOACDQ5Kp5KO5BAnKCkk",
        },
        {
          state_name: "Uttar Pradesh",
          geohash: "ttpcfs6r0nwf",
          id: 1,
          village_name: "THATHI",
          key: "EWevo3rVcQDROMnNYmJl",
          food: [
            {
              name: "Dal Roti",
              description:
                "\nDal(lentil soup) and Roti(flatbread) is a staple food and  people have been eating it since very old time. Roti is made from different types of grains like wheat, maize, millet, rice etc. Also there are many  types of Dal(lentil) like ",
              media_id: "7042507",
              media_name: "7042507.jpeg",
            },
            {
              name: null,
              media_id: "7042508",
              description:
                "\nDal(lentil soup) and Roti(flatbread) is a staple food and  people have been eating it since very old time. Roti is made from different types of grains like wheat, maize, millet, rice etc. Also there are many  types of Dal(lentil) like ",
              media_name: "7042508.jpeg",
            },
          ],
          lat: 28.4572,
          long: 78.5114,
        },
        {
          state_name: "Meghalaya",
          geohash: "wh937t95f7nz",
          village_name: "LUMBATNGEN",
          id: 1,
          key: "lsTSrxlgAQHyxVMKHy8Z",
          lat: 25.5631,
          food: [
            {
              name: "Jadoh ",
              media_id: "6663863",
              description:
                "\nJadoh - It is made up of rice, turmeric, oil, onion, ginger, garlic, salt and black sesame seeds. It is usually made on special occasions such as birthdays, marriage ceremonies gatherings, etc. It is usually served with dohkhleh ( mixed boiled ) meat or dohjem ( entrails). \n\nDohkhleh ( mixed boiled meat ) - It is made by boiling meat and cut into small pieces mixed with onion, ginger, and salt. It is usually served with plain rice or jadoh. \n\nDohjem ( entrails) - The entrails are cut into small pieces and cooked with onion, ginger, garlic, black sesame seeds, turmeric and salt. This item is also usually served with plain rice or jadoh.",
              media_name: "6663863.jpg",
            },
          ],
          long: 91.9131,
        },
        {
          state_name: "Gujarat",
          geohash: "tec7wdp81j2q",
          id: 1,
          village_name: "RINAVADA",
          food: [
            {
              name: "Roti sabzi (flat bread with vegetables) and buttermilk",
              media_id: "1936001",
              description:
                "\nPeople in this village like to eat Roti Sabzi (flatbread with vegetables). Residents of the village eat this dish on every special occasion, like festivals, marriage ceremonies, and cultural gatherings. They also like to drink buttermilk every day.",
              media_name: "1936001.jpg",
            },
            {
              name: "Roti sabji (flatbread with vegetables)",
              media_id: "1936742",
              description:
                "\nPeople in this village like to eat Roti Sabzi (flatbread with vegetables). Residents of the village eat this dish on every special occasion, like festivals, marriage ceremonies, and cultural gatherings. They also like to drink buttermilk every day.",
              media_name: "1936742.jpg",
            },
          ],
          long: 69.5538,
          lat: 21.72,
          key: "fhb886x8Avfe4OR7Au5c",
        },
        {
          state_name: "Andhra Pradesh",
          geohash: "tfc72q1jr8hk",
          id: 1,
          village_name: "MUNNANGI",
          food: [
            {
              name: "Avakay",
              media_id: "5669434",
              description:
                "\nThe traditional dish of the village is Avakay. It is also known as mango pickle. Mango, custard seed powders, mustard seed, chilies, salt, garlic, turmeric powder, oil and small dry onions are the main ingredients.\n",
              media_name: "5669434.jpg",
            },
          ],
          lat: 16.0739,
          key: "L2aed9psyIpcEQqrcm3J",
          long: 80.5205,
        },
        {
          state_name: "Jharkhand",
          geohash: "tu7e58x0fmv0",
          village_name: "RAMBANDH",
          id: 1,
          food: [
            {
              name: "Litti Chokha",
              media_id: "7476570",
              description:
                "\nThe traditional food of the village is Litti Chokha. It is a healthy combination. Litti is the whole wheat dough ball stuffed with Sattu (roasted chickpea flour) and Chokha can be made with roasted eggplant, boiled or roasted potatoes.\n",
              media_name: "7476570.jpg",
            },
          ],
          lat: 24.4365,
          key: "7RdUhM2tKHIXHnk742Ak",
          long: 83.8354,
        },
        {
          state_name: "Punjab",
          geohash: "ttqrszbyw0x2",
          id: 1,
          village_name: "BALSANDA (167)",
          food: [
            {
              name: "Sarson ka Saag and Makke ki Roti\n",
              media_id: "128534",
              description:
                "\nTraditional food of the village is Sarso Ka saag and makki ki roti which is eaten by every villager during the winter season.  As per the villagers, this combination provides the body with all the essential nutrients that it requires during the winters. It is a wholesome meal and generally consumed along with white butter or ghee, lassi and makki di roti. Sarso da saag is made up of sarso, palak and bathua.\n",
              media_name: "128534.jpg",
            },
          ],
          long: 76.4991,
          lat: 30.8933,
          key: "PSGqjSIGwbCJSNfFNEVb",
        },
        {
          state_name: "Chhattisgarh",
          geohash: "tg9ye4x4kbx0",
          id: 1,
          village_name: "KAJRABANDHA",
          long: 81.3526,
          lat: 20.8442,
          food: [
            {
              name: "Bhajiya",
              media_id: "476265",
              description:
                "\r\n\r\n\r\n\r\nBhajiya is a savory deep-fried snack made from a batter of Besan (gram flour) and a variety of vegetables.  ",
              media_name: "476265.jpg",
            },
            {
              name: null,
              description:
                "\r\n\r\n\r\n\r\nBhajiya is a savory deep-fried snack made from a batter of Besan (gram flour) and a variety of vegetables.  ",
              media_id: "5559602",
              media_name: "5559602.jpg",
            },
          ],
          key: "KrHofi1AwTTrDIvdVzSz",
        },
        {
          state_name: "Himachal Pradesh",
          geohash: "ttrjr6znvqnp",
          village_name: "MOHKAMPUR NAWADA (106)",
          id: 1,
          key: "jGBs3SDw0hJ34uviqn9r",
          lat: 30.4704,
          food: [
            {
              name: "malpua dish ",
              media_id: "7221573",
              description:
                "\r\nA malpua is a kind of sweet pancake that is generally made with ghee(clarified butter) or oil. Malpua is made with wheat flour and jaggery. Jaggery is melted and mixed with wheat flour. It has to be made sure that there are no lumps in the batter. , it is then fried in oil or ghee(clarified butter) till it has turned golden brown, and it is served hot. A malpua is a must-have item during a marriage or any festive occasion.\r\n",
              media_name: "7221573.jpeg",
            },
          ],
          long: 77.6722,
        },
        {
          state_name: "Uttarakhand",
          geohash: "tv2jrjne9000",
          id: 1,
          village_name: "NAG JAGAYI",
          food: [
            {
              name: "Aaloo Masala",
              description:
                "\r\nAloo, a South Asian term for potatoes, found in the names of a number of dishes.  Aloo Masala also known as Potato Masala is a simple and delicious",
              media_id: "8355460",
              media_name: "8355460.jpeg",
            },
          ],
          long: 79.0665865,
          key: "koAytYqldEt7Q74c0CCQ",
          lat: 30.4821682,
        },
        {
          state_name: "Tripura",
          geohash: "wh14vsk856cj",
          village_name: "BETAGA RESERVE FOREST",
          id: 1,
          key: "GbnOzxeF2ytR8xmDhUFy",
          lat: 23.00676,
          food: [
            {
              name: "Gudok",
              description:
                "\r\nGudok is the traditional dish of the village. It is made using fermented fish mixed with raw vegetables and spices and stuffed inside a hollow bamboo. It is then baked in a mud oven. It is served with rice and made during festivals or wedding ceremonies. \r\n \r\n\r\n",
              media_id: "4441934",
              media_name: "4441934.jpeg",
            },
            {
              name: null,
              media_id: "4441935",
              description:
                "\r\nGudok is the traditional dish of the village. It is made using fermented fish mixed with raw vegetables and spices and stuffed inside a hollow bamboo. It is then baked in a mud oven. It is served with rice and made during festivals or wedding ceremonies. \r\n \r\n\r\n",
              media_name: "4441935.jpeg",
            },
          ],
          long: 91.65427,
        },
        {
          state_name: "Haryana",
          geohash: "ttq6dme5msgq",
          id: 1,
          village_name: "RAWAHAR JAGIR (4)",
          key: "fueNOrixB46tUSe4QroM",
          lat: 30.0015,
          food: [
            {
              name: "Gulgule ",
              description:
                "\r\nGulgule is a traditional dish of the village. It is a deep-fried dessert that is made from whole wheat flour, sugar or jaggery and fennel seeds. Pua is another name for this dish.\r\n\r\n",
              media_id: "7208757",
              media_name: "7208757.jpg",
            },
          ],
          long: 76.3923,
        },
        {
          state_name: "Tamil Nadu",
          geohash: "tf0dnbgt2gh1",
          id: 1,
          village_name: "TIRUCHCHEPURAM",
          long: 79.7546,
          food: [
            {
              name: "Idli",
              media_id: "8186516",
              description:
                "\r\nIdli (savory rice cake) is a common breakfast item in Kotluru. The cakes are made by steaming a batter made from fermented, dehusked black lentils and rice. Idli is known for its soft and fluffy texture.\r\n",
              media_name: "8186516.jpg",
            },
          ],
          lat: 11.6066,
          key: "MhLYgbxJWQEwnzXIVoCH",
        },
        {
          state_name: "Karnataka",
          geohash: "tdwh4rhky9ms",
          village_name: "KATENAHALLI",
          id: 1,
          key: "XUl21v0KHPCsc1osQsYz",
          food: [
            {
              name: "Idli",
              media_id: "7753950",
              description:
                "\r\nIdli is the breakfast of the locals in this village. It is steamed and made with rice and urad dal. Idli is usually served with sambar which is a South-Indian lentil and vegetable stew.\r\n",
              media_name: "7753950.jpeg",
            },
          ],
          lat: 14.8049,
          long: 76.0425,
        },
        {
          state_name: "Odisha",
          geohash: "tguk50gu6c4c",
          village_name: "SANSIBNATHPUR",
          id: 1,
          lat: 21.80173,
          long: 84.86367,
          food: [
            {
              name: "Idli ",
              media_id: "8036522",
              description:
                "\r\nIdli is the traditional food of the village. It is a type of savory rice cake that is popular as a breakfast food. It is consumed with Sambar (a lentil-based vegetable stew) and is made from batter consisting of fermented black lentils (de-husked) and rice.\r\n",
              media_name: "8036522.jpg",
            },
          ],
          key: "775Lkzgn4so070V40QTl",
        },
        {
          state_name: "LADAKH",
          geohash: "twnk1263z25x",
          id: 1,
          village_name: "KUKSTE",
          lat: 34.4548,
          food: [
            {
              name: "Paba",
              media_id: "5114096",
              description:
                "\r\nPaba is the traditional food of the village people. It is made of roasted barely flour. Roasted barely flour is poured into the hot boiling water and stirred until it forms a brown dough. Then this dough is scrolled to make a round ball to serve.",
              media_name: "5114096.jpg",
            },
          ],
          key: "l3aWTTx8oUxeUyvaoDkm",
          long: 76.3474,
        },
        {
          state_name: "Maharashtra",
          geohash: "temhhgrygfs2",
          id: 1,
          village_name: "AWALI",
          lat: 19.0034,
          long: 74.7508,
          key: "9PZ1CZ1t9HoAgun4SwB8",
          food: [
            {
              name: "Rice",
              description:
                "\r\nRice is the staple food of the people of this village. It is eaten as a main course meal with a curry. Both vegetarian and non-vegetarian curries are eaten with rice.",
              media_id: "7894592",
              media_name: "7894592.jpeg",
            },
          ],
        },
        {
          state_name: "Rajasthan",
          geohash: "tsu8pzynq0cn",
          village_name: "SIRASANA",
          id: 1,
          long: 74.1772,
          lat: 26.7624,
          food: [
            {
              name: "Sabzi-Roti",
              description:
                "\r\nSabzi-Roti is the most common food in the village. Sabzi (cooked vegetable) can be any like soybean, ladyfinger and Potato with capsicum. It is served with Roti (flat bread). Roti is made up of wheat Atta (flour).\r\n",
              media_id: "7497167",
              media_name: "7497167.jpeg",
            },
          ],
          key: "2HVStmFitAFuiiIImcDl",
        },
        {
          state_name: "Nagaland",
          geohash: "whek88pnbhc3",
          id: 1,
          village_name: "VEDAMI",
          key: "2eTejjFFYyd64LuRp3qX",
          lat: 26.1047,
          long: 94.6019,
          food: [
            {
              name: "Axone curry",
              description:
                " Axone is a fermented soybean product commonly used in Vedami village. Axone is perhaps the most commonly used fermented product of Nagaland and the North Eastern Region of India.\r\n",
              media_id: "5318382",
              media_name: "5318382.jpg",
            },
          ],
        },
        {
          state_name: "Delhi",
          geohash: "ttnencpem8yq",
          village_name: "JHARODA KALAN (CT)",
          id: 1,
          food: [
            {
              name: " Bajra Ki Khichdi \r\n",
              description:
                " Bajre ki Khichdi is the main dish of the village people. It is made up of  Bajra (millet), Moong Dal (lentils), and rice. It is served with Ghee (clarified butter), buttermilk, and pickles. It is mostly made in the winter for breakfast and lunch. Bajra keeps the body warm and gives energy in cold weather, so people prefer to cook it during the winter season.",
              media_id: "188442",
              media_name: "188442.jpeg",
            },
          ],
          key: "w3SxNx1kIbop4BSTCjYX",
          lat: 28.6584,
          long: 76.9478,
        },
        {
          state_name: "Telangana",
          geohash: "tg05xzwn1yys",
          village_name: "CHANDEPALLY",
          id: 1,
          food: [
            {
              name: "Brinjal Curry ",
              media_id: "7206535",
              description:
                " Brinjal Curry known as Gutti Vankaya Curry has a mouthwatering flavor. This recipe, which uses brinjals, tamarind extract, and a variety of spices, is the staple diet of the village. ",
              media_name: "7206535.jpg",
            },
          ],
          lat: 17.5325,
          key: "r6X5Vqo3YElQj6kQ7FAR",
          long: 79.0989,
        },
        {
          state_name: "Arunachal Pradesh",
          geohash: "whcd0710eu5k",
          id: 1,
          village_name: "SAMPHUNG",
          food: [
            {
              name: "Momo",
              description:
                " It is the traditional dish of the village. It is a steamed dumpling. The fillings for the dumplings are either made of vegetables or meat. Momo is usually served as a snack.",
              media_id: "7140987",
              media_name: "7140987.jpg",
            },
          ],
          long: 92.1219,
          lat: 27.0869,
          key: "PIJl8T5OvAfDlUk60Jzl",
        },
        {
          state_name: "Manipur",
          geohash: "wh7n9ed6tehe",
          id: 1,
          village_name: "T.CHANHONG",
          key: "Iz6CEKZfw4IJ0k7s8KVR",
          food: [
            {
              name: "Local Bread (Hao Khamui)",
              description:
                "(Hao Khamui) is local bread made of sticky rice, firstly, we soak it in water and dried up, after that, we make it into powder with a pestle into the mortar. The powdered flour mix with water and fried with oil in the pan. When we fried, it become a local bread (Hao Khamui) we specially make during a festival like Luira, Mangkhap, Thareo, etc.",
              media_id: "5982027",
              media_name: "5982027.jpg",
            },
          ],
          long: 94.288,
          lat: 25.0685,
        },
        {
          state_name: "Puducherry",
          geohash: "tffrnyn5n1r5",
          village_name: "YANAM (M)",
          id: 1,
          food: [
            {
              name: "Appam",
              description:
                "Appam is a type of pancake made with fermented rice batter and coconut milk. It is a common food in the village and eaten most frequently for breakfast or dinner.",
              media_id: "5849332",
              media_name: "5849332.jpg",
            },
          ],
          lat: 16.7327,
          key: "cssMpqCEpXUbjRmHieUG",
          long: 82.2192,
        },
        {
          state_name: "Kerala",
          geohash: "t9wt14j9hnet",
          village_name: "ARANMULA",
          id: 1,
          key: "txizNxeDK9hQbgl1UTBB",
          food: [
            {
              name: "Aranmula Valla Sadhya",
              description:
                "Aranmula Valla Sadhya is a famous dish in Aranmula. Multiple dishes are made and served in smaller quantities on a banana leaf. It is also offered to the gods and goddesses worshipped in people's homes.",
              media_id: "70370",
              media_name: "70370.jpg",
            },
            {
              description:
                "Aranmula Valla Sadhya is a famous dish in Aranmula. Multiple dishes are made and served in smaller quantities on a banana leaf. It is also offered to the gods and goddesses worshipped in people's homes.",
              media_name: "70371.jpg",
            },
          ],
          lat: 9.3276,
          long: 76.6923,
        },
        {
          state_name: "Goa",
          geohash: "tdswe3hqt43j",
          id: 1,
          village_name: "CHINCHINIM (CT)",
          food: [
            {
              name: "Bebinca ",
              description:
                "Bebinca is a food that is customarily prepared for all events or festivities in the village using coconut flour and sugar. This meal is considered to be a must-have at gatherings.",
              media_id: "2819811",
              media_name: "2819811.jpg",
            },
            {
              name: "Bebinca ",
              description:
                "Bebinca is a food that is customarily prepared for all events or festivities in the village using coconut flour and sugar. This meal is considered to be a must-have at gatherings.",
              media_id: "2819823",
              media_name: "2819823.jpg",
            },
          ],
          lat: 15.2117,
          key: "Fao85UJLmuVWri3BQlKu",
          long: 73.977,
        },
        {
          state_name: "Andaman and Nicobar Islands",
          geohash: "w41yps0r5p07",
          village_name: "UTTARA (RV)",
          id: 1,
          long: 92.791,
          key: "HsS8DjQl6X43QC20Ezku",
          lat: 12.3279,
          food: [
            {
              name: "Boiled Rice",
              media_id: "6479314",
              description:
                "Boiled rice is a traditional food of the locals of this village. Rice is kept in water overnight;  and cooked the next day with green chilies, onions, or fish fry. ",
              media_name: "6479314.jpg",
            },
          ],
        },
        {
          state_name: "Sikkim",
          geohash: "tuyeweb39mk5",
          village_name: "TINGBRUM",
          id: 1,
          long: 88.176672,
          key: "qFoL28LrTW4ywEuOuH9h",
          lat: 27.35487,
          food: [
            {
              name: "Chatamari",
              description:
                "Chatamari is the traditional food in this village. It is a round bread made of rice flour with toppings of onions, coriander leaves and of different vegetables. It is generally made during festivals and other special occasions.",
              media_id: "7204299",
              media_name: "7204299.jpeg",
            },
          ],
        },
        {
          state_name: "The Dadra and Nagar Haveli and Daman and Diu",
          geohash: "teefvcbps05y",
          id: 1,
          village_name: "DAPADA",
          key: "KKSd8FrCeLo9FhI8KlzD",
          food: [
            {
              name: "Chicken Curry",
              description:
                "Chicken curry is one of the famous food in the village. This dish is cooked in gravy which is made using homemade masalas(spices). It is made occasionally. People prefer to eat this dish with boiled rice or roti (flat bread).\r\n",
              media_id: "6658022",
              media_name: "6658022.jpeg",
            },
          ],
          long: 73.0263,
          lat: 20.1818,
        },
      ],
      festivals: [
        {
          festivals: [
            {
              name: null,
              description: "",
              media_id: "431528",
              media_name: "431528.jpg",
            },
            {
              name: null,
              media_id: "431545",
              description: "",
              media_name: "431545.jpg",
            },
            {
              name: null,
              media_id: "431557",
              description: "",
              media_name: "431557.jpg",
            },
          ],
          state_name: "Tamil Nadu",
          geohash: "tcb7dn2j9p6c",
          id: 1,
          village_name: "VEPPANKKADU UKKADAI",
          lat: 10.4943,
          key: "94smECvJedHBJBUcA37u",
          long: 79.1895,
        },
        {
          festivals: [
            {
              name: "Muharram celebration",
              description: "",
              media_id: "4917471",
              media_name: "4917471.jpg",
            },
          ],
          state_name: "Assam",
          geohash: "wh8ts56ey14r",
          id: 1,
          village_name: "NO.1 RASUL PUR",
          lat: 26.2978,
          key: "t9gyWODzMi2PNYZD5pRx",
          long: 90.8826,
        },
        {
          festivals: [
            {
              name: "Mangona",
              media_id: "6524919",
              description: "",
              media_name: "6524919.jpg",
            },
          ],
          state_name: "Meghalaya",
          geohash: "wh2pw17reyff",
          village_name: "PIKAPARA",
          id: 1,
          long: 90.2683,
          key: "1WsVYQTm1m15KU53WJir",
          lat: 25.2328,
        },
        {
          festivals: [
            {
              name: "Residents celebrating Eid ul-Adha",
              description: "",
              media_id: "7605121",
              media_name: "7605121.jpeg",
            },
          ],
          state_name: "Jharkhand",
          geohash: "tuqmz60p8y36",
          id: 1,
          village_name: "MADIA AMANAT SARKAR",
          lat: 24.9293,
          key: "KpDVsVVS7SyaHg3Ai3Ft",
          long: 87.8577,
        },
        {
          festivals: [
            {
              name: "Sazo celebration",
              media_id: "8518697",
              description: "",
              media_name: "8518697.jpg",
            },
          ],
          state_name: "Himachal Pradesh",
          geohash: "ttxdf3prwgck",
          village_name: "BATURI (13/3)",
          id: 1,
          lat: 31.4277,
          key: "sQvpi4BlU1ighixCpiEs",
          long: 78.156,
        },
        {
          festivals: [
            {
              description: "",
              media_name: "7037061.jpeg",
            },
          ],
          state_name: "Uttar Pradesh",
          geohash: "tug562894pbq",
          id: 1,
          village_name: "SEMARNAR",
          long: 83.0684,
          lat: 27.293,
          key: "cRlRn87D9QGByd7SIrL9",
        },
        {
          festivals: [
            {
              description: "",
              media_name: "7119251.jpg",
            },
          ],
          state_name: "Rajasthan",
          geohash: "tsvfubb6w2rz",
          village_name: "SAMODE",
          id: 1,
          long: 75.7953,
          lat: 27.2067,
          key: "A0T7PdMQ4brWU6s3yZFl",
        },
        {
          festivals: [
            {
              description: "",
              media_name: "7709205.jpg",
            },
          ],
          state_name: "Odisha",
          geohash: "tgse5mypjfpg",
          village_name: "KUNJABANAPRASAD",
          id: 1,
          key: "YTTTJ3Kcs7IscA4lkLDj",
          lat: 20.247642445446544,
          long: 85.22944449034586,
        },
        {
          festivals: [
            {
              name: "Bhogi\r\n",
              description:
                "\nBhogi is a festival celebrated in the village in honor of Lord Indra, who is known as the god of rains and clouds and who blesses the land with good harvest and prosperity. On this day, people focus on fresh things that bring about change or transformation rather than old, abandoned items. \n",
              media_id: "7392086",
              media_name: "7392086.png",
            },
          ],
          state_name: "Andhra Pradesh",
          geohash: "tfby4zbfrbb1",
          village_name: "SRIRUKMINIPURAM",
          id: 1,
          lat: 16.5664,
          key: "8sKoN4VfKtc1kPIkL1Gx",
          long: 79.9269,
        },
        {
          festivals: [
            {
              name: "Diwali celebration",
              media_id: "6854199",
              description:
                "\nDiwali is the main festival celebrated in the village. People clean their houses many days before Diwali. They decorate their home with lights, Diyas (earthen lamps), and candles for this occasion. They worship the goddess Lakshmi and Lord Ganesha.\n",
              media_name: "6854199.jpg",
            },
          ],
          state_name: "Madhya Pradesh",
          geohash: "tspz4t64ge9z",
          village_name: "SINGARMUNDI",
          id: 1,
          long: 78.5112,
          lat: 23.7598,
          key: "4ZerNPBM4dEyRLrae0Vp",
        },
        {
          festivals: [
            {
              name: "Pork dish",
              media_id: "6410115",
              description:
                "\nTsokum Sumai is a festival celebrated in the first week of October. During this festival, villagers worship for a good harvest and good health.\n\n\n",
              media_name: "6410115.jpg",
            },
          ],
          state_name: "Nagaland",
          geohash: "whet45n9ufe4",
          village_name: "NOKLAK VILL.",
          id: 1,
          key: "QNVPToImlcteq3qlyyTR",
          lat: 26.2082,
          long: 95.0189,
        },
        {
          festivals: [
            {
              name: "Jodh Mela",
              description:
                "\r\n\r\nJodh Mela is organized in the village on the 15th of September every year. People from various villages across the district participate in this fair. Langar is distributed and Kabaddi matches are held in the evening. ",
              media_id: "6793717",
              media_name: "6793717.jpg",
            },
          ],
          state_name: "Punjab",
          geohash: "ttt4ycvfm0qj",
          id: 1,
          village_name: "GILL WARAICH (217)",
          lat: 31.4309,
          key: "jSNsWJiWv0ngEVnk9AXu",
          long: 74.836,
        },
        {
          festivals: [
            {
              name: "People celebrating Dussehra",
              description:
                "\r\n\r\nOne of the most popular festivals in the village is called Dussehra. In the village, several cultural events are also held. The life of Lord Ram is also portrayed through role-playing. The role-play of Ram's life is also seen by visitors from neighboring communities. ",
              media_id: "5765487",
              media_name: "5765487.jpg",
            },
          ],
          state_name: "Haryana",
          geohash: "ttrh031sf7j6",
          id: 1,
          village_name: "MUKARAPUR (181)",
          lat: 30.2407,
          long: 77.3569,
          key: "Ej7vIjgUZ3swJpva6hEr",
        },
        {
          festivals: [
            {
              name: "Fagvel Mela",
              media_id: "3940509",
              description:
                "\r\nAn annual fair dedicated to Bhatiji Maharaj is organized in the village. Numerous swings, food stalls, cosmetics, ornaments shops, and games stalls are set up by local businessmen. A Bhandara (community feast) is organized on the last day of the fair.",
              media_name: "3940509.jpg",
            },
          ],
          state_name: "Gujarat",
          geohash: "tsh46qvtykck",
          id: 1,
          village_name: "FAGVEL",
          long: 73.2317,
          lat: 22.9336,
          key: "JbRcYBgOospRlLhUdYIU",
        },
        {
          festivals: [
            {
              name: "Bathukamma",
              media_id: "6302082",
              description:
                "\r\nBathukamma is known as the 'Flower Festival' of the people of this village. On this day, women dress up in their traditional attire. They also adorn their hair with flowers. Flowers are also offered to the village deity.",
              media_name: "6302082.jpg",
            },
          ],
          state_name: "Telangana",
          geohash: "tg0q69v7y1vn",
          village_name: "Kannaram",
          id: 1,
          long: 79.2189,
          lat: 17.9839,
          key: "VSHRYzXUnD9O9axBBpte",
        },
        {
          festivals: [
            {
              name: "Bhojli Tihar",
              media_id: "8135972",
              description:
                "\r\nBhojli Tihar is celebrated in this village in August. It includes all the natives of the village and involves singing Bhojli folk songs, sowing Bhojli plants, and worshiping the goddess for good health. The festival ends with the immersion of Bhojli in the river.",
              media_name: "8135972.jpeg",
            },
          ],
          state_name: "Chhattisgarh",
          geohash: "tg9x8vc69huc",
          village_name: "PENDRI KHURD",
          id: 1,
          lat: 21.0379,
          key: "YQ2839RsiF23JvytX2uT",
          long: 80.8941,
        },
        {
          festivals: [
            {
              name: "Chhat puja",
              media_id: "8097540",
              description:
                "\r\nChhat puja is celebrated by the locals of this village. It comes after six days of Diwali (the festival of lights). The gods' sun is worshipped.",
              media_name: "8097540.jpeg",
            },
          ],
          state_name: "Bihar",
          geohash: "tusgc98fgcdd",
          id: 1,
          village_name: "MATHURAPUR",
          long: 85.4968,
          lat: 25.9804,
          key: "vkcdxbGwwlMsTw5OnqTX",
        },
        {
          festivals: [
            {
              name: "Goddess Durga Temple ",
              description:
                "\r\nDurga Puja is a major festival for residents of the village. It is celebrated at the end of Navaratri (a festival celebrated to honor the various forms of Goddess Durga) every year to honor Goddess Durga. During Durga Puja, the residents decorate the temple in the village and sing Bhajans and Kirtans (devotional singing).\r\n",
              media_id: "8516843",
              media_name: "8516843.jpg",
            },
          ],
          state_name: "West Bengal",
          geohash: "tun9y71b48se",
          id: 1,
          village_name: "NAWAPARA",
          long: 88.1678,
          key: "Vk3V9woMWHOeIgZ91Niv",
          lat: 22.8241,
        },
        {
          festivals: [
            {
              name: "Ganesh Chathurthi",
              media_id: "8424548",
              description:
                "\r\nGanesh Chaturthi is an important festival celebrated in the village. An idol of Lord Ganesha is brought home and worshiped. The villagers worship this idol for a period of either 11 or 21 days. Modak is also prepared as a prasad during this festival.\r\n",
              media_name: "8424548.jpg",
            },
          ],
          state_name: "Karnataka",
          geohash: "tdtft4h1e2d6",
          village_name: "RAJANAHALLI",
          id: 1,
          long: 75.8113,
          key: "7s40fD6a63GSzTWVChAu",
          lat: 14.5132,
        },
        {
          festivals: [
            {
              name: "Harela",
              media_id: "8601618",
              description:
                "\r\nHarela is an earth festival which is celebrated in the Shravan month. Lord Shiva and Goddess Parvati are worshipped on this day in their clay figures. The festival celebrates greenery, prosperity, peace and environmental conservation. Nine days before the festival seeds of maize, urad, mustard and oats are sown in baskets. They are harvested on the ninth day and are distributed to near and dear for their prosperity.",
              media_name: "8601618.jpg",
            },
          ],
          state_name: "Uttarakhand",
          geohash: "tv2785ybwjxt",
          id: 1,
          village_name: "KHAND MALLA",
          lat: 30.1672,
          key: "ih0EIRQ8dmCXvguWHrwe",
          long: 79.1111,
        },
        {
          festivals: [
            {
              name: "Eid celebration",
              description:
                " After the holy month of Ramadan, the residents of the village celebrate Eid with immense joy and happiness. Eid-ul-Fitr is an auspicious festival for the residents of the village and people greet each other by hugging and exchanging gifts. The elder members of the family give Eidi (Eid money) to the children. People also offer special prayers on Eid for prosperity in their homes.",
              media_id: "8091223",
              media_name: "8091223.jpg",
            },
          ],
          state_name: "Jammu and Kashmir",
          geohash: "twhfftp0jw8j",
          id: 1,
          village_name: "KHAMANDER PORA",
          key: "dNXJgfcTJ1vXHgRySF9T",
          long: 74.2994,
          lat: 34.2609,
        },
        {
          festivals: [
            {
              name: "Janmashtami celebration",
              description:
                " Community peoples Install clay statues of Lord Krishna (a major deity). Krishna Janmashtami (celebrates the birth of Lord Krishna, God of love), also known simply as Janmashtami or Gokulashtami (celebrates the birth of Lord Krishna, the God of love and compassion), is a festival that celebrates the birth of Krishna (a major deity). ",
              media_id: "4781465",
              media_name: "4781465.jpeg",
            },
          ],
          state_name: "Maharashtra",
          geohash: "tg8vbxvjg85u",
          id: 1,
          village_name: "DAMBEVIRALI",
          key: "ZOuOX47JvGqRq4WALeVH",
          lat: 20.741802,
          long: 79.833682,
        },
        {
          festivals: [
            {
              name: "Kang",
              media_id: "5838582",
              description:
                " Rath Yatra is locally known as Kang in the village and it is observed for eight consecutive days. The last day of Kang is known as Kanglen. During this festival Lord Jagannath is worshipped and idols of the deities are pulled in cart-shaped car by devotees of both young and old. The cart is pulled from the temple and travels within the village and different household offers fruits , vegetables, flowers and candle with money as offering to please Lord Jagannath.",
              media_name: "5838582.jpeg",
            },
            {
              name: "Kang",
              description:
                " Rath Yatra is locally known as Kang in the village and it is observed for eight consecutive days. The last day of Kang is known as Kanglen. During this festival Lord Jagannath is worshipped and idols of the deities are pulled in cart-shaped car by devotees of both young and old. The cart is pulled from the temple and travels within the village and different household offers fruits , vegetables, flowers and candle with money as offering to please Lord Jagannath.",
              media_id: "5838644",
              media_name: "5838644.jpeg",
            },
          ],
          state_name: "Manipur",
          geohash: "wh6v40qd4jp1",
          id: 1,
          village_name: "KONGBA NONGTHOMBAM (THAMBALKHONG LAISHRAM LEIKAI)",
          long: 93.9641,
          key: "WRvhei80A6DqhpbF9YIN",
          lat: 24.7869,
        },
        {
          festivals: [
            {
              name: "Ker Puja rituals performed by the priest",
              description:
                " The God of the village, called Ker, is worshipped by the residents of the village. The festival is observed for two weeks and commences at the end of the Kharchi Puja. Whereas Kharchi Puja is devoted to the 14 deities that comprise the dynastic deity. The Ker Puja is dedicated to the main guardian deity of the Vastu Devatha. It is an age-old ritual performed since the time of the king's rule in the state. ",
              media_id: "585961",
              media_name: "585961.jpg",
            },
          ],
          state_name: "Tripura",
          geohash: "wh32cdj0sxz3",
          village_name: "MAHARANI",
          id: 1,
          long: 91.8308,
          lat: 24.0492,
          key: "gGbdrUNSwjPC4eKsCHep",
        },
        {
          festivals: [
            {
              name: "Chapchar kut celebration ",
              media_id: "5625116",
              description:
                "'Chavang-Kut’ is a post-harvest festival to offer thanksgiving to the Almighty for the bountiful harvest of the year. It is celebrated on the Ist of November every year. The vibrant folkdances performed by the tribals, reflect their joy and happiness. One of the main attractions of the festival is the Miss Kut beauty pageant contest. Participants from different communities, dressed in their best ethnic attires, walk on the ramp. Such events not only highlight the vibrant colors of the region, but also play an important role in strengthening the bonds between different tribes.\r\n",
              media_name: "5625116.jpg",
            },
            {
              name: "Chapchar kut ",
              description:
                "'Chavang-Kut’ is a post-harvest festival to offer thanksgiving to the Almighty for the bountiful harvest of the year. It is celebrated on the Ist of November every year. The vibrant folkdances performed by the tribals, reflect their joy and happiness. One of the main attractions of the festival is the Miss Kut beauty pageant contest. Participants from different communities, dressed in their best ethnic attires, walk on the ramp. Such events not only highlight the vibrant colors of the region, but also play an important role in strengthening the bonds between different tribes.\r\n",
              media_id: "5625128",
              media_name: "5625128.jpg",
            },
          ],
          state_name: "Mizoram",
          geohash: "wh38m0pp1bxd",
          village_name: "TUMPANGLUI",
          id: 1,
          lat: 23.9514,
          key: "OPVp9MEyf8UCdYB11BHu",
          long: 92.3388,
        },
        {
          festivals: [
            {
              name: "Mahatma Jayanti",
              media_id: "6806426",
              description:
                "A fair is held in the village every year on January 12 to commemorate Bade Mahatma's birth anniversary. Around this time, a large number of villagers from nearby villages and tourists attend the fair. There are numerous stores, a carousel ride, and food vendors at the fair.",
              media_name: "6806426.png",
            },
          ],
          state_name: "Delhi",
          geohash: "ttn3q0jtp6c4",
          id: 1,
          village_name: "KHERA DABAR",
          long: 76.5606,
          key: "Jd3Whd5F4XkvvKNwY9uT",
          lat: 28.3456,
        },
        {
          festivals: [
            {
              name: "Aalpaloni festival",
              media_id: "6045794",
              description:
                "Aalpaloni is a Chakma traditional festival performed by Brajapur  village resident . Aalpaloni is celebrated on the month of June. On that particular day the farmers doesn't go to their field to plough. On that day the Chakma people celebrate the day with great joys.",
              media_name: "6045794.jpg",
            },
          ],
          state_name: "Arunachal Pradesh",
          geohash: "whusbhf5rxhy",
          id: 1,
          village_name: "BRAJAPUR",
          key: "84ATzF4soL6TeepdBOk1",
          long: 96.3312,
          lat: 27.5804,
        },
        {
          festivals: [
            {
              name: "Vat Poornima",
              media_id: "1134646",
              description:
                "All the women celebrate Vat Poornima for the well-being of their partners. This festival is celebrated by women with full-day fasting. Specially prepared food is served during this festival.",
              media_name: "1134646.jpg",
            },
          ],
          state_name: "Goa",
          geohash: "tdu8kyxnmhvk",
          id: 1,
          village_name: "ONDA (CT)",
          long: 74.0467,
          key: "qZBkSUXhfBhqbx7lSTzw",
          lat: 15.5495,
        },
        {
          festivals: [
            {
              name: "Archery Festival",
              description:
                "Archery is the main festival organized in this village. Archery is the traditional game of Ladakh and is considered to be a part of the culture or tradition. This is organized during the spring season on the open grounds. Various teams from this village and the nearby villages take part in this sports festival.",
              media_id: "6953009",
              media_name: "6953009.jpg",
            },
          ],
          state_name: "LADAKH",
          geohash: "twn5s3jyzvpg",
          id: 1,
          village_name: "STAKTSE",
          key: "whPsKJPKcLTNEcG1wBNO",
          lat: 34.3719137,
          long: 76.132507,
        },
        {
          festivals: [
            {
              name: "Paddy cultivation",
              description:
                "Asar 15 is the traditional festival of the village residents, in which the people celebrate to mark the beginning of paddy plantation shortly after the arrival of the monsoon.",
              media_id: "6769675",
              media_name: "6769675.jpg",
            },
          ],
          state_name: "Sikkim",
          geohash: "tuyepqcm7sgk",
          village_name: "LINGCHOM",
          id: 1,
          key: "X8wOdHXlbcdGxY9IzwhP",
          long: 88.2111,
          lat: 27.2841,
        },
        {
          festivals: [
            {
              name: "Chader Shreef mela",
              media_id: "62051",
              description:
                "Chader Shreef mela is organized in the village in the masjid, where Kawallis are invited to sing the kawalli. Many people come to participate in the fair and to listen to the Kawallis. Langar is also served to all the Devotees.",
              media_name: "62051.jpg",
            },
          ],
          state_name: "Chandigarh",
          geohash: "ttqweuz8kny9",
          id: 1,
          village_name: "DARIA (CT)",
          long: 76.8159,
          key: "19gV3W4Q0uxakCgS6bsS",
          lat: 30.7,
        },
        {
          festivals: [
            {
              name: "People Celebrating Christmas ",
              media_id: "6689689",
              description:
                "Christmas is celebrated as a festival in this village. This is celebrated on 25th of December every year. People decorate their houses on this festival and exchange gifts with neighbours.",
              media_name: "6689689.jpg",
            },
          ],
          state_name: "Andaman and Nicobar Islands",
          geohash: "w41fst8c3gdw",
          village_name: "BINDRABAN (RV)",
          id: 1,
          long: 92.6598,
          key: "HCb1ZDgwcbaErkacok08",
          lat: 11.7199,
        },
        {
          festivals: [
            {
              name: "Draupadi Amman Festival",
              description:
                "Draupadi Amman Festival is celebrated in the village in honor of Goddess Draupadi (from the epic, Mahabharata). It includes rituals such as walking barefoot on a hot ash bed and is celebrated twice a year in the month of February and May.",
              media_id: "7154518",
              media_name: "7154518.png",
            },
          ],
          state_name: "Puducherry",
          geohash: "tf0ex26kh1p3",
          village_name: "ABISHEGAPAKKAM",
          id: 1,
          key: "5sMywFoEnn4uQBTDQoMm",
          lat: 11.8673,
          long: 79.775,
        },
        {
          festivals: [
            {
              name: "Mosque",
              media_id: "7576314",
              description:
                "Eid is the festival celebrated by the residents of the village with great enthusiasm. People attend the special prayers held at mosques and arrange feasts for family and friends.",
              media_name: "7576314.jpg",
            },
          ],
          state_name: "Kerala",
          geohash: "tdjtguk3nz6z",
          id: 1,
          village_name: "PULINGOME",
          lat: 12.2843,
          long: 75.4053,
          key: "AGp552bAtB3MSAvRe1tN",
        },
        {
          festivals: [
            {
              name: "People celebrating Ganesh Chaturthi",
              description:
                "Ganesh Chaturthi is the festival in the village. It comes in between the months of August and September every year. On these days, people celebrate Ganesh ji birthday. Ganesh ji is the son of Lord Shiva and Mata Parvati. Another name for Ganesh Chaturthi is Vinayaka Chaturthi. He is the god of prosperity and wisdom.",
              media_id: "6501777",
              media_name: "6501777.jfif",
            },
          ],
          state_name: "The Dadra and Nagar Haveli and Daman and Diu",
          geohash: "teegcvhkbx21",
          village_name: "NAILA PARDI",
          id: 1,
          long: 72.8562,
          key: "zWtf5iViwzNYpdniVBb2",
          lat: 20.375,
        },
      ],
      heritage_places: [
        {
          state_name: "Maharashtra",
          heritage_places: [
            {
              name: "Swayambhu Ganesha Temple",
              media_id: "6176417",
              description: "",
              media_name: "6176417.png",
            },
          ],
          geohash: "teh1e3ge9dtj",
          id: 1,
          village_name: "GANPATIPULE",
          lat: 17.1489,
          key: "pMou8LnCoXLe9x7P2M0k",
          long: 73.2727,
        },
        {
          state_name: "Meghalaya",
          heritage_places: [
            {
              name: "Small Wooden Bridge",
              media_id: "6372226",
              description: "",
              media_name: "6372226.jpg",
            },
            {
              name: null,
              description: "",
              media_id: "6372227",
              media_name: "6372227.jpg",
            },
          ],
          geohash: "wh80npqsjdn3",
          village_name: "RONGBOKGRI",
          id: 1,
          long: 90.272843,
          key: "qj8T0sATQzJLrbr9g4uI",
          lat: 25.353023,
        },
        {
          state_name: "Gujarat",
          heritage_places: [
            {
              name: "Dasha Maa Temple",
              media_id: "7541575",
              description: "",
              media_name: "7541575.jpeg",
            },
          ],
          geohash: "tesp4892mpu1",
          village_name: "MACHHISADADA",
          id: 1,
          lat: 20.9208,
          long: 73.2368,
          key: "MZyIgTlpjve9oXKDSMXI",
        },
        {
          state_name: "Karnataka",
          heritage_places: [
            {
              name: "Babbuswami Kshetra Kere",
              media_id: "924722",
              description: "",
              media_name: "924722.jpg",
            },
            {
              name: "Babbuswami Kshetra Kere",
              media_id: "924750",
              description: "",
              media_name: "924750.jpg",
            },
          ],
          geohash: "tdmhhp1xzpym",
          id: 1,
          village_name: "PADUTHONSE",
          lat: 13.3992,
          long: 74.7094,
          key: "hOjcfINQQhP9bFr6hLDK",
        },
        {
          state_name: "Bihar",
          geohash: "tutstk1d3hwz",
          heritage_places: [
            {
              description: "",
              media_name: "324568.jpg",
            },
          ],
          village_name: "TULAPATTI",
          id: 1,
          key: "L5vDUhbgEPkm16kr4ctX",
          lat: 26.1259,
          long: 86.7172,
        },
        {
          state_name: "Jammu and Kashmir",
          geohash: "ttuyjzh00ccj",
          heritage_places: [
            {
              description: "",
              media_name: "7636141.jpg",
            },
          ],
          id: 1,
          village_name: "SOKER",
          long: 74.4379,
          key: "cGSJXJccr0c7qKTG72rv",
          lat: 33.4369,
        },
        {
          state_name: "West Bengal",
          heritage_places: [
            {
              name: "Forest in the village",
              description: "",
              media_name: "8041702.jpg",
            },
          ],
          geohash: "tuxte10nkquv",
          id: 1,
          village_name: "CHARAKER KUTHI DEWANBAS",
          lat: 26.2859,
          long: 89.4289,
          key: "xKEmMtOjjXcDfLXH0c2Q",
        },
        {
          state_name: "Assam",
          heritage_places: [
            {
              description: "",
              media_name: "965696.jpg",
            },
          ],
          geohash: "whg02xew7ypm",
          id: 1,
          village_name: "DAHIKHOR GAON",
          lat: 26.805,
          key: "b8lFu6S2SSzVZv2n2M57",
          long: 94.2457,
        },
        {
          state_name: "Tripura",
          geohash: "wh0zu3muvf92",
          heritage_places: [
            {
              description: "",
              media_name: "993816.jpg",
            },
          ],
          village_name: "Lankamura",
          id: 1,
          key: "4l0ETSoO9Nn7cybWQUDO",
          long: 91.2496,
          lat: 23.87,
        },
        {
          state_name: "Uttar Pradesh",
          geohash: "tucc3s322wky",
          heritage_places: [
            {
              name: "Peer Baba Mazar",
              description:
                "\nThe shrine here is 210 years old and it is very famous. People from nearby villages come to visit and seek blessings from the deity.",
              media_id: "7342",
              media_name: "7342.jpg",
            },
            {
              name: "Shiv Temple",
              description:
                "There is a 100 years old temple in the village. Lord Shiva is worshipped in this temple. In the evening, the devotees perform aarti. This temple is looked after by the locals. The temple is decorated on the occasion of Shivratri.",
              media_id: "7382",
              media_name: "7382.jpg",
            },
          ],
          village_name: "NYAULA KARSANDA",
          id: 1,
          lat: 26.9619,
          long: 81.2786,
          key: "Kkp5Za6OPuuXZoCnzfg1",
        },
        {
          state_name: "Uttarakhand",
          heritage_places: [
            {
              name: "Vishwanath Temple",
              media_id: "6548380",
              description:
                "\r\n\r\n\r\n\r\n\r\nOne of the region's architectural wonders is the Vishwanath Temple. The tall, towering Vishwanath Temple is located in the village. The temple is surrounded by stunning scenery, with the Chaukhamba snowcapped mountains providing a stunning backdrop.\r\n\r\n\r\n\r\n\r\n",
              media_name: "6548380.jpeg",
            },
          ],
          geohash: "tv2jxt31pzgh",
          id: 1,
          village_name: "GUPTKASHI",
          key: "rbt2dHrZevOk8m945xm9",
          long: 79.0813,
          lat: 30.5271,
        },
        {
          state_name: "Punjab",
          geohash: "ttw2503sbfp5",
          heritage_places: [
            {
              name: "Old Well ",
              media_id: "232842",
              description:
                "\r\n\r\nAn old well is located in this village. It was once used for irrigation and household purposes, but now it has been permanently closed.\r\n",
              media_name: "232842.jpg",
            },
            {
              name: null,
              description:
                "\r\n\r\nAn old well is located in this village. It was once used for irrigation and household purposes, but now it has been permanently closed.\r\n",
              media_id: "232844",
              media_name: "232844.jpg",
            },
          ],
          village_name: "SHEIKHUPUR (96)",
          id: 1,
          lat: 30.9397,
          key: "hgsICUFhmgu22LBA6VZ2",
          long: 76.423,
        },
        {
          state_name: "Rajasthan",
          heritage_places: [
            {
              name: "Johad (water pond)",
              description:
                "\r\n\r\nWater from Johad (water pond) is utilized for irrigation as well as for personal use by the villagers. \r\n\r\n\r\n",
              media_id: "3610477",
              media_name: "3610477.jpg",
            },
          ],
          geohash: "tsvpff65gxfd",
          id: 1,
          village_name: "BHUKHREDI",
          key: "UbV95nr8O6h8Aab7OREm",
          lat: 28.0941,
          long: 74.655,
        },
        {
          state_name: "Jharkhand",
          heritage_places: [
            {
              name: "The old Hanuman Temple which was created by the villagers. ",
              media_id: "6998832",
              description:
                "\r\nThere is a small temple dedicated to Lord Hanuman in the village. It was constructed by the villagers around 45 years ago and is taken care of by them. They come to the temple every Saturday and Tuesday to offer prayers and sacred food to the Lord. \r\n",
              media_name: "6998832.jpg",
            },
          ],
          geohash: "tumvzjhbv4mx",
          id: 1,
          village_name: "CHANAI CHAK",
          long: 87.1503,
          lat: 24.9446,
          key: "gIqwcphGYvaTxcCyxaRu",
        },
        {
          state_name: "Madhya Pradesh",
          geohash: "tu2tkestem32",
          heritage_places: [
            {
              name: "Lord Hanuman Temple",
              description:
                "\r\nThere is an ancient Hanuman Ji temple in this village. It is believed that Lord Hanuman grants all of his devotees' wishes. Special rituals and Puja are organized in the temple every Tuesday.",
              media_id: "443590",
              media_name: "443590.jpg",
            },
            {
              name: "Lord Hanuman Temple",
              description:
                "\r\nThere is an ancient Hanuman Ji temple in this village. It is believed that Lord Hanuman grants all of his devotees' wishes. Special rituals and Puja are organized in the temple every Tuesday.",
              media_id: "443593",
              media_name: "443593.jpg",
            },
          ],
          id: 1,
          village_name: "BRAJPURA",
          key: "ZfM1leNwj3jgegXU0S6H",
          long: 79.6572,
          lat: 24.8493,
        },
        {
          state_name: "Himachal Pradesh",
          geohash: "ttwr0v04nkpe",
          heritage_places: [
            {
              name: null,
              description:
                " Himachal Pradesh Cricket Association (HPCA) Stadium, also known as the Dharamshala Cricket Stadium, is located in the foothills of the magnificent Himalayan mountain range. One of the highest sporting venues in the world, it is situated in the Kangra valley at a height of 1,457 meters, within the Dhauladhar mountain range. The Indian Cricket Team, the Himachal Pradesh state cricket side, and the Kings XI Punjab team from the Indian Premier League (IPL) frequently train at the Cricket Stadium. When there are no scheduled events, the stadium is accessible to visitors. Tibetan architectural design was used to construct the main stand. It can accommodate up to 23,000 spectators and includes a number of modern conveniences for holding cricket matches at the national and international levels.",
              media_id: "6100260",
              media_name: "6100260.jpg",
            },
            {
              name: "Himachal Pradesh Cricket Association (HPCA) Stadium",
              media_id: "6100261",
              description:
                " Himachal Pradesh Cricket Association (HPCA) Stadium, also known as the Dharamshala Cricket Stadium, is located in the foothills of the magnificent Himalayan mountain range. One of the highest sporting venues in the world, it is situated in the Kangra valley at a height of 1,457 meters, within the Dhauladhar mountain range. The Indian Cricket Team, the Himachal Pradesh state cricket side, and the Kings XI Punjab team from the Indian Premier League (IPL) frequently train at the Cricket Stadium. When there are no scheduled events, the stadium is accessible to visitors. Tibetan architectural design was used to construct the main stand. It can accommodate up to 23,000 spectators and includes a number of modern conveniences for holding cricket matches at the national and international levels.",
              media_name: "6100261.jpg",
            },
            {
              name: null,
              media_id: "6100265",
              description:
                " Himachal Pradesh Cricket Association (HPCA) Stadium, also known as the Dharamshala Cricket Stadium, is located in the foothills of the magnificent Himalayan mountain range. One of the highest sporting venues in the world, it is situated in the Kangra valley at a height of 1,457 meters, within the Dhauladhar mountain range. The Indian Cricket Team, the Himachal Pradesh state cricket side, and the Kings XI Punjab team from the Indian Premier League (IPL) frequently train at the Cricket Stadium. When there are no scheduled events, the stadium is accessible to visitors. Tibetan architectural design was used to construct the main stand. It can accommodate up to 23,000 spectators and includes a number of modern conveniences for holding cricket matches at the national and international levels.",
              media_name: "6100265.jpg",
            },
          ],
          village_name: "CHELIAN (361)",
          id: 1,
          key: "H7g0EkmZB87y4GW3JbYK",
          lat: 32.1958,
          long: 76.3223,
        },
        {
          state_name: "Tamil Nadu",
          geohash: "t9z8b6gmy7vk",
          heritage_places: [
            {
              name: "Sacred tree",
              media_id: "7478583",
              description:
                " In this village there is a tree which is considered sacred by this village people. Devotees offer flower garland and sari (a long piece of cloth draped around the body and over one shoulder) to the tree, which is tied with the tree by priest. The tree is considered as goddess, so people worship the tree every Friday.",
              media_name: "7478583.jpg",
            },
          ],
          village_name: "KEELANEDUGULAM",
          id: 1,
          key: "sRWh367wU2OdND2B0hsL",
          lat: 9.9917,
          long: 78.0626,
        },
        {
          state_name: "Odisha",
          geohash: "tgejw98u3y23",
          heritage_places: [
            {
              name: "Lord Jagannath Temple",
              description:
                " Lord Jagannath (local deity) is worshipped in this village. This temple was built by the village cultural committee of this village along with the guidance of Gaintia. This temple is more than 100 years old. Every year, different cultural activities are celebrated by the villagers. This temple is one of the oldest in this village. ",
              media_id: "7958033",
              media_name: "7958033.jpeg",
            },
          ],
          village_name: "CHIPAKANI",
          id: 1,
          lat: 20.6633,
          long: 83.2555,
          key: "WrTIJNthGtMsz8ANmE0b",
        },
        {
          state_name: "Manipur",
          geohash: "wh6sj259mcyw",
          heritage_places: [
            {
              name: "Ibudhou Pakhangba Temple",
              media_id: "6602371",
              description:
                " People of Chothe community visit the  Ibudhou Pakhangba Temple every Sunday to offer prayers and seek the deity's blessings. \r\n\r\n",
              media_name: "6602371.jpg",
            },
          ],
          village_name: "BISHNUPUR KHA",
          id: 1,
          lat: 24.6096,
          key: "3NwXzErRDY23JL2TvvXV",
          long: 93.7514,
        },
        {
          state_name: "Haryana",
          heritage_places: [
            {
              name: "Old Well",
              description:
                " The village also has a 250-year-old well on which the people of the town depended for fresh drinking water for many years. From this, the women of the village used to carry water to the houses. ",
              media_id: "1411935",
              media_name: "1411935.jpg",
            },
          ],
          geohash: "ttjtqbey3j3h",
          id: 1,
          village_name: "SARSANA(14)",
          long: 75.5362,
          key: "krGvIHtgyBsGuP0IFsrP",
          lat: 29.0517,
        },
        {
          state_name: "Andhra Pradesh",
          heritage_places: [
            {
              name: "Vengalammacheruvu Lake",
              media_id: "84559",
              description:
                " Vengalammacheruvu Lake is one of the oldest lakes in Andhra Pradesh. It is having more than 100 years of history. It houses a number of fish species and a rich aquatic ecosystem.",
              media_name: "84559.jpg",
            },
          ],
          geohash: "tdx2eb6w13hk",
          village_name: "VENGALAMMACHERUVU",
          id: 1,
          key: "5rswHlNjRuGMHI475qTk",
          long: 77.8636,
          lat: 14.1528,
        },
        {
          state_name: "Andaman and Nicobar Islands",
          geohash: "w465708dyryu",
          heritage_places: [
            {
              name: "Kali Maa Temple",
              description:
                "A Kali temple is situated in Sita Nagar. The people of this village believe that many years ago an old woman had a dream of Kali Maa. At present, all the villagers believe that Kali Maa makes all their dreams come true.",
              media_id: "6666240",
              media_name: "6666240.jpg",
            },
          ],
          village_name: "SITANAGAR, (EFA)",
          id: 1,
          lat: 13.2308,
          long: 92.9453,
          key: "vTHR1WUyaPVlrdQmdFto",
        },
        {
          state_name: "Goa",
          heritage_places: [
            {
              name: "Santa Cruz Church",
              description:
                "A church dedicated to Santa Cruz or Holy Cross is an attractive spot in the village. It is located near the Cavelossim beach and tourists often visit the church to pray.",
              media_id: "6941256",
              media_name: "6941256.png",
            },
          ],
          geohash: "tdsw6g72ev2f",
          village_name: "CAVELOSSIM",
          id: 1,
          key: "otM8BVWoEfDMVkXpomo6",
          lat: 15.1791,
          long: 73.9536,
        },
        {
          state_name: "Chhattisgarh",
          heritage_places: [
            {
              name: "Forest",
              media_id: "7929559",
              description:
                "A forest is a large area of land that's covered in trees. The word forest can also refer collectively to those trees. An area that's covered in trees can be described with the adjective forested. Less commonly, forest can be used as a verb meaning to cover an area with trees.",
              media_name: "7929559.jpg",
            },
          ],
          geohash: "tu5s2thqf8yq",
          id: 1,
          village_name: "BHAGWATPUR",
          long: 83.6998,
          lat: 23.2757,
          key: "nVIJTe1GKESJzBnc9L61",
        },
        {
          state_name: "Nagaland",
          heritage_places: [
            {
              name: "Hanging Bridge",
              media_id: "7166785",
              description:
                "A hanging bridge of the village is a long bridge made of bamboo and cane, connecting the other side of the village. Vehicles are not allowed to pass through.",
              media_name: "7166785.jpg",
            },
          ],
          geohash: "whg8udt5ku2d",
          village_name: "OLD SANGSA (UR)",
          id: 1,
          lat: 26.8649,
          key: "99LWq32nfEJJ8FUMS3Oc",
          long: 95.1267,
        },
        {
          state_name: "Kerala",
          heritage_places: [
            {
              name: "Aerial view of St. Mary's Jacobite Syrian Church",
              media_id: "7032663",
              description:
                "According to residents, St. Mary's Jacobite Syrian Church dedicated to Lord Jesus is one of the most famous churches in the village. People from nearby villages also come here to worship the diety.",
              media_name: "7032663.png",
            },
          ],
          geohash: "t9y8bvfb8zvm",
          village_name: "POTHANIKKAD",
          id: 1,
          lat: 10.0073,
          key: "VMK7ts5RIE07zyN6m7M8",
          long: 76.6774,
        },
        {
          state_name: "Chandigarh",
          heritage_places: [
            {
              name: "Gurdwara Sahib in the village\r\n",
              description:
                "According to the locals, there is a Gurudwara in a village, since 1970. people of the village visit it twice a day to listen to the Shabad and kirtan.",
              media_id: "97658",
              media_name: "97658.jpg",
            },
          ],
          geohash: "ttqx5f49tybp",
          village_name: "KHUDA ALISHER (CT)",
          id: 1,
          long: 76.8091,
          lat: 30.773,
          key: "pWxaNq7VTTZc1SnxOjsV",
        },
        {
          state_name: "Telangana",
          geohash: "tep63zeq27yf",
          heritage_places: [
            {
              name: "Inside Anjaneya Swami Temple",
              media_id: "7280954",
              description:
                "According to the residents, Anjaneya Swami Temple dedicated to Lord Hanuman from the epic, Ramayana, was built in 18th century and stands as the oldest temple of the village.",
              media_name: "7280954.png",
            },
          ],
          village_name: "DHARUR",
          id: 1,
          long: 77.7767,
          lat: 17.3128,
          key: "ZrvPaPMtxBpheTlM0oXW",
        },
        {
          state_name: "Sikkim",
          geohash: "tuyf63kfhyn3",
          heritage_places: [
            {
              name: "Kitam Bird Sanctuary",
              description:
                "According to the residents, Kitam Bird Sanctuary is located in the village. It is home to more than 200 species of birds. Some of the birds found here are Grey Crown Prinia, Yellow-vented Warbler and Rufus Naked Hornbill.",
              media_id: "7233215",
              media_name: "7233215.png",
            },
          ],
          village_name: "KITAM",
          id: 1,
          lat: 27.1215,
          long: 88.3478,
          key: "CXjbpPmZpa3rG88qkPBK",
        },
        {
          state_name: "LADAKH",
          geohash: "twp4syyce3bq",
          heritage_places: [
            {
              name: "Kanji Dagpo River",
              description:
                "According to the residents, a river known as Kanji Dagpo River, flows through the village. It is a small river made from the melting glacier mountains.",
              media_id: "7282862",
              media_name: "7282862.png",
            },
          ],
          village_name: "KANJI",
          id: 1,
          lat: 34.2268,
          long: 77.5619,
          key: "wkMhgiguUiAcfignwrNu",
        },
        {
          state_name: "Puducherry",
          heritage_places: [
            {
              name: "Ariyankuppam Bridge",
              media_id: "5834720",
              description:
                "Ariyankuppam Bridge is one of the oldest Bridge constructed in Ariyankuppam river. This river is a tributary of Sankaraparani river. Arikamedu is the important tourist spot near this river. Ariyankuppam river drains into Bay of Bengal at Puducherry fishing harbour. This bridge acts as connecting barrier between with Puducherry Town Limit and Ariyankuppam Village.\r\n\r\n",
              media_name: "5834720.jpg",
            },
          ],
          geohash: "tf0g8n25xq14",
          village_name: "ARIANKUPPAM (CT)",
          id: 1,
          key: "GtTWmbTzIotSxqrDqRIL",
          lat: 11.9002,
          long: 79.805,
        },
        {
          state_name: "Delhi",
          geohash: "ttngbk7j8jss",
          heritage_places: [
            {
              name: "Sitaram Temple",
              description:
                "As per the natives of the village, there is an ancient temple in the village known as Sitaram Temple dedicated to Lord Rama (from the epic, Ramayana) and his wife, Goddess Sita. It is considered the oldest temple among all the surrounding villages and is visited by people from other villages as well.",
              media_id: "4587517",
              media_name: "4587517.png",
            },
          ],
          id: 1,
          village_name: "NANGAL THAKRAN",
          key: "6jXT1j4l8vsK4fjTUVAc",
          lat: 28.8085,
          long: 77.0073,
        },
        {
          state_name: "Arunachal Pradesh",
          geohash: "whgz0fsgu0ye",
          heritage_places: [
            {
              name: "Siyom River",
              media_id: "7408435",
              description:
                "As per the natives, the village is surrounded by the Siyom river on one side which adds to the beauty of the river. It also acts as the primary source of water for the natives. This is a major spot of attraction for tourists in the village. ",
              media_name: "7408435.jpg",
            },
          ],
          id: 1,
          village_name: "Rani village",
          lat: 27.9636,
          key: "7Fds9a3KhlehzZuEE0R5",
          long: 95.3131,
        },
        {
          state_name: "Mizoram",
          heritage_places: [
            {
              name: "Temple gate",
              media_id: "7307473",
              description:
                "Buddhist monastery is the place of worship for Buddhists, the followers of Buddhism. They include the structures called Vihara, Chaitya, Stupa, Wat and Pagoda in different regions and languages.",
              media_name: "7307473.jpg",
            },
          ],
          geohash: "wh401vg0n6tt",
          id: 1,
          village_name: "AJASORA-I",
          key: "Ll2kFjL6HUwrOgii6VD0",
          long: 92.8938,
          lat: 22.5316,
        },
        {
          state_name: "The Dadra and Nagar Haveli and Daman and Diu",
          geohash: "teefvjwwr5nc",
          heritage_places: [
            {
              name: "Vangana Park\r\n\r\n",
              media_id: "6669356",
              description:
                "It is said that Vanganga Park was created in Japanese style. The village has a large lake where one can do boating, and enjoy the surrounding beauty. An end number of varied fish species can also be seen. There are restaurants and small eating joints for the visitors. ",
              media_name: "6669356.jpg",
            },
          ],
          village_name: "CHINCHPADA",
          id: 1,
          lat: 20.2022,
          long: 73.0024,
          key: "K0LWUH7Tkyy1mIJ0S2tO",
        },
      ],
      ornaments: [
        {
          state_name: "Meghalaya",
          geohash: "wh91w6xy3hhm",
          ornaments: [
            {
              name: null,
              description: "",
              media_id: "5442307",
              media_name: "5442307.jpg",
            },
            {
              name: null,
              media_id: "5442327",
              description: "",
              media_name: "5442327.jpg",
            },
          ],
          id: 1,
          village_name: "PYNDENGDOMBAH",
          lat: 25.591,
          long: 91.6916,
          key: "ln2W6pZRCNlQy4jgeVQ7",
        },
        {
          state_name: "Punjab",
          geohash: "ttt6gr3c12y4",
          ornaments: [
            {
              description: "",
              media_name: "5484101.jpeg",
            },
          ],
          id: 1,
          village_name: "DARAPUR (331)",
          long: 75.0281,
          lat: 31.4609,
          key: "5DWmFixhn8Tdywu9wqTg",
        },
        {
          state_name: "Jammu and Kashmir",
          geohash: "twhfefc85xtd",
          ornaments: [
            {
              name: "Maang Tikka",
              media_id: "7417864",
              description:
                "\t\r\nA Maang Tikka is a traditional piece of head jewelry that is worn by women of the village on their wedding day or for other significant cultural events. It is a centerpiece that is latched onto the forehead and attached to a chain that is pinned into the hair.",
              media_name: "7417864.jpg",
            },
          ],
          village_name: "MAIDANAN",
          id: 1,
          long: 74.3467,
          lat: 34.2046,
          key: "XKcopatbB9umGULiCzWI",
        },
        {
          state_name: "Jharkhand",
          geohash: "tuj64u4xr5g1",
          ornaments: [
            {
              name: "Woman wearing a Mangalsutra ",
              description:
                "\t\r\nMangalsutra is the main ornament worn by the women in the village. It is regarded as a symbol of married life. It is a necklace of black pearls that women wear around their necks. ",
              media_id: "6375108",
              media_name: "6375108.jpg",
            },
          ],
          village_name: "BHADUDIH",
          id: 1,
          lat: 22.8748,
          key: "NGbWoiPoX0AmML8Wtgi9",
          long: 86.2574,
        },
        {
          state_name: "Uttar Pradesh",
          geohash: "ttpq0cjjcqn2",
          ornaments: [
            {
              name: "Kangan ",
              media_id: "252666",
              description:
                "\n\nKangan is a traditional bracelet worn by women of the village. It is typically made of gold, silver, or other metals and can be embellished with precious stones or intricate designs. It can be worn individually or in a pair, and it is often considered an essential accessory for special occasions such as weddings, festivals, and other cultural events. \n",
              media_name: "252666.jpeg",
            },
          ],
          village_name: "JITPUR",
          id: 1,
          key: "KGUQN8jMiwkhylJWYcqV",
          long: 77.7352,
          lat: 29.1862,
        },
        {
          state_name: "Andhra Pradesh",
          geohash: "tf8f057g7ucr",
          ornaments: [
            {
              name: "Mukkupuduka ",
              media_id: "4438374",
              description:
                "\nA Mukkupuduka (nose pin) is a piece of jewelry worn on the nose. It is worn by women and come in a variety of styles and materials, including gold, silver, and diamond.\n",
              media_name: "4438374.jpeg",
            },
          ],
          village_name: "MOGALLAPALEM",
          id: 1,
          lat: 14.4325,
          long: 79.81,
          key: "ymEddFDFOI38za7L3AP1",
        },
        {
          state_name: "West Bengal",
          geohash: "tun9y085swx7",
          ornaments: [
            {
              name: "Necklace",
              description:
                "\nA necklace is a piece of jewelry worn by women around their necks. It is made of gold or silver and is worn at all festivals as well as on special occasions.",
              media_id: "8505974",
              media_name: "8505974.jpg",
            },
          ],
          village_name: "JHINKA",
          id: 1,
          lat: 22.811,
          long: 88.1545,
          key: "PiljMGJaLQPonQW4j95J",
        },
        {
          state_name: "Gujarat",
          geohash: "teggcjp9pqhe",
          ornaments: [
            {
              name: "Earring",
              media_id: "5627404",
              description:
                "\nAn earring is a type of jewellery worn by women that are pierced through the earlobe or another part of the ear. The women of the village, in particular, wear gold or silver earrings. ",
              media_name: "5627404.jpg",
            },
          ],
          village_name: "SAYAKHA",
          id: 1,
          key: "yXtQAbclTWRlhkNuVoU2",
          lat: 21.7806,
          long: 72.828,
        },
        {
          state_name: "Haryana",
          geohash: "ttqc0m73tpy7",
          ornaments: [
            {
              name: "Anklets ",
              description:
                "\nAnklets are one of the traditional ornaments worn by the women of this village. It is worn around the ankle of both legs. They are made out of gold, silver, stones, and pearl and are made by tying links in a chain. \n",
              media_id: "7197563",
              media_name: "7197563.png",
            },
          ],
          id: 1,
          village_name: "TIKRI (3)",
          key: "5d1DH49H2TmnBRt86ZvN",
          lat: 29.73617,
          long: 77.00786,
        },
        {
          state_name: "Odisha",
          geohash: "tgsubwttqwjb",
          ornaments: [
            {
              name: "Earring",
              media_id: "8499438",
              description:
                "\nEarrings are the traditional ornament typically worn by the women of the village. These can be made of several materials, including gold, silver, plastic, glass, precious stone, beads, wood. Designs range from small hoops and studs to large plates.\n",
              media_name: "8499438.jpeg",
            },
          ],
          village_name: "KARADASINGA",
          id: 1,
          lat: 20.5591,
          key: "yBEt8icyn34IJy5uNhu5",
          long: 85.4595,
        },
        {
          state_name: "Karnataka",
          geohash: "tdq5gzxzd42t",
          ornaments: [
            {
              name: null,
              description:
                "\nGold Chain mostly wearing young boys and girls. Gold is known to possess spiritual powers that bring happiness, peace, stability and luck to those who wear it\n",
              media_id: "8002562",
              media_name: "8002562.jpeg",
            },
          ],
          village_name: "KELAGINAHOSAHALLI",
          id: 1,
          lat: 13.357928928027102,
          long: 76.11302468438782,
          key: "shkiUhfEmKzJgGu962SF",
        },
        {
          state_name: "Assam",
          geohash: "wh9jc26vtxs2",
          ornaments: [
            {
              name: "jonbiri",
              media_id: "4311554",
              description:
                "\nJonbiri is an ornament worn by women. This type of ornament is worn around the neck by the women of this village during Bihu. The evening of Bihu is incomplete without the moonlight. The moon is the self-plane of the Assamese daughter. This type of ornament is very beautiful to look at.",
              media_name: "4311554.jpg",
            },
            {
              name: null,
              media_id: "4311560",
              description:
                "\nJonbiri is an ornament worn by women. This type of ornament is worn around the neck by the women of this village during Bihu. The evening of Bihu is incomplete without the moonlight. The moon is the self-plane of the Assamese daughter. This type of ornament is very beautiful to look at.",
              media_name: "4311560.jpg",
            },
          ],
          id: 1,
          village_name: "HANAPARA",
          key: "yWvpgrSepd6PpHjZhVy1",
          long: 91.4652,
          lat: 26.3256,
        },
        {
          state_name: "Maharashtra",
          geohash: "tgb9tcr9u6je",
          ornaments: [
            {
              name: "Mangalsutra",
              media_id: "2218191",
              description:
                "\nMangalsutra is a traditional necklace that is a symbol of marriage and is worn by married women in the village. The word Mangalsutra can be deciphered as a sacred thread or cord, as Mangal means auspicious and Sutra means thread or cord. One of the most sacred customs in weddings is tying the Mangalsutra around the bride's neck. The necklace is woven with black beads.\n",
              media_name: "2218191.jpg",
            },
            {
              name: "Mangalsutra",
              media_id: "2218218",
              description:
                "\nMangalsutra is a traditional necklace that is a symbol of marriage and is worn by married women in the village. The word Mangalsutra can be deciphered as a sacred thread or cord, as Mangal means auspicious and Sutra means thread or cord. One of the most sacred customs in weddings is tying the Mangalsutra around the bride's neck. The necklace is woven with black beads.\n",
              media_name: "2218218.jpg",
            },
          ],
          village_name: "KHAPA (TUMSAR)",
          id: 1,
          lat: 21.3646,
          key: "ilwxOWRVKR3400w4xmlq",
          long: 79.7163,
        },
        {
          state_name: "Madhya Pradesh",
          geohash: "tspznr67x2fm",
          ornaments: [
            {
              name: "Woman wearing Mangalsutra",
              description:
                "\nMangalsutra is a traditional necklace worn by married women in the village. It consists of a chain, beaded with black and golden beads, with a gold pendant attached to it.",
              media_id: "6871770",
              media_name: "6871770.jpeg",
            },
          ],
          id: 1,
          village_name: "KANERA GOND",
          lat: 23.7709,
          long: 78.6765,
          key: "5CEhmHvzc85aUZv8g6a2",
        },
        {
          state_name: "Tripura",
          geohash: "wh0zu3muvf92",
          ornaments: [
            {
              name: "Women wearing Sankha and Pola ",
              media_id: "100490",
              description:
                "\nSankha is made from conch shells and pola is made from red corals. They are made into bangles, which are worn by married women. White and red in color, these bangles carry ritualistic importance with them, and are thought to be auspicious for the married life of the couple. \n\n",
              media_name: "100490.jpeg",
            },
          ],
          id: 1,
          village_name: "Lankamura",
          key: "4l0ETSoO9Nn7cybWQUDO",
          long: 91.2496,
          lat: 23.87,
        },
        {
          state_name: "Chhattisgarh",
          geohash: "tggk5zj90bjr",
          ornaments: [
            {
              name: "Santi",
              description:
                "\nSanti is a type of anklet made in silver worn by married women. It is also known as Payal or Paijan. ",
              media_id: "6756510",
              media_name: "6756510.jpg",
            },
            {
              name: "Santi",
              description:
                "\nSanti is a type of anklet made in silver worn by married women. It is also known as Payal or Paijan. ",
              media_id: "6756512",
              media_name: "6756512.jpeg",
            },
          ],
          id: 1,
          village_name: "SALHEONA",
          long: 83.4927,
          key: "ChyuwI87PGujiVTKXmoF",
          lat: 21.8355,
        },
        {
          state_name: "Bihar",
          geohash: "tukrdsbmjrkj",
          ornaments: [
            {
              name: "Woman wearing Nath",
              description:
                "\r\n The traditional nose ring worn by the women of the village is Nath. It is worn on the nose which hangs at the septum right down to the upper lip and is made of gold.\r\n",
              media_id: "7487146",
              media_name: "7487146.jpg",
            },
          ],
          id: 1,
          village_name: "ALIPUR",
          lat: 25.2516,
          key: "ntvsNWrcBQm4VrGR3zFM",
          long: 84.837,
        },
        {
          state_name: "Tamil Nadu",
          geohash: "tf0knqcw2kq6",
          ornaments: [
            {
              name: "Necklace ",
              media_id: "311637",
              description:
                "\r\nA necklace is a piece of jewelry worn by women around their necks. It is made of gold or silver and is worn at all festivals as well as on special occasions. It is used as a symbol of wealth and status. \r\n",
              media_name: "311637.jpg",
            },
          ],
          id: 1,
          village_name: "PALLIYANDUR",
          long: 79.3783,
          key: "N1cEDCN4RKi8RQNP7zY0",
          lat: 11.9913,
        },
        {
          state_name: "Telangana",
          geohash: "ter3d16tgud4",
          ornaments: [
            {
              name: "Earrings",
              media_id: "8642314",
              description:
                "\r\nEarrings are the traditional ornament of this village. It is a variety of material worn by both men and women and made of gold, silver and gemstones.",
              media_name: "8642314.jpg",
            },
          ],
          village_name: "TIRMALAPUR",
          id: 1,
          lat: 18.5528,
          key: "jT8X52OoTa3VvMKVbO79",
          long: 77.7868,
        },
        {
          state_name: "Himachal Pradesh",
          geohash: "ttww946x2ydx",
          ornaments: [
            {
              name: "Jhumka",
              media_id: "8256237",
              description:
                "\r\nJhumka is the traditional ornament worn by the women in the village. These are earrings made of gold and silver. The women of the village have different types of earrings, which they wear on a daily basis or on special occasions.",
              media_name: "8256237.jpg",
            },
          ],
          village_name: "JALGARAN (855)",
          id: 1,
          key: "MlbAosZhpuF2glT51Rhb",
          lat: 32.09371839872971,
          long: 76.68803911931712,
        },
        {
          state_name: "Uttarakhand",
          geohash: "tv25b92uwvve",
          ornaments: [
            {
              name: "Woman wearing Nath",
              media_id: "8502082",
              description:
                "\r\nNath is the traditional nose ring worn by the women of the village. It is worn on the nose which hangs at the septum right down to the upper lip and is made of gold which is worn only on special occasions.\r\n",
              media_name: "8502082.jpeg",
            },
          ],
          village_name: "BACHHELI",
          id: 1,
          key: "O8iGE8Vs3C7wIunNKFAL",
          lat: 30.1981,
          long: 78.7733,
        },
        {
          state_name: "Rajasthan",
          geohash: "tsvqmj3gyn2h",
          ornaments: [
            {
              name: "Traditional Bangles",
              description:
                "\r\nThe traditional ornament for women in this village is bangles. The bangles are made from gold, silver, and other metals. These ornaments are worn on all occasions, festivals, and cultural gatherings.\r\n",
              media_id: "7299579",
              media_name: "7299579.jpg",
            },
          ],
          village_name: "BHOORIYO KA BAS",
          id: 1,
          lat: 27.8469,
          long: 75.1052,
          key: "0cG05NaNTnZVIdDsV9q4",
        },
        {
          state_name: "Mizoram",
          geohash: "wh1ymf5yzs5u",
          ornaments: [
            {
              name: "Darngun sei photo taken from Mizoram state museum",
              media_id: "6936652",
              description:
                "\r\nWomen of the Mizo community of the village wear Darngun (bangles). These spiral bangles are made from brass.",
              media_name: "6936652.jpg",
            },
            {
              name: "Darngun sei photo taken from Mizoram state museum",
              media_id: "6936653",
              description:
                "\r\nWomen of the Mizo community of the village wear Darngun (bangles). These spiral bangles are made from brass.",
              media_name: "6936653.jpg",
            },
          ],
          id: 1,
          village_name: "MUALLUNGTHU",
          long: 92.7191,
          lat: 23.6108,
          key: "DYfzDtggs9io9di7icZZ",
        },
        {
          state_name: "Arunachal Pradesh",
          geohash: "wj52hseyys5e",
          ornaments: [
            {
              name: "Ugge Belt",
              media_id: "5825290",
              description:
                "  Ugge is a traditional waist belt constructed of aluminum and plastic rope that women of the Galo tribe typically wear during specific festivals or rituals.",
              media_name: "5825290.jpg",
            },
          ],
          village_name: "PUSHI DOKE",
          id: 1,
          long: 94.7735,
          lat: 28.1509,
          key: "ojfr3N1867qvaDsvebKt",
        },
        {
          state_name: "Nagaland",
          geohash: "wh6rz1wju5qw",
          ornaments: [
            {
              name: "Npui nteu",
              description:
                " Npui nteu is a traditional necklace worn by both men and women. It was made of sea shells earlier but now it's made of glass beads which are easily available in the market.",
              media_id: "5235404",
              media_name: "5235404.jpg",
            },
            {
              name: "Npui nteu",
              media_id: "5235423",
              description:
                " Npui nteu is a traditional necklace worn by both men and women. It was made of sea shells earlier but now it's made of glass beads which are easily available in the market.",
              media_name: "5235423.jpg",
            },
          ],
          village_name: "OLD NGAULONG",
          id: 1,
          lat: 25.2778,
          long: 93.4801,
          key: "BlMY36xsDtAoC9Bi5hYF",
        },
        {
          state_name: "Manipur",
          geohash: "wh6dyxrw89yv",
          ornaments: [
            {
              name: "Kajenglei",
              media_id: "3127550",
              description:
                '"Kajenglei" or "Leitreng" is a Kumbi headdress consisting of eighty to one hundred brass strips and is worn especially by goddesses, female royalties, dancers and brides.',
              media_name: "3127550.jpeg",
            },
          ],
          id: 1,
          village_name: "KUMBI",
          lat: 24.4306,
          key: "49sRJWgUunfhnKVJjbID",
          long: 93.8116,
        },
        {
          state_name: "Goa",
          geohash: "tdsxery7cseu",
          ornaments: [
            {
              name: "Bajubandh",
              media_id: "6965744",
              description:
                "A bajuband is a traditional ornament owned by the village women. It is made from gold. Bajubandh or armlet is always designed with peacock or florals designs. ",
              media_name: "6965744.jpg",
            },
          ],
          village_name: "ANJUNA (CT)",
          id: 1,
          long: 73.9796,
          lat: 15.4241,
          key: "OjozGs4irr35KjqUhzqt",
        },
        {
          state_name: "Puducherry",
          geohash: "tf0et4ct401y",
          ornaments: [
            {
              name: "Attigai",
              description:
                "Attigai (necklace) is a jewelry worn around the neck. It is the traditional ornament, worn by the women of this village. Gold, silver, or other artificial metals are used to make a necklace.",
              media_id: "5862132",
              media_name: "5862132.webp",
            },
          ],
          id: 1,
          village_name: "KIZHUR",
          lat: 11.8812,
          long: 79.675,
          key: "zylr8FyWggiiqGLtNhRJ",
        },
        {
          state_name: "Kerala",
          geohash: "tdjbsxskepm3",
          ornaments: [
            {
              name: "Bamboo Bangle ",
              description:
                "Bamboo Bangle is the traditional ornament of this village. Men and women here wear bangles in this village and comes in a variety of designs and sizes. ",
              media_id: "7143440",
              media_name: "7143440.jpg",
            },
          ],
          id: 1,
          village_name: "ELATHUR (OG)",
          lat: 11.379901,
          key: "1NpJcifk3xMlSgS3kcjj",
          long: 75.789664,
        },
        {
          state_name: "Delhi",
          geohash: "ttndxkd7wscz",
          ornaments: [
            {
              name: "Payal",
              media_id: "72130804-1c29-422c-acd9-afa27ebacf9c",
              description:
                "Bangles and Payal (anklet) are the two ornaments worn by the women of this village. Bangles are generally made of metal, wood, glass, or plastic whereas, Payal is a pair of silver anklets worn around the ankle. ",
              media_name: "72130804-1c29-422c-acd9-afa27ebacf9c.jpg",
            },
            {
              name: "Bangles",
              description:
                "Bangles and Payal (anklet) are the two ornaments worn by the women of this village. Bangles are generally made of metal, wood, glass, or plastic whereas, Payal is a pair of silver anklets worn around the ankle. ",
              media_id: "e1442643-265e-49de-bf09-8d7cd8b421a9",
              media_name: "e1442643-265e-49de-bf09-8d7cd8b421a9.jpg",
            },
          ],
          village_name: "KHARKHARI NAHAR",
          id: 1,
          lat: 28.5898,
          long: 76.9626,
          key: "OhdLocCJdEsF2DrSPc6e",
        },
        {
          state_name: "The Dadra and Nagar Haveli and Daman and Diu",
          geohash: "teefwk2qqj4x",
          ornaments: [
            {
              name: "Bangles and rings",
              media_id: "6658986",
              description:
                "Bangles and rings are the ornaments that are worn by women in the village. Though the bangles are also made out of glass, and other materials, during festivals and other important occasions, the women will be seen adorned with gold ones. ",
              media_name: "6658986.jfif",
            },
          ],
          id: 1,
          village_name: "TINODA",
          key: "duyyYbdKdY9PJmM07oW6",
          lat: 20.1514,
          long: 73.0487,
        },
        {
          state_name: "Andaman and Nicobar Islands",
          geohash: "w44p3h4v4pjt",
          ornaments: [
            {
              name: "Bangles",
              description:
                "Bangles are a traditional piece of jewelry worn by the women of this village. They are rigid bracelets that are usually made of gold, wood, glass, or plastic.",
              media_id: "6623075",
              media_name: "6623075.JPG",
            },
          ],
          village_name: "KALSI NO. 4 (JPPC)",
          id: 1,
          lat: 12.5472835,
          long: 92.8603154,
          key: "RBUKKsS8uAzjeyUg6aOF",
        },
        {
          state_name: "Chandigarh",
          geohash: "ttqwun8t67xv",
          ornaments: [
            {
              name: "Bangles",
              media_id: "388555",
              description:
                "Bangles are traditional ornaments which are worn by the women on their wrist. It is usually made of glass, metal, gold and silver. It is usually worn by females in daily life and on all occasions and festivals.",
              media_name: "388555.jpg",
            },
          ],
          village_name: "KAIMBWALA",
          id: 1,
          lat: 30.7544,
          key: "h7fDixzoZRsmDiBCXDvk",
          long: 76.8172,
        },
        {
          state_name: "Sikkim",
          geohash: "tuyg0rgn85kt",
          ornaments: [
            {
              name: "Woman wearing Bulaki",
              description:
                "Bulaki is the traditional ornament worn by the women of the village. It is a nose ring made of gold or silver and comes in various shapes and designs.",
              media_id: "6718835",
              media_name: "6718835.jpg",
            },
          ],
          village_name: "GYALSHING FOREST BLOCK",
          id: 1,
          key: "yyokQE6dLFlBU6CLmYBG",
          long: 88.2573,
          lat: 27.2898,
        },
        {
          state_name: "LADAKH",
          geohash: "twngkj9dq663",
          ornaments: [
            {
              name: "Chatelaine ",
              media_id: "7344801",
              description:
                "Chatelaine is a traditional ornament worn by the women of the village on their left shoulder. It is made up of one or two silver or brass discs and six or seven lines of cowrie shells strung together with small bells at the ends. It is usually worn by them on special occasions. ",
              media_name: "7344801.jpeg",
            },
          ],
          village_name: "Suspol",
          id: 1,
          lat: 34.3519,
          key: "qa1UqzRF5osqQ2NAYbCp",
          long: 77.1703,
        },
      ],
      traditional_dress: [
        {
          state_name: "Punjab",
          geohash: "tttwtk6p3tbg",
          id: 1,
          village_name: "JHANDE CHAK (304)",
          traditional_dress: [
            {
              name: "Turban",
              media_id: "8258739",
              description: "",
              media_name: "8258739.jpg",
            },
          ],
          lat: 32.1047,
          long: 75.4679,
          key: "JYnQ2Spj8wsLxPxTVS3u",
        },
        {
          state_name: "Uttar Pradesh",
          geohash: "tu9zh0dnvkyf",
          village_name: "JAITPUR",
          id: 1,
          traditional_dress: [
            {
              description: "",
              media_name: "6473978.jpg",
            },
          ],
          lat: 26.5469,
          key: "c9aWlhyKFvqXpmPjQKSU",
          long: 81.3897,
        },
        {
          state_name: "Assam",
          geohash: "wh8qt3ym2cqv",
          id: 1,
          village_name: "GHILAPARA",
          traditional_dress: [
            {
              name: "Gamusa",
              description:
                "\t\r\nGamusa is a rectangular piece of cloth with red borders. It can be used as a lower garment, a waist belt, a towel and a headband. During the festival of Bihu, Gamusa is presented as a Bihuwan (a token of respect to the elders of the family).",
              media_id: "7457857",
              media_name: "7457857.jpeg",
            },
          ],
          long: 90.5909,
          key: "0ssqqnZdisY7kLb2M8Ny",
          lat: 26.4656,
        },
        {
          state_name: "Tamil Nadu",
          geohash: "tcbjunbu4pfh",
          id: 1,
          village_name: "ALANGUDIMAHAJANAM",
          traditional_dress: [
            {
              name: "Sari",
              media_id: "6957253",
              description:
                "\tThe Sari is the traditional outfit worn by the women of the village. It is a piece of unstitched cloth wrapped around the waist and tucked in the form of an ankle-length skirt. The leftover fabric is then tucked at one shoulder. Women also cover their heads as a mark of respect for the elders of the village.",
              media_name: "6957253.jpg",
            },
          ],
          lat: 10.8923,
          long: 78.9269,
          key: "nAHAOIcvo6Hq1Yx2OwCB",
        },
        {
          state_name: "Chhattisgarh",
          geohash: "tg9zpkdtzsdu",
          id: 1,
          village_name: "DUDABHATHA",
          traditional_dress: [
            {
              name: "Lugra",
              media_id: "6581315",
              description:
                "\n\nWomen of the village wear sari known as Lugra. It is usually made of linen, silk or cotton fabric and is available in vibrant colors.",
              media_name: "6581315.jpeg",
            },
          ],
          lat: 20.9437,
          key: "kp3ik7tW04XJtfVATpZw",
          long: 81.5333,
        },
        {
          state_name: "Gujarat",
          geohash: "teg9tfh893h7",
          id: 1,
          village_name: "MIRJAPOR",
          traditional_dress: [
            {
              name: "Sari",
              media_id: "4949457",
              description:
                "\n The traditional dress worn by the women of this village is the Sari (a dress consisting of a very long piece of cloth wrapped around the waist). It is usually made of silk, cotton, or chiffon. It comes in various colors and patterns and is adorned with intricate designs, embroidery, or embellishments.\n",
              media_name: "4949457.jpg",
            },
          ],
          lat: 21.3685,
          key: "y4As6rzQztsZjofR66Ux",
          long: 72.6808,
        },
        {
          state_name: "Maharashtra",
          geohash: "tg8zdh3ykhtg",
          id: 1,
          village_name: "MANGAPUR",
          traditional_dress: [
            {
              name: "Dhoti",
              description:
                "\nA dhoti (loincloth) is a lower garment for men. It is a rectangular piece of unstitched cloth, usually around 4.5 meters long, wrapped around the waist and knotted either in the front or the back. The fabric can be either silk or cotton. ",
              media_id: "7529616",
              media_name: "7529616.jpg",
            },
            {
              name: "Dhoti",
              description:
                "\nA dhoti (loincloth) is a lower garment for men. It is a rectangular piece of unstitched cloth, usually around 4.5 meters long, wrapped around the waist and knotted either in the front or the back. The fabric can be either silk or cotton. ",
              media_id: "7529617",
              media_name: "7529617.jpg",
            },
          ],
          lat: 21.030303,
          key: "1TmRhyHSHwtHHdzwhTKo",
          long: 79.895161,
        },
        {
          state_name: "Andhra Pradesh",
          geohash: "tfc5gzkbbepb",
          id: 1,
          village_name: "PRATHIPADU",
          traditional_dress: [
            {
              name: "Cotton Sari",
              media_id: "7455685",
              description:
                "\nCotton sari is the traditional outfit worn by the women of the village. It is a piece of unstitched cloth wrapped around the waist and tucked in a pleated form. The remaining fabric is then tucked at one shoulder. \n",
              media_name: "7455685.jpeg",
            },
          ],
          long: 80.3276,
          lat: 16.1679,
          key: "6OO3vFC6gaJp27lFjKZA",
        },
        {
          state_name: "Madhya Pradesh",
          geohash: "tu6h6nux8dqp",
          id: 1,
          village_name: "CHHIULAHA",
          traditional_dress: [
            {
              name: "Man wearing Dhoti Kurta",
              description:
                "\nDhoti Kurta is the traditional dress of the men in the village. Dhoti is a piece of cloth wrapped around the waist that covers the lower half of the body, whereas Kurta is a long shirt that covers the upper half.\n",
              media_id: "3352435",
              media_name: "3352435.jpg",
            },
          ],
          key: "KcR72dmptsFOaWqabbD9",
          long: 81.6566,
          lat: 24.6917,
        },
        {
          state_name: "Haryana",
          geohash: "ttqcc6rmtjrm",
          village_name: "JOHAR MAJRA KHURD(46)",
          id: 1,
          traditional_dress: [
            {
              name: "Dhoti-Kurta  ",
              media_id: "227518",
              description:
                "\nDhoti-Kurta is the traditional outfit worn by the men of the village. Kurta is a long tunic paired with Dhoti, which is a piece of unstitched cloth wrapped and tucked at the waist.\n",
              media_name: "227518.jpg",
            },
          ],
          lat: 29.8522,
          key: "vEkifWV4q8DYiSg5fB85",
          long: 77.0573,
        },
        {
          state_name: "Bihar",
          geohash: "tutchemqycqg",
          village_name: "MOHAMMAD HEAT KUMEDAN JAGIR",
          id: 1,
          traditional_dress: [
            {
              name: "Dhoti Kurta",
              media_id: "7987725",
              description:
                "\nDhoti-Kurta is the traditional outfit worn by the men of the village. Kurta is a long tunic paired with Dhoti, which is a piece of unstitched cloth wrapped around the waist.\n",
              media_name: "7987725.jpg",
            },
          ],
          key: "gPRdWl31fRVxUlOnEVtr",
          long: 87.0412,
          lat: 25.5073,
        },
        {
          state_name: "Himachal Pradesh",
          geohash: "ttqvsz3h4r9u",
          id: 1,
          village_name: "TRILOKPUR (124)",
          traditional_dress: [
            {
              name: "A village man wearing Kurta and Dhoti\r\nCaptured by: Gaurav Chauhan\r\n(State Team, CSC)",
              description:
                "\nDhoti-Kurta is the traditional outfit worn by the men of the village. Kurta is a long tunic paired with Dhoti, which is a piece of unstitched cloth wrapped around the waist.\n\n",
              media_id: "289325",
              media_name: "289325.jpg",
            },
          ],
          long: 77.2024,
          lat: 30.5386,
          key: "SCruwDaC5G6rt82aQy0q",
        },
        {
          state_name: "Meghalaya",
          geohash: "wh81mne2n2yn",
          village_name: "ABAGRE",
          id: 1,
          traditional_dress: [
            {
              name: "Woman wearing Mekhela Chador",
              description:
                "\nMekhela Sador is a two-piece attire worn by women of the village. The lower piece of garment, Mekhela is worn like a long skirt that is wrapped, pleated and tucked in around the waist. Sador is a long piece of fabric, whose one end is wrapped and tucked around the Mekhela, while the other end of the Sador is pleated and placed over the left shoulder.\n",
              media_id: "7812170",
              media_name: "7812170.jpg",
            },
          ],
          key: "QNBr4LLyLBW2rSI8BzpU",
          lat: 25.5679372,
          long: 90.2244662,
        },
        {
          state_name: "Karnataka",
          geohash: "tdq6yh98pn5b",
          village_name: "JABAGHATTA",
          id: 1,
          traditional_dress: [
            {
              name: "Panche",
              description:
                "\nPanche (a rectangular piece of cloth) is the traditional dress of this village and is worn by men. It is a non-stitched cloth with a length of 4.5 meters, wrapped around the waist. It is usually paired with Kurta (a long tunic).\n",
              media_id: "6008613",
              media_name: "6008613.jpg",
            },
          ],
          long: 76.5551,
          key: "tIZGGXppVCrWTpAUlD0O",
          lat: 13.1644,
        },
        {
          state_name: "Odisha",
          geohash: "tg7q687erwk6",
          id: 1,
          village_name: "BHATAPUR",
          traditional_dress: [
            {
              name: "Women wearing sari ",
              media_id: "4356217",
              description:
                "\nSari is the main dress worn by the women in the village. It is an unstitched piece of cloth draped around the body and fastened at the waist, with the other end placed like a stole over one shoulder. It is paired with a matching blouse.",
              media_name: "4356217.jpg",
            },
          ],
          key: "wyLSF5jrHgwBeDrgS7xD",
          long: 83.43531,
          lat: 19.381848,
        },
        {
          state_name: "West Bengal",
          geohash: "tun9wrpnf7jq",
          id: 1,
          village_name: "DHANYAHANA",
          traditional_dress: [
            {
              name: "Woman wearing sari ",
              description:
                "\nSari is the traditional dress of the women of the village. It is a cloth ranging between 5 and 5.5 meters in length which are draped around the waist with gathered pleats in the front. They pair it with a blouse and petticoat.\n",
              media_id: "8521280",
              media_name: "8521280.jpg",
            },
          ],
          key: "Ny4CjWrZWmLrI3zUXQ5S",
          long: 88.175,
          lat: 22.8033,
        },
        {
          state_name: "Uttarakhand",
          geohash: "tv2b55qmd9kd",
          id: 1,
          village_name: "ATI",
          traditional_dress: [
            {
              name: "Sari",
              media_id: "3546249",
              description:
                "\nThe sari is the traditional outfit worn by the women of the village. It is a piece of unstitched cloth wrapped around the waist and tucked in a pleated form. The remaining fabric is then tucked at one shoulder. \n",
              media_name: "3546249.jpg",
            },
          ],
          long: 79.94522,
          lat: 29.550054,
          key: "9Tbfy0cEO7ZHpS3dKrk3",
        },
        {
          state_name: "Jammu and Kashmir",
          geohash: "ttv1v44rrb2e",
          village_name: "RAM BAGH",
          id: 1,
          traditional_dress: [
            {
              name: "Sari",
              media_id: "6538178",
              description:
                "\nThe sari is the traditional outfit worn by the women of the village. It is a piece of unstitched cloth wrapped around the waist and tucked in a pleated form. The remaining fabric is then tucked at one shoulder. \n\n",
              media_name: "6538178.jpg",
            },
          ],
          lat: 32.6636,
          key: "qdBdn4x2qwkvwVAVKz6T",
          long: 74.7544,
        },
        {
          state_name: "Mizoram",
          geohash: "w5cyv0dzqn97",
          id: 1,
          village_name: "CHIKHURLUI",
          traditional_dress: [
            {
              name: "Hadi",
              description:
                "\r\n\r\nHadi is commonly worn by the Chakma women of this village. Hadi is a piece of fabric worn diagonally across the body over the shoulder.  It has intricate designs and is only worn on special occasions.\r\n\r\n",
              media_id: "5171730",
              media_name: "5171730.jpg",
            },
            {
              name: "Hadi",
              media_id: "5171803",
              description:
                "\r\n\r\nHadi is commonly worn by the Chakma women of this village. Hadi is a piece of fabric worn diagonally across the body over the shoulder.  It has intricate designs and is only worn on special occasions.\r\n\r\n",
              media_name: "5171803.jpg",
            },
            {
              name: "Pinon",
              description:
                "Pinon is commonly worn by the Chakma women of the village. Pinon is a long wrap-around skirt. It has intricate designs and is only worn on special occasions.",
              media_id: "5172999",
              media_name: "5172999.jpg",
            },
            {
              name: "Dudi",
              media_id: "5175413",
              description:
                "\r\n\r\nDudi is a traditional lower garment worn by the Chakma men of the village. It is white in color and worn on special occasions. ",
              media_name: "5175413.jpg",
            },
          ],
          lat: 22.2843,
          key: "FLRAjF6dCIFPqB3HLLSX",
          long: 92.6847,
        },
        {
          state_name: "Jharkhand",
          geohash: "tuqmqje5cpfh",
          village_name: "RANIGRAM",
          id: 1,
          traditional_dress: [
            {
              name: "Sari",
              description:
                "\r\nA Sari is a long piece of unstitched fabric draped around the body. One end of the sari is tucked at the waist. The other end is pleated and worn over the shoulder. The pallu of the sari (the loose end) is used to cover the head.",
              media_id: "7213170",
              media_name: "7213170.jpg",
            },
          ],
          lat: 24.86,
          long: 87.8069,
          key: "LmKTK0mSZzgYy3Tie6Na",
        },
        {
          state_name: "Telangana",
          geohash: "ter2qtz9zgry",
          id: 1,
          village_name: "SARVAPUR",
          traditional_dress: [
            {
              name: "Sari",
              media_id: "7644318",
              description:
                "\r\nAll married ladies in this village wear the Sari (a long cloth wrapped around the waist and one end draped on the shoulder) which is the most traditional and casual clothing. Sari is also worn by unmarried ladies on important occasions, it also represents our heritage and culture.",
              media_name: "7644318.jpg",
            },
          ],
          long: 77.9916,
          lat: 18.3571,
          key: "BgLxSRvP42e49mPbXx2O",
        },
        {
          state_name: "Rajasthan",
          geohash: "tsv1tvgs1354",
          id: 1,
          village_name: "GELASAR",
          traditional_dress: [
            {
              name: "Woman wearing Lehenga Lungdi with Koti",
              media_id: "7887692",
              description:
                "\r\nLehenga Lungdi with Koti is the traditional dress worn by women in the village. Lehenga is the long skirt carried with Koti. Koti is a kind of top that covers the upper portion of the body. Lungdi is the Chunni or Dupatta (scarf) which is used to cover the head of the lady. \r\n",
              media_name: "7887692.jpeg",
            },
          ],
          long: 74.7888,
          lat: 27.0147,
          key: "OEunqspeMnXe1RDf4pzr",
        },
        {
          state_name: "Nagaland",
          geohash: "whdvh7mwnf19",
          id: 1,
          village_name: "YENCHO",
          traditional_dress: [
            {
              name: "Longpensu(shawl)",
              media_id: "5655543",
              description:
                "\r\nLongpensu (shawl) is commonly used in the village. It is put over by the elders of the village. This shawl has a colour combination of blue and black. \r\n",
              media_name: "5655543.jpg",
            },
          ],
          long: 94.0618,
          lat: 26.2103,
          key: "Vl5XH3ctI0vkm1Fv64OL",
        },
        {
          state_name: "Tripura",
          geohash: "wh1pmxpgsy4u",
          id: 1,
          village_name: "DAKSHIN GAKULNAGAR",
          traditional_dress: [
            {
              name: "A woman in Rignai / Chamathwi. Pic Courtesy: Benjamin Debbarma, VLE",
              description:
                '\r\n“Rignai Chamathwi” or "Chamathwi bar" is a hand-woven piece of cloth worn during important occasions like wedding ceremonies and festivals like Garia Puja and Hangrai.  It is worn by wrapping it around the waist and securing it with a knot. \r\n',
              media_id: "489682",
              media_name: "489682.jpg",
            },
          ],
          long: 91.6588,
          key: "2KP39SW5plSlNiCLmrpo",
          lat: 23.8135,
        },
        {
          state_name: "Manipur",
          geohash: "wh6dwftu7qqj",
          village_name: "CHINGLANGMEI",
          id: 1,
          traditional_dress: [
            {
              name: "Couple wearing Hlam na Pun",
              description:
                " Hlam na Pun is the dress of the village people worn on special occasions like cultural festivals and marriage ceremonies to remember the origin of the 'Kom community'. The Shawl worn by men is known as 'Puisa Pun' and the Lungi is known as 'Uichok Dir' which is wrapped around the waist. The dress worn by women is called 'Santor' which covers the whole part of the body with a 'Punchai Rakom' worn at the waist also a headgear made up of bird wings is worn. These clothes are made by local people with cotton by handloom.",
              media_id: "1781138",
              media_name: "1781138.jpg",
            },
          ],
          long: 93.8203,
          key: "5bfNwsxpNuDFRZhR83ne",
          lat: 24.3602,
        },
        {
          state_name: "Arunachal Pradesh",
          geohash: "wj520400ndw3",
          village_name: "LIPU YOMCHA",
          id: 1,
          traditional_dress: [
            {
              name: "Gale",
              description:
                "A Gale is a traditional dress worn by women. It is a long skirt that is made of cotton, polyester and silk. This dress is typically worn during festivals and on special occasions.",
              media_id: "7298770",
              media_name: "7298770.jpg",
            },
          ],
          lat: 28.136,
          key: "SekrPyL9fX7b1uG6gZaE",
          long: 94.5706,
        },
        {
          state_name: "Goa",
          geohash: "tdsrx5ydd4q0",
          village_name: "MORMUGAO (M CL)",
          id: 1,
          traditional_dress: [
            {
              name: "Sari",
              description:
                "A Sari is a long piece of unstitched fabric draped around the body. One end of the sari is tucked at the waist. The other end is pleated and worn over the shoulder. The pallu of the sari (the loose end) is used to cover the head. It is worn by the women of the village.",
              media_id: "7351486",
              media_name: "7351486.jpeg",
            },
          ],
          long: 73.7932,
          key: "YRhmYkIMyC8Gb8HQYnbB",
          lat: 15.4019,
        },
        {
          state_name: "The Dadra and Nagar Haveli and Daman and Diu",
          geohash: "teegjd5qudj4",
          id: 1,
          village_name: "KUDACHA",
          traditional_dress: [
            {
              name: "Shawl",
              media_id: "7213760",
              description:
                "A shawl is a large piece of woollen cloth worn by men and women in the village. It is generally worn in the winter season. It is available in various colors and patterns.",
              media_name: "7213760.jpeg",
            },
          ],
          key: "YUb10QH9kB5VW9q7gV4x",
          long: 73.0198,
          lat: 20.227,
        },
        {
          state_name: "Sikkim",
          geohash: "tuyepbtjzfr7",
          village_name: "BERMIOK",
          id: 1,
          traditional_dress: [
            {
              name: "Woman wearing Bakhu",
              media_id: "6777658",
              description:
                "Bakhu is a sleeveless version of the Tibetan Chuba, which is worn by both men and women of the Bhutia and Sherpa communities. It is made of silk and is worn only on special occasions.",
              media_name: "6777658.jpg",
            },
          ],
          key: "FjGpwFSHi6nrePtBHCtT",
          lat: 27.24984,
          long: 88.23841,
        },
        {
          state_name: "Kerala",
          geohash: "t9yc16jt4urr",
          id: 1,
          village_name: "PALLIVASAL",
          traditional_dress: [
            {
              name: "Chattayum Mundum",
              description:
                "Chattayum Mundum is a traditional dress worn by older women of this village. Mundu is a long lower garment, and Chatta is Blouse. It is typically made of light fabrics and comes in a variety of prints.",
              media_id: "7143119",
              media_name: "7143119.jpg",
            },
          ],
          lat: 10.0314,
          key: "IsM1ylID2mVIyRQ9AJpG",
          long: 77.0548,
        },
        {
          state_name: "Delhi",
          geohash: "ttndqzces797",
          id: 1,
          village_name: "KHARKHARI ROND",
          traditional_dress: [
            {
              name: "Dhoti Kurta",
              description:
                "Dhoti Kurta is a traditional dress worn by the men in this village. Dhoti is an unstitched rectangular piece of long cloth worn around the legs, and kurta is a loose shirt that is collarless.",
              media_id: "8152814",
              media_name: "8152814.jpeg",
            },
          ],
          lat: 28.5637,
          long: 76.9395,
          key: "6zTLNiJKqfwoJx5AHTem",
        },
        {
          state_name: "Andaman and Nicobar Islands",
          geohash: "w41yps0r5p07",
          id: 1,
          village_name: "UTTARA (RV)",
          traditional_dress: [
            {
              name: "Dhoti",
              media_id: "6613416",
              description:
                "Dhoti is a traditional dress of the locals in Uttara village. It is a simple piece of cloth that is usually white and creamy. It is wrapped around the waist and knotted either in the front or the back of a man.",
              media_name: "6613416.jpg",
            },
          ],
          long: 92.791,
          key: "HsS8DjQl6X43QC20Ezku",
          lat: 12.3279,
        },
        {
          state_name: "Puducherry",
          geohash: "tf0eybufvjkc",
          id: 1,
          village_name: "VILLIANUR (CT)",
          traditional_dress: [
            {
              name: "Saris",
              media_id: "5698091",
              description:
                "Dhoti is the main dress of village men. It is a long piece of cloth wrapped around and knotted to the waist. It is worn with a shirt. Women in the village wear Koora podava and Pattu Sari. Saris are long pieces of cloth that are draped around the body. It is paired with a matching blouse. Women wear it for wedding occasions, Diwali and Pongal festival celebrations, and temple rituals.",
              media_name: "5698091.jpg",
            },
          ],
          key: "MXoqRPJVYYEqOqgGx61q",
          lat: 11.9138,
          long: 79.7565,
        },
        {
          state_name: "LADAKH",
          geohash: "twnvcpg41pgm",
          village_name: "BOGDANG",
          id: 1,
          traditional_dress: [
            {
              name: "Sulma",
              description:
                "Goncha and Sulma are made from locally reared wools. Goncha is the traditional long robe worn by men while women wear Sulma. Tipi is the traditional cap. The uniqueness of this dress is that it is completely handmade with the help of local instruments called Thakshing.",
              media_id: "273383ea-efdc-42ef-b68c-48496135a74a",
              media_name: "273383ea-efdc-42ef-b68c-48496135a74a.jpg",
            },
            {
              name: "Goncha",
              description:
                "Goncha and Sulma are made from locally reared wools. Goncha is the traditional long robe worn by men while women wear Sulma. Tipi is the traditional cap. The uniqueness of this dress is that it is completely handmade with the help of local instruments called Thakshing.",
              media_id: "b4108a20-a515-4fd8-8457-68e1667a40ef",
              media_name: "b4108a20-a515-4fd8-8457-68e1667a40ef.jpg",
            },
            {
              description:
                "Goncha and Sulma are made from locally reared wools. Goncha is the traditional long robe worn by men while women wear Sulma. Tipi is the traditional cap. The uniqueness of this dress is that it is completely handmade with the help of local instruments called Thakshing.",
              media_name: "141099.jpg",
            },
          ],
          key: "lTBcWKUF3brKYlpzupcE",
          lat: 34.8037,
          long: 77.0403,
        },
        {
          state_name: "Chandigarh",
          geohash: "ttqwun8t67xv",
          id: 1,
          village_name: "KAIMBWALA",
          traditional_dress: [
            {
              name: "Salwar suit",
              media_id: "68727",
              description:
                "Salwar paired with a kurta (long shirt) and dupatta is traditional dress worn by the women of the village. They prefer to wear simple suits in the house but during functions, heavy suits with embroidery work are worn by women. This is available in many kinds of fabric like cotton, silk, paper silk, rayon, and many more.",
              media_name: "68727.jpg",
            },
          ],
          key: "h7fDixzoZRsmDiBCXDvk",
          long: 76.8172,
          lat: 30.7544,
        },
      ],
    };
    let snapshots = array[sel];
    snapshots.forEach((snapshot) => {
      let data = snapshot;

      let object = {
        position: {
          lat: parseFloat(data?.lat),
          lng: parseFloat(data?.long),
        },
        name: data?.[sel]?.[0]?.name,
        media: data?.[sel]?.[0]?.media_id,
        media_name: data?.[sel]?.[0]?.media_name,
        village_name: data.village_name,
        state_name: data.state_name,
        key: data.key,
        id: data?.[sel]?.[0]?.media_id,
      };
      if (sel == "food") {
        const result = list.some((obj) => obj.key === object.key);
        if (!result) {
          list.push(object);
        }
      }

      if (sel == "festivals") {
        const result = list.some((obj) => obj.key === object.key);
        if (!result) {
          list.push(object);
        }
      }

      if (sel == "heritage_places") {
        const result = placeData.some((obj) => obj.key === object.key);
        if (!result) {
          list.push(object);
        }
      }

      if (sel == "ornaments") {
        const result = list.some((obj) => obj.key === object.key);
        if (!result) {
          list.push(object);
        }
      }

      if (sel == "traditional_dress") {
        const result = list.some((obj) => obj.key === object.key);
        if (!result) {
          list.push(object);
        }
      }
    });

    if (sel == "food") {
      setFoodData([...list]);
      setLoaded(true);
      setLoaded2(true);
    }

    if (sel == "festivals") {
      setFestivalData([...list]);
      setLoaded(true);
      setLoaded2(true);
    }

    if (sel == "heritage_places") {
      setPlaceData([...list]);
      setLoaded(true);
      setLoaded2(true);
    }

    if (sel == "ornaments") {
      setornamentData([...list]);
      setLoaded(true);
      setLoaded2(true);
    }

    if (sel == "traditional_dress") {
      setDressData([...list]);
      setLoaded(true);
      setLoaded2(true);
    }
  }, [selected]);
  const memoizedData = useMemo(() => {
    if (dataLoaded) {
      let data =
        selected === 0
          ? foodData
          : selected === 1
          ? festivalData
          : selected === 2
          ? placeData
          : selected === 4
          ? ornamentData
          : selected === 5
          ? dressData
          : foodData;

      let data2 =
        selected === 0
          ? foodData
          : selected === 1
          ? festivalData
          : selected === 2
          ? placeData
          : selected === 4
          ? ornamentData
          : selected === 5
          ? dressData
          : foodData;
      let reverseData = [...data].reverse();
      return currentZoom <= 5
        ? [...data2]?.slice(0, 28)
        : currentZoom == 6
        ? [...data2]?.slice(0, 100)
        : currentZoom > 6 && currentZoom <= 8
        ? reverseData?.slice(0, 100)
        : currentZoom > 8 && currentZoom <= 10
        ? reverseData?.slice(0, 120)
        : currentZoom > 10
        ? reverseData?.slice(0, 200)
        : reverseData?.slice(0, 300);
    }
  }, [
    currentZoom,
    selected,
    dataLoaded,
    viewport.districtName,
    dataLoaded2,
    foodData,
    festivalData,
    placeData,
    ornamentData,
    dressData,
  ]);
  const awesomePlaceholder = (
    <>
      <div className="recentLoading media">Loading...</div>
    </>
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBfs_CP7WMzib2pT1_Qc5eF5pJeXvesr68",
    libraries: libraries,
  });

  console.log(currentZoom);
  return (
    <MyLayout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h1 className="th-40 fw-700 th-fair-play text-success">
          Mera Gaon Meri Dharohar
        </h1>
        <h1 className="th-20 fw-700">Search</h1>
        <p style={{ fontStyle: "italic", textAlign: "center" }}>
          Dive deep into themes of your interest and explore the rich cultural
          tapestry that India holds.
        </p>
      </div>
      <div className="col-md-12 p-3 bg-white">
        <div className="d-flex search_map_functionality justify-content-center">
          {isItemDetail && windowDimensions > 768 ? (
            <div
              className="search_sub_mobile th-br-15"
              style={{
                width: isItemDetail ? "30%" : "0%",
                padding: "0px 20px 0px",
                // overflow: "scroll",
                overflowY: "scroll",
                maxHeight: "800px",
              }}
            >
              <div
                className="d-flex justify-content-end "
                style={{ cursor: "pointer" }}
                onClick={closeMapSidebar}
              >
                <img style={{ height: "2vh" }} src={closeLogo} alt="X" />
              </div>
              <div className="d-flex justify-content-center pb-3">
                <div className="fw-bold">
                  {isItemDetail.village_name} Village
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn th-primary"
                  style={{
                    borderRadius: "5px",
                    padding: "5px 15px",
                    color: "#FFF",
                    background: "#75cf8f",
                  }}
                  onClick={() =>
                    history.push(`/village-details/${isItemDetail.key}`)
                  }
                >
                  Visit Village
                </button>
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  style={{
                    width: "250px",
                    height: "250px",
                    padding: "0.5rem 0",
                    borderRadius: "18px",
                    objectFit: "contain",
                  }}
                  src={isItemDetailImg}
                  alt="..."
                />
                <div className="text-capitalize py-3 details-box">
                  <div className="map_image_detail">
                    <strong className="font-weight-bold d-flex justify-content-center">
                      {isItemDetail?.name}
                    </strong>
                  </div>
                  <div className="text-capitalize py-3 details-box">
                    <div className="map_image_detail">
                      {clickItem && clickItem.length > 0
                        ? clickItem?.map((item, index) => {
                            return (
                              <div key={index}>
                                {
                                  <>
                                    {/* <div className="d-flex th-14 th-fw-500 mb-0 text-capitalize px-3">
                                      {item?.title}
                                    </div> */}
                                    <div className="th-12 py-2 px-3">
                                      {item?.description
                                        ? item?.description
                                        : item?.legend_stories}
                                    </div>
                                    <div>{item?.legend_stories}</div>
                                    <div>{item?.personal_details}</div>
                                  </>
                                }
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : isItemDetail && windowDimensions <= 768 ? (
            <>
              <Modal fullscreen={true} show={isItemDetail}>
                <Modal.Header
                  closeButton
                  onClick={() => setIsItemDetail("")}
                ></Modal.Header>
                <Modal.Body>
                  <div
                    className="search_sub_mobile th-br-15"
                    style={{
                      width: isItemDetail ? "100%" : "0%",
                      padding: "0px 20px 0px",
                    }}
                  >
                    <div className="">
                      <p className="map_item">
                        {isItemDetail.village_name} Village
                      </p>
                    </div>
                    <div className="d-flex align-item-center justify-content-center">
                      <button
                        className="btn th-primary"
                        style={{
                          borderRadius: "5px",
                          padding: "5px 15px",
                          color: "#FFF",
                          background: "#75cf8f",
                        }}
                        onClick={() =>
                          history.push(`/village-details/${isItemDetail.key}`)
                        }
                      >
                        Visit Village
                      </button>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          width: "250px",
                          height: "250px",
                          padding: "0.5rem 0",
                          borderRadius: "18px",
                          objectFit: "contain",
                        }}
                        src={isItemDetailImg}
                        alt="..."
                      />
                      <div className="text-capitalize py-3 details-box">
                        <div className="map_image_detail">
                          <div className="font-weight-bold d-flex justify-content-center">
                            {isItemDetail?.name}
                          </div>

                          {clickItem && clickItem.length > 0
                            ? clickItem?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    {
                                      <>
                                        <div className="d-flex justify-content-center th-14 th-fw-500 mb-0 text-capitalize px-3">
                                          {item?.title}
                                        </div>
                                        <div className="th-12 py-2 px-3">
                                          {item?.description
                                            ? item?.description
                                            : item?.legend_stories}
                                        </div>
                                        <div>{item?.legend_stories}</div>
                                        <div>{item?.personal_details}</div>
                                      </>
                                    }
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          ) : (
            ""
          )}
          <ReactPlaceholder ready={true} customPlaceholder={awesomePlaceholder}>
            <div
              className="search_sub"
              style={{ width: isItemDetail ? "70%" : "100%" }}
            >
              <div className="d-flex flex-row justify-content-around mx-2 map_search_label">
                <div
                  className="px-3 shadow-lg"
                  style={selected == 0 ? selectedChipStyle : chipStyle}
                  onClick={() => {
                    setIsItemDetail();
                    setSelected(0);
                  }}
                >
                  Food
                </div>
                <div
                  className="px-3 shadow-lg"
                  style={selected == 5 ? selectedChipStyle : chipStyle}
                  onClick={() => {
                    setIsItemDetail();
                    setSelected(5);
                  }}
                >
                  Dresses
                </div>
                <div
                  className="px-3 shadow-lg"
                  style={selected == 4 ? selectedChipStyle : chipStyle}
                  onClick={() => {
                    setIsItemDetail();
                    setSelected(4);
                  }}
                >
                  Ornaments
                </div>

                <div
                  className="px-3 shadow-lg"
                  style={selected == 1 ? selectedChipStyle : chipStyle}
                  onClick={() => {
                    setIsItemDetail();
                    setSelected(1);
                  }}
                >
                  Festivals
                </div>
                <div
                  className="px-3 shadow-lg"
                  style={selected == 2 ? selectedChipStyle : chipStyle}
                  onClick={() => {
                    setIsItemDetail();
                    setSelected(2);
                  }}
                >
                  Heritage Places
                </div>
                {/* <div
                  className="px-3 shadow-lg"
                  style={selected == 3 ? selectedChipStyle : chipStyle}
                  onClick={() => {
                    setIsItemDetail();
                    setSelected(3);
                  }}
                >
                  Famous Personality
                </div> */}
              </div>

              <div className="th-bg-white p-3">
                {isLoaded && dataLoaded && (
                  <>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      options={{
                        fullscreenControl: false,
                      }}
                      zoom={currentZoom}
                      onLoad={handleLoad}
                      onUnmount={() => setMap(null)}
                      onZoomChanged={handleZoomChanged}
                      onBoundsChanged={handleBounds}
                    >
                      {memoizedData.map((item) => (
                        <CustomInfoWindow
                          key={item.key}
                          item={item}
                          ImageClick={ImageClick}
                        />
                      ))}
                    </GoogleMap>
                  </>
                )}
              </div>
            </div>
          </ReactPlaceholder>
        </div>
      </div>
    </MyLayout>
  );
}

export default React.memo(Index);

const CustomInfoWindow = React.memo(({ item, ImageClick }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const handleImage = (itemV) => {
    ImageClick(itemV);
  };

  // setTimeout(() => { }, 300);
  return (
    <>
      {item.key && item.position && item.position.lat && item.position.lng && (
        <InfoWindow
          key={item?.key}
          options={{ disableAutoPan: true }}
          // position={item.position}
          position={{ lat: item.position.lat, lng: item.position.lng }}
          onDomReady={() => {
            document.getElementsByClassName("gm-ui-hover-effect")[0]?.remove();
          }}
        >
          <div
            className="custom_infowindow"
            onClick={() => handleImage(item)}
            style={{
              width: isHovered ? "200px" : "20px",
              height: "20px",
              zIndex: isHovered ? "10000" : "0",
            }}
          >
            {item?.media_name && <VillageImage image_id={item?.media_name} />}
            {isHovered && (
              <div
                className="text-capitalize"
                style={{
                  color: "#000",
                  fontWeight: "600",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                <div>
                  <p>{item?.village_name}</p>
                </div>
                <div style={{ wordWrap: "break-word" }}>{item?.name}</div>
              </div>
            )}
          </div>
        </InfoWindow>
      )}
    </>
  );
});
