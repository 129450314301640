import React from "react";
import { withRouter } from "react-router-dom";

class DownloadButton extends React.Component {
  handleDownload = () => {
    // // Trigger download action
    // const link = document.createElement("a");
    // link.href = "./Circular for 2000 videos (2) (1).pdf";
    // link.download = "Circular for 2000 videos (2) (1).pdf";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    // Navigate to /notice route
    this.props.history.push("/notice");
  };

  render() {
    return (
      <div
        style={{
          color: "#800000",
          cursor: "pointer",
        }}
      >
        <svg
          className="my-auto img-fluid mx-2 tablet-responsive"
          style={{
            fill: "#800000",
            width: "30px",
            cursor: "pointer",
          }}
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="InsertDriveFileIcon"
          onClick={this.handleDownload}
        >
          <path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm7 7V3.5L18.5 9z"></path>
        </svg>
        <br />
        <span
          style={{
            fontSize: "10px",
          }}
        >
          Notices
        </span>
      </div>
    );
  }
}

export default withRouter(DownloadButton);
