import React, { Component, useEffect, useState } from "react";
import firebase from "../../../firebase";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Link } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import CustomSearchBox from "./CustomSearchBar";
import VillageCard from "./VillageCard";
import MenuSelect from "./MenuSelect";
import "react-placeholder/lib/reactPlaceholder.css";
import "./index.css";
import axios from "axios";

class Village extends Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <div>
          <div className="col-md-12 custom-list">
            <div className=" pb-3 search-content justify-content-between">
              <a href="/explore">
                <svg
                  style={{
                    outline: "none",
                    width: "30px",
                    height: "30px",
                    fill: "#75cf8f",
                  }}
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-18wq8ra"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="KeyboardBackspaceSharpIcon"
                  tabindex="-1"
                  title="KeyboardBackspaceSharp"
                >
                  <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Village;
