import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyMobile from "../../Components/MyComponents/MyMobile";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import Default from "../../Assets/img/General/default.png";
import Editmedia from "../../Assets/img/Artist/EditMedia.svg";
import AddEditMedia from "./AddEditMedia";
import axios from "axios";

const MediaCard = (props) => {
  const [hoverCard, setHoverCard] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [imageData, setImageData] = useState("");
  useEffect(() => {
    console.log(props.cover);
    const fetchData = async () => {
      try {
        const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/350x300/filters:quality(90)/images/${props.cover[0]}`;
        const response = await axios.get(url);
        const data = response?.data?.data;

        setImageData(data);
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    };

    fetchData();
  }, [props.cover]);

  console.log(imageData);
  return (
    <React.Fragment>
      <MyDesktop>
        <MediaCardDiv
          className="gold th-br-12 overflow-hidden position-relative media-card-content"
          onMouseEnter={() => setHoverCard(true)}
          onMouseLeave={() => setHoverCard(false)}
          onTouchStart={() => setHoverCard(true)}
        >
          <div className="media-main-image">
            <img
              style={{
                height: "300px",
                width: "100%",
                objectFit: "cover",
              }}
              src={imageData}
            />
          </div>
          {hoverCard && (
            <div
              className="edit-media d-flex flex-column"
              style={{
                position: "absolute",
                height: "300px",
                width: "100%",
                top: "0",
              }}
              onClick={() => setModalShow(true)}
            >
              <div
                className="common-edit"
                style={{
                  height: "300px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="edit-img"
                  style={{
                    position: "absolute",
                    backgroundImage: `url(${Editmedia})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    height: "80px",
                    width: "80px",
                    paddingBottom: "10%",
                  }}
                ></div>
                <div className="edit-text">Edit Media</div>
              </div>
            </div>
          )}
          <div className="th-bg-white  py-2 px-3">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="th-12 th-fw-600 text-truncate text-capitalize text-lowercase name-text">
                  {props?.media_name}
                </div>
                <div className="th-10 th-grey-2 description-text">
                  {props?.description}
                </div>
              </div>
            </div>
          </div>
        </MediaCardDiv>
      </MyDesktop>
      <MyMobile>
        <MediaCardDiv
          className="gold th-br-12 overflow-hidden position-relative media-card-content"
          onMouseEnter={() => setHoverCard(true)}
          onTouchStart={() => setHoverCard(true)}
          onMouseLeave={() => setHoverCard(false)}
        >
          <div
            className="media-main-image"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "top",
              height: "300px",
              width: "100%",
              paddingBottom: "60%",
            }}
          >
            <img
              src={imageData ? imageData : Default}
              style={{
                width: "100%",
                height: "300px",

                objectFit: "cover",
              }}
            />
          </div>
          {
            <div
              className="edit-media d-flex flex-column"
              style={{
                position: "absolute",
                height: "100px",
                width: "10%",
                top: "15px",
                right: "4%",
              }}
              onClick={() => setModalShow(true)}
            >
              <div
                className="common-edit"
                style={{
                  height: "50px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="edit-img"
                  style={{
                    position: "absolute",
                    backgroundImage: `url(${Editmedia})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    height: "40px",
                    width: "40px",
                    paddingBottom: "10%",
                  }}
                ></div>
                {/* <div className="edit-text">Edit Media</div> */}
              </div>
            </div>
          }
          <div className="th-bg-white  py-2 px-3">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="th-12 th-fw-600 text-truncate text-capitalize text-lowercase name-text">
                  {props?.media_name}
                </div>
                <div className="th-10 th-grey-2 description-text">
                  {props?.description}
                </div>
              </div>
            </div>
          </div>
        </MediaCardDiv>
      </MyMobile>
      <AddEditMedia
        show={modalShow}
        onHide={() => setModalShow(false)}
        item={props?.item}
        edit={true}
        getMediaData={props.getMediaData}
      />
    </React.Fragment>
  );
};

export default MediaCard;

const MediaCardDiv = styled.div`
  transition: all 0.15s ease;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
`;
