import React, { useEffect } from "react";
import MyLayout from "../../Components/MyLayout";
import "./index.scss";

export const Disclaimer = () => {
  useEffect(() => {
    const elementDiv = document.querySelector(".th-90-height-scroll");
    elementDiv.scrollIntoView();
  }, []);
  return (
    <MyLayout className="privacy_main">
      <section className="container-fluid terms_content">
        <div className="custom-rounded-class-terms-screen">
          <div className="p-2 headingTerms">
            <h4 className="d-flex justify-content-center p-2">
              Mera Gaon Meri Dharohar
            </h4>
            <div className="p-1">
              <h5 className="d-flex justify-content-center">DISCLAIMER</h5>
            </div>
          </div>
          <div className="contentTerms p-2">
            <p className="text-justify">
              The use of this website by the User including prospective
              registered Users is solely governed by this policy and any other
              policy so mentioned by terms of reference. Moving past home page,
              or using any of the services shall be taken to mean that User have
              read and agreed to all of the agreements or policies so binding
              upon User and that User are contracting with the Ministry and have
              undertaken binding obligations with the Ministry. Please read
              these ‘Terms of Use’ & ‘Privacy Policy’ (hereinafter referred to
              as "Agreements",) carefully before using our website mgmd.gov.in
              (hereinafter referred to as "us", "we", or "our" "the Site")
              operated by the Ministry of Culture, Government of India
              ("Ministry"). These Agreements sets forth the legally binding
              terms and conditions for your use of the services or website at
              mgmd.gov.in. User will be subject to the rules, guidelines,
              policies, terms, and conditions applicable to any service that is
              provided by this website, and they shall be deemed to be
              incorporated into this Terms of Use & Privacy Policy and shall be
              considered as part and parcel of these Agreements. Ministry holds
              the sole right to modify these Agreements without prior permission
              from User or informing User. The relationship creates on User a
              duty to periodically check the terms and stay updated on its
              requirements. If User continues to use the website following such
              a change, this is deemed as consent by User to the so amended
              Agreement. As long as User complies with these Agreements, The
              User will be granted personal, non-exclusive, non-transferable,
              limited privilege to enter and use the website. Ministry or its
              officials, managers, employees or affiliates will not be liable
              for any loss, damage, injury, inaccessibility, failure,
              interruption, alteration or damage of any data, third party
              information or other information caused to a User arising out of
              use of the Website , its Online services or materials. By
              impliedly or expressly accepting these Terms of Service, User also
              accepts and agrees to be bound by other Company Policies, inter
              alia Privacy Policy, which would be amended from time to time. In
              case User finds any information objectionable or beyond scope of
              such purpose, please feel free to contact Grievance Redressal
              Officer. If you are not satisfied with resolution of your
              query/complaint, you have the option to discontinue use of the
              website or cancel your account with mgmd.gov.in.
            </p>
          </div>
        </div>
      </section>
    </MyLayout>
  );
};
