import React, { useEffect } from "react";
import MyLayout from "../../Components/MyLayout";
import "./index.scss";

export const PrivacyPolicy = () => {
  useEffect(() => {
    const elementDiv = document.querySelector(".th-90-height-scroll");
    elementDiv.scrollIntoView();
  }, []);
  return (
    <MyLayout className="privacy_main">
      <section className="container-fluid terms_content">
        <div className="custom-rounded-class-terms-screen">
          <div className="p-2 headingTerms">
            <h4 className="d-flex justify-content-center p-2">
              Mera Gaon Meri Dharohar
            </h4>
            <div className="p-1">
              <h5 className="d-flex justify-content-center">PRIVACY POLICY</h5>
            </div>
          </div>
          <div className="contentTerms p-2">
            <p className="text-justify">
              <b> 1. GENERAL</b>
              <br /> <br /> a) <br /> <br /> The Ministry of Culture, Government
              of India (hereinafter will be referred as "Ministry") has
              established this Privacy Policy to provide users with information
              about the policies and procedures governing the collection, usage,
              and disclosure of personally identifiable information or data
              obtained from individuals using the website, Mera Gaon Meri
              Dharohar ("MGMD"). The MGMD Platform can be accessed at
              mgmd.gov.in and will be referred to as the "MGMD Platform"
              hereafter. The intent of this privacy policy is to provide clear
              and concise information about how user’s personal information is
              handled. Therefore, this policy has set forth to inform the user
              about the manner in which information will be collected, used, and
              disclosed. This electronic record is generated by a computer
              system and does not require any physical or digital signatures.
              <br /> <br /> b) <br /> <br /> This document is published in
              accordance with the provisions of Rule 3 (1) of the Information
              Technology (Intermediaries guidelines) Rules, 2011 that require
              publishing the Rules and Regulations, Privacy Policy and Terms of
              Use for access or usage mgmd.gov.in.
              <br /> <br /> c) <br /> <br /> The domain name mgmd.gov.in
              ("Website"), is owned and operated by the Ministry where such
              expression shall, unless repugnant to the context thereof, be
              deemed to include its respective representatives, administrators,
              employees, officers, and their assigns.
              <br /> <br /> d) <br /> <br /> For the purpose of these Terms of
              Use (“Terms”), wherever the context so requires, i. The term ‘You’
              &‘User’ shall mean any legal person or entity accessing or
              availing the services provided on this Website, who is competent
              to enter into binding contracts, as per the provisions of the
              Indian Contract Act, 1872; ii. The terms ‘We’, ‘Us’ & ‘Our’ shall
              mean the Website and/or the Ministry, as the context so requires.
              iii. The terms ‘Party’& ‘Parties’ shall respectively be used to
              refer to the User and the Ministry individually and collectively,
              as the context so requires.
              <br /> <br /> e) <br /> <br /> The headings of each section in
              this Policy are only for the purpose of organizing the various
              provisions under this Policy in an orderly manner, and shall not
              be used by either Party to interpret the provisions contained
              herein in any manner. Further, it is specifically agreed to by the
              Parties that the headings shall have no legal or contractual
              value.
              <br /> <br /> f) <br /> <br /> The use of the Website by the User
              is solely governed by this Policy as well as the Terms of Use of
              the Website (“Terms of Use”, available at mgmd.gov.in), and any
              modifications or amendments made thereto by the Ministry from time
              to time, at its sole discretion. Visiting the home page of the
              Website and/or using any of the services provided on the Website
              shall be deemed to signify the User’s unequivocal acceptance of
              this Policy and the aforementioned Terms, and the User expressly
              agrees to be bound by the same. The User expressly agrees and
              acknowledges that the Terms of Use and Policy are co-terminus, and
              that expiry / termination of either one will lead to the
              termination of the other.
              <br /> <br /> g) <br /> <br /> The User unequivocally agrees that
              this Policy and the aforementioned Terms constitute a legally
              binding agreement between the User and the Ministry, and that the
              User shall be subject to the rules, guidelines, policies, terms,
              and conditions applicable to any service that is provided by the
              Website, and that the same shall be deemed to be incorporated into
              the Terms of Use, and shall be treated as part and parcel of the
              same. The User acknowledges and agrees that no signature or
              express act is required to make these Terms and the Policy binding
              on the User, and that the User’s act of visiting any part of the
              Website constitutes the User’s full and final acceptance of the
              Policy and the aforementioned Terms.
              <br /> <br /> h) <br /> <br /> The Parties expressly agree that
              the Ministry retains the sole and exclusive right to amend or
              modify the Policy and the aforementioned Terms without any prior
              permission or intimation to the User and the User expressly agrees
              that any such amendments or modifications shall come into effect
              immediately. The User has a duty to periodically check the Policy
              and Terms, and stay updated on their policies.
              <br /> <br /> i) <br /> <br /> If the User continues to use the
              Website following such a change, the User will be deemed to have
              consented to any and all amendments / modifications made to the
              Policy and Terms. In so far as the User complies with the Policy
              and Terms, he/she is granted a personal, non-exclusive, non-
              transferable, revocable, limited privilege to enter, access and
              use the Website. <br /> <br />
              <b> 2. COLLECTION OF PERSONAL AND OTHER INFORMATION</b>
              <br /> <br /> a) <br /> <br /> The User expressly agrees and
              acknowledges that the Ministry collects and stores the User’s
              personal information, which is provided by the User from time to
              time on the Website, including but not limited to the User’s
              username, password, email address, name, address, Service address,
              destination, age, date of birth, sex, nationality, browsing
              history, etc., as well as any images or other information
              uploaded/submitted by the User on the Website. The Ministry on
              timely basis will evaluate the personal information of the User
              and keep it safe, accurate and up to date. If User identify any
              error in personal information or need to make a change to that
              information, then Only User can edit or update their personal
              information any time on the website. The edited information is
              subject to approval from website administration before updating on
              the website/portal to avoid any misuse and maladministration.
              <br /> <br /> b) <br /> <br /> The User is aware that the Website
              may automatically track certain information about the User based
              upon the User’s IP address and the User’s behavior on the Website
              and the User expressly consents to the same. The User is aware
              that this information is used to do internal research on user
              demographics, interests, and behavior, to enable the Website to
              better understand, and cater to the interests of its users. The
              User is expressly made aware that such information may include the
              URL that the User visited prior to accessing the Website, the URL
              which the User subsequently visits (whether or not these URLs form
              a part of the Websit
              <br /> <br /> e) <br /> <br />, the User’s computer & web browser
              information, the User’s IP address, etc.
              <br /> <br /> c) <br /> <br /> The User is aware that any and all
              information pertaining to the User collected by the Ministry,
              whether or not directly provided by the User to the Ministry or
              Website, including but not limited to personal correspondence such
              as emails or letters, feedback from other users or third parties
              regarding the User’s activities or postings on the Website, etc.,
              may be collected and complied by the Ministry or Website into a
              file or folder specifically created for / allotted to the User,
              and the User hereby expressly consents to the same.
              <br /> <br /> d) <br /> <br /> The User is aware that while he/she
              can browse some sections of the Website without being a registered
              user, certain activities such as (adding information and
              registering as an artist) require the User to provide valid
              personal information to the Ministry for the purpose of
              registration. The User is aware that the contact information
              provided to the Ministry or Website may be used to send the
              information and promotions, whether or not based on the User’s
              previous browsing and interests, and the User hereby expressly
              consents to receiving the same. <br /> <br />{" "}
              <b>3. USER’S RESPONSIBILITIES</b> <br /> <br />
              It is a user’s responsibility to provide correct information about
              its contact particulars. Ministry shall send emails or call at the
              email addresses/ phone numbers mentioned by a User at the time of
              registration. Ministry will not be liable for any incorrect
              information submitted by a user at any point of time on
              mgmd.gov.in. <br /> <br />
              <b>4. COOKIES</b>
              <br /> <br /> a) <br /> <br /> The User is aware that a ‘Cookie’
              is a small piece of information stored by a web server on a web
              browser so it can later be traced back from that particular
              browser, and that cookies are useful for enabling the browser to
              remember information specific to a given user, including but not
              limited to a User’s login identification, password, etc. The User
              is aware that the Website places both permanent and temporary
              cookies in the User’s computer's hard drive and web browser, and
              does hereby expressly consent to the same.
              <br /> <br /> b) <br /> <br /> The User is further aware that the
              mgmd.gov.in uses or might use data collection devices such as
              cookies on certain pages of the mgmd.gov.in to help analyze web
              page flow, measure promotional effectiveness, and promote trust
              and safety, and that certain features of the mgmd.gov.in are only
              available through the use of such cookies. While the User is free
              to decline the Website’s cookies if the User’s browser permits,
              the User may consequently be unable to use certain features on the
              mgmd.gov.in.
              <br /> <br /> c) <br /> <br /> Additionally, the User is aware
              that he/she might encounter ‘cookies’ or other similar devices on
              certain pages of the mgmd.gov.in that are placed by affiliates of
              the Ministry. The User expressly agrees and acknowledges that the
              Ministry or Website does not control the use of such cookies/other
              devices by such third parties, that the Ministry or Website is in
              no way responsible for the same and that the User assumes any and
              all risks in this regard. <br /> <br />
              <b>5. DIVULGING/SHARING OF PERSONAL INFORMATION</b>
              <br /> <br /> a) <br /> <br /> The User is aware that mgmd.gov.in
              may disclose personal information if required to do so by law or
              if mgmd.gov.in in good faith believes that such disclosure is
              reasonably necessary to respond to subpoenas, court orders, or
              other legal processes. The mgmd.gov.in may also disclose the
              User’s personal information to law enforcement offices, third
              party rights owners, or other third parties if it believes that
              such disclosure is reasonably necessary to enforce the Terms or
              Policy; respond to claims that an advertisement, posting or other
              content violates the rights of a third party; or protect the
              rights, property or personal safety of its users, or the general
              public.
              <br /> <br /> b) <br /> <br /> The Ministry may disclose
              information if legally required to, in pursuant to an order from a
              governmental entity or in good faith. We will disclose the
              information bases to: i. confirm to legal requirements or comply
              with legal process; ii. protect our rights or property or our
              affiliated authorities/ministries; iii. prevent a crime or protect
              national security; or iv. protect the personal safety of users or
              the public. v. Ministry has rights to accept or reject any
              inappropriate information <br /> <br /> <b>
                6. USER’S CONSENT
              </b>{" "}
              <br /> <br /> By using the Website or by providing information to
              the Ministry through the Website, the User consents to the
              collection and use of the information disclosed by the User on the
              Website in accordance with this Policy, including but not limited
              to the User’s consent the mgmd.gov.in sharing/divulging the User’s
              information, as per the terms contained hereinabove in clause 2 of
              the Policy. <br /> <br /> <b> 7. GRIEVANCE OFFICER </b> <br />{" "}
              <br /> In case User has any grievances in connection with the use
              of the Website, a user may write to the grievance redressal
              officer. The grievance redressal officer is authorized to attend
              to any user complaints or queries and resolve any matter within 30
              days from date of receipt of complaint. In accordance with
              Information Technology Act 2000 and rules made there under, the
              name and contact details of the Grievance Officer are provided
              below: Name: Shri Abhay Mishra Phone no- 011-23446502 Email-
              bld.ignca@gmail.com Address: Room no 506, NMCM, 5 th Floor, A
              wing, IGNCA Janapath Road New Delhi -110001 <br /> <br />{" "}
              <b>
                {" "}
                8. NOTICES, JURISDICTION AND GOVERNING LAW FOR DISPUTE
                RESOLUTION:
              </b>
              <br /> <br /> a) <br /> <br /> Any notices or communications
              required to be given or served by any of the Parties on the others
              in respect of this Agreement shall be given in writing in English,
              and shall be deemed to have been duly served, if sent by prepaid
              registered mail with acknowledgement due at the address specified
              in this Agreement or at such other address as may have been
              notified by the Parties to the other. Any notice under this
              Agreement shall be deemed to be received: i. if delivered
              personally, at the time of delivery; ii. in the case of courier,
              72 hours from the date of posting;
              <br /> <br /> b) <br /> <br /> Any Disputes / Claims arising out
              of this Agreement are subject to Arbitration and the Jurisdiction
              of Gurugram, Haryana Courts. Further, in case the parties failed
              to resolve the dispute; this Agreement shall be governed by and
              construed in accordance with the law and Jurisdiction within
              Gurugram, Haryana, India only.
              <br /> <br /> c) <br /> <br /> Any amendments in the clauses of
              the Agreement can be effected as an addendum, after written
              approval from both parties.
            </p>
          </div>
        </div>
      </section>
    </MyLayout>
  );
};
