import { Component } from "react";
import firebase from "../../../firebase";
import { Link, withRouter } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { Alert, Modal } from "react-bootstrap";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";

import OnboardingImage from "../../Assets/img/background/home_bg.jpg";
import SingBG from "../../Assets/img/background/signin_m.jpg";
import closeLogo from "../../Assets/img/icons/close.svg";
import InfoIcon from "../../Assets/img/LoginSignupPage/Info.svg";
import EyeIcon from "../../Assets/img/LoginSignupPage/EyeIcons.svg";
import EyeSlashedIcon from "../../Assets/img/LoginSignupPage/EyeCross.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
class Login extends Component {
  state = {
    isLoading: false,
    isSignedIn: false,
    values: {
      name: "",
      email: "",
      password: "",
    },
    errMsgEmail: "",
    errMsgContact: "",
    errMsgPassword: "",
    successMsg: "",
    passwordErrorMsg: "",
    invalidUserData: "",
    showpassword: false,
    requestedUser: "simpleUser",
    showToaster: false,
    showTooltip: false,
    ErrorPopupEmail: false,
  };

  onComponentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user.isSignedIn) {
        if (!localStorage.getItem("user")) {
          localStorage.setItem("user", JSON.stringify(user));
        }
      } else {
      }
    });
  }
  handleTouchStart = () => {
    this.setState({ showTooltip: true });
    setTimeout(() => {
      this.setState({ showTooltip: false });
    }, 1500);
  };
  forgotPassword = () => {
    var email_add = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.state.values.email == "") {
      this.validationForm(
        this.state.errMsgEmail
          ? this.state.errMsgEmail
          : "Please enter your email address"
      );
    } else {
      if (!email_add.test(this.state.values.email)) {
        this.validationForm(
          this.state.errMsgEmail
            ? this.state.errMsgEmail
            : "Please enter valid email address"
        );
      } else {
        firebase
          .auth()
          .sendPasswordResetEmail(this.state.values.email)
          .then((data) => {
            this.setState({
              invalidUserData: "",
            });
            this.setState({
              successMsg: this.props.t("reset_password_successful"),
            });
            setTimeout(() => {
              this.setState({ successMsg: "" });
            }, 1500);
          })
          .catch((error) => {
            this.validationForm(
              "There is no user record corresponding to this identifier. The user may have been deleted."
            );
            setTimeout(() => {
              this.validationForm("");
            }, 1500);
          })
          .finally(() => {
            this.setState({
              isLoading: false,
            });
          });
      }
    }
  };

  handleEmail = (e) => {
    this.setState({
      invalidUserData: "",
    });
    var { values = {} } = this.state;
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    values.email = e.target.value;
    if (emailPattern.test(e.target.value)) {
      this.setState({ values, errMsgEmail: "" });
    } else {
      this.setState({
        values,
        errMsgEmail: "Please Enter Valid Email Id",
      });
    }
  };

  handleNameChange = (e) => {
    this.setState({
      values: {
        ...this.state.values,
        name: e.target.value,
      },
    });
  };

  handlePassword = (e) => {
    this.setState({
      invalidUserData: "",
    });
    var { values = {} } = this.state;

    var passwordPattern = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,25}$/;
    values.password = e.target.value;
    if (passwordPattern.test(e.target.value)) {
      this.setState({ values, errMsgPassword: "" });
    } else {
      this.setState({
        values,
        errMsgPassword: "Minimum 8 characters and at least 1 special character",
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value },
    });
  };

  validationForm(msj) {
    this.setState({
      invalidUserData: msj,
    });
  }

  onFinish = (e, _userType) => {
    this.setState({ isLoading: true });
    e.preventDefault();

    // checking if the fields are filled
    if (this.state.values.email == "" || this.state.values.password == "") {
      this.validationForm("Please fill all fields");
      setTimeout(() => {
        this.validationForm("");
      }, 1500);
      this.setState({ isLoading: false });
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(
          this.state.values.email,
          this.state.values.password
        )
        .then((res) => {
          let db = firebase.firestore().collection("users");
          firebase.auth().onAuthStateChanged((user) => {
            if (user)
              db.doc(res.user.uid)
                .get()
                .then((response) => {
                  this.setState({ showToaster: true });
                  if (response.data() == undefined) {
                    db.doc(firebase.auth().currentUser.uid)
                      .set(
                        {
                          values: {
                            name: firebase.auth().currentUser.displayName || "",
                            email: firebase.auth().currentUser.email,
                          },
                          avatar: firebase.auth().currentUser.photoURL || "",
                        },
                        { merge: true }
                      )
                      .then((response) => {
                        localStorage.setItem(
                          "user",
                          JSON.stringify(firebase.auth().currentUser)
                        );
                        localStorage.setItem("userType", _userType);
                        this.setState({ isLoading: false });
                        setTimeout(() => {}, 1500);
                      })
                      .catch((err) => {
                        console.log("second err: ", err);
                      });
                  } else {
                    if (_userType == "artist") {
                      if (
                        response.data()?.userType != "undefined" &&
                        response.data()?.userType != "artist"
                      ) {
                        this.validationForm("Invalid Artist Credential");
                        this.setState({ showToaster: false });
                        this.setState({ isLoading: false });
                        setTimeout(() => {
                          this.validationForm("");
                        }, 1500);
                      } else {
                        localStorage.setItem(
                          "userdata",
                          JSON.stringify(response.data()?.values)
                        );
                        localStorage.setItem(
                          "user",
                          JSON.stringify(firebase.auth().currentUser)
                        );
                        localStorage.setItem(
                          "userType",
                          _userType == "artist" &&
                            response.data()?.userType != "undefined"
                            ? "artist"
                            : "simpleUser"
                        );
                        this.setState({ showToaster: true });
                        setTimeout(() => {
                          this.setState({ isLoading: false });
                          this.props.history.push("/artist-profile");
                        }, 500);
                      }
                    } else {
                      if (
                        response.data()?.userType != "undefined" &&
                        response.data()?.userType == "artist"
                      ) {
                        this.validationForm("Invalid User Credential");
                        this.setState({ showToaster: false });
                        this.setState({ isLoading: false });
                        setTimeout(() => {
                          this.validationForm("");
                        }, 1500);
                      } else {
                        localStorage.setItem(
                          "userdata",
                          JSON.stringify(response.data()?.values)
                        );
                        localStorage.setItem(
                          "user",
                          JSON.stringify(firebase.auth().currentUser)
                        );
                        localStorage.setItem(
                          "userType",
                          _userType == "artist" &&
                            response.data()?.userType != "undefined"
                            ? "artist"
                            : "simpleUser"
                        );
                        setTimeout(() => {
                          this.setState({ isLoading: false });
                          this.props.history.push("/explore");
                        }, 500);
                      }
                    }
                  }
                })
                .catch((err) => {
                  console.log("first err; ", err);
                });
          });
        })
        .catch((err) => {
          if (this.state.requestedUser == "simpleUser") {
            this.validationForm("Invalid User Credential");
            setTimeout(() => {
              this.validationForm("");
            }, 1500);
          } else {
            this.validationForm("Invalid Artist Credential");
            setTimeout(() => {
              this.validationForm("");
            }, 1500);
          }

          this.setState({ showToaster: false });
          this.setState({ isLoading: false });
        });
    }
  };

  handleRequestedUserChange = () => {
    if (this.state.requestedUser == "simpleUser")
      this.setState({
        requestedUser: "artist",
      });
    else
      this.setState({
        requestedUser: "simpleUser",
      });
  };

  render() {
    const { t } = this.props;
    const tooltip = (
      <Tooltip id="tooltip" style={{ background: "transparent" }}>
        Who Is artist? <br />
        An artist is someone who uses their creativity to produce creative works
        like paintings, music, stories, and more, using a variety of skills and
        imagination.
      </Tooltip>
    );
    return (
      <MyLayout
        sideheader={false}
        transparent={true}
        bg={OnboardingImage}
        bg_m={SingBG}
        footer={false}
      >
        <MyDesktop>
          <ToastContainer position="top-end" className="p-3">
            <Toast
              onClose={() => this.setState({ showToaster: false })}
              show={this.state.showToaster}
              className="text-white th-bg-primary "
            >
              <Toast.Body>
                {this.state.requestedUser === "artist" ? "Artist" : "User"}{" "}
                login successfully.
              </Toast.Body>
            </Toast>
          </ToastContainer>

          <div className="login-popup col-md-4 th-bg-white mx-auto th-br-20 py-2 d-flex justify-content-center position-relative">
            <Link to="/explore">
              <img
                className="position-absolute img-fluid"
                src={closeLogo}
                alt="..."
                style={{ right: "5%", top: "5%", height: "16px" }}
              ></img>
            </Link>
            <div style={{ width: "340px" }}>
              <div className="col-12 th-24 th-fw-500 pt-4 th-dark-grey text-center">
                <span>Log In</span>
              </div>
              <div className="col-12 th-12 th-fw-400 th-md-grey text-center pb-3">
                <span>Enter your details to continue</span>
              </div>
              {this.state.invalidUserData != "" && (
                <Alert
                  key={"danger"}
                  variant={"danger"}
                  className="d-flex align-item-center justify-content-center"
                >
                  {this.state.invalidUserData}
                </Alert>
              )}
              {this.state.successMsg && (
                <Alert key={"success"} variant={"success"}>
                  {this.state.successMsg}
                </Alert>
              )}
              <form
                onSubmit={(e) => e.preventDefault()}
                className="pt-3 w-100 col-12"
              >
                <div>
                  <input
                    type="text"
                    placeholder="E-mail Address"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="email"
                    onChange={(e) => this.handleEmail(e)}
                  />
                  <hr className="th-hr"></hr>
                </div>

                <div className="pt-2 d-flex password-page">
                  <input
                    type={this.state.showpassword ? "text" : "password"}
                    placeholder="Password"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="password"
                    onChange={(e) => this.handlePassword(e)}
                  />
                  <span
                    onClick={() => {
                      this.setState({
                        showpassword: !this.state.showpassword,
                      });
                    }}
                    className="eyeicon"
                  >
                    {this.state.showpassword == true ? (
                      <img src={EyeIcon} alt="EyeIcon" height="20" width="20" />
                    ) : (
                      <img
                        src={EyeSlashedIcon}
                        alt="EyeSlashedIcon"
                        height="20"
                        width="20"
                      />
                    )}
                  </span>
                </div>
                <hr className="th-hr"></hr>
                <div className="pt-3">
                  <div
                    className="row justify-content-center text-center"
                    style={{ marginLeft: "5px" }}
                  >
                    <div className="row g-0">
                      <div className="col-2">
                        <div className="row justify-content-center">
                          <input
                            onClick={this.handleRequestedUserChange}
                            type="checkbox"
                            id="i-am-artist-checkbox"
                            className="checkbox-i-am-artist text-center"
                            role="button"
                          />
                          <label className="custom-control-label"></label>
                        </div>

                        <div className="row ">
                          <span>
                            <OverlayTrigger placement="auto" overlay={tooltip}>
                              <p className="text-i-am-artist text-right">
                                <span>
                                  <img
                                    src={InfoIcon}
                                    alt="InfoIcon"
                                    className="icon-login"
                                  />{" "}
                                </span>
                                I am artist
                              </p>
                            </OverlayTrigger>
                          </span>
                        </div>
                      </div>
                      <div className="col-9">
                        {!this.state.isLoading ? (
                          <button
                            className="w-100 px-3 py-2 th-fw-400 th-btn-green "
                            onClick={(e) =>
                              this.onFinish(e, this.state.requestedUser)
                            }
                          >
                            <span>
                              Login as{" "}
                              {this.state.requestedUser == "simpleUser"
                                ? "User"
                                : "Artist"}
                            </span>
                          </button>
                        ) : (
                          <button
                            className="w-100  px-3 py-2 th-btn-green d-flex align-items-center justify-content-center"
                            disabled
                          >
                            <span
                              className="spinner-grow spinner-grow-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Wait...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-12 th-13 pt-4 text-center th-md-grey pb-3">
                Don't have an account?
                <Link to="/signup">
                  <span className="th-green">Sign Up here!</span>
                </Link>
              </div>
              <div className="col-12 th-13 pt-2 text-center th-md-grey pb-3">
                Forgot Password?
                <span
                  className="th-green"
                  onClick={() => {
                    this.forgotPassword();
                  }}
                  role="button"
                >
                  {" "}
                  Reset Password
                </span>
              </div>
            </div>
          </div>
        </MyDesktop>
        <MyMobile>
          <ToastContainer position="top-end" className="p-3">
            <Toast
              onClose={() => this.setState({ showToaster: false })}
              show={this.state.showToaster}
              className="text-white th-bg-primary "
            >
              <Toast.Body>
                {this.state.requestedUser === "artist" ? "Artist" : "User"}{" "}
                Login successfully.
              </Toast.Body>
            </Toast>
          </ToastContainer>
          <div className="container">
            <div className="row px-1 mx-1 mobile-theme th-bg-white th-br-20 py-2 d-flex justify-content-center position-relative">
              <Link to="/explore">
                <img
                  className="position-absolute close_btn_login"
                  src={closeLogo}
                  alt="closeLogo"
                ></img>
              </Link>
              <div className="col-12 th-24 th-fw-500 pt-4 th-dark-grey text-center">
                <span>Login</span>
              </div>
              <div className="col-12 th-16 th-fw-400 th-md-grey text-center">
                <span>Enter your details to continue</span>
              </div>
              {this.state.invalidUserData && (
                <Alert
                  key={"danger"}
                  variant={"danger"}
                  className="d-flex align-item-center justify-content-center"
                >
                  {this.state.invalidUserData}
                </Alert>
              )}
              {this.state.successMsg && (
                <Alert key={"success"} variant={"success"}>
                  {this.state.successMsg}
                </Alert>
              )}
              <form onSubmit={this.onFinish} className="pt-3 w-100 col-12">
                <div>
                  <input
                    type="text"
                    placeholder="Email address"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="email"
                    onChange={this.handleInputChange}
                  />
                  <hr className="th-hr"></hr>
                </div>

                <div className="pt-3 password-page">
                  <input
                    type={this.state.showpassword ? "text" : "password"}
                    placeholder="Password"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="password"
                    onChange={this.handleInputChange}
                  />
                  <span
                    onClick={() => {
                      this.setState({
                        showpassword: !this.state.showpassword,
                      });
                    }}
                    className="eyeicon"
                  >
                    {this.state.showpassword == true ? (
                      <img
                        src={EyeIcon}
                        alt="EyeIcon."
                        height="20"
                        width="20"
                      />
                    ) : (
                      <img
                        src={EyeSlashedIcon}
                        alt="EyeSlashedIcon"
                        height="20"
                        width="20"
                      />
                    )}
                  </span>

                  <hr className="th-hr"></hr>
                </div>

                <div className="pt-3">
                  <div className="row">
                    <div
                      className="row g-0 justify-content-center"
                      style={{ alignItems: "center", margin: "0px -3px" }}
                    >
                      <div className="col-3">
                        <div className="row justify-content-center">
                          <input
                            onClick={this.handleRequestedUserChange}
                            type="checkbox"
                            id="i-am-artist-checkbox"
                            className="checkbox-i-am-artist-mobile"
                            role="button"
                          />
                          <label className="custom-control-label"></label>
                        </div>

                        <div className="row g-0">
                          <span>
                            <div className="text-i-am-artist-mobile">
                              <span>
                                <OverlayTrigger
                                  placement="auto"
                                  overlay={tooltip}
                                  trigger={["click", "touch"]}
                                  show={this.state.showTooltip}
                                >
                                  <img
                                    src={InfoIcon}
                                    alt="..."
                                    className="icon-login"
                                    onTouchStart={this.handleTouchStart}
                                  />
                                </OverlayTrigger>
                              </span>
                              I am artist
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="col-7">
                        {!this.state.isLoading ? (
                          <button
                            className="w-100 px-3 py-2 th-fw-400 th-btn-green "
                            onClick={(e) =>
                              this.onFinish(e, this.state.requestedUser)
                            }
                          >
                            <span>
                              Login as{" "}
                              {this.state.requestedUser == "simpleUser"
                                ? "User"
                                : "Artist"}
                            </span>
                          </button>
                        ) : (
                          <button
                            className="w-100 px-3 py-2 th-btn-green d-flex align-items-center justify-content-center"
                            disabled
                          >
                            <span
                              className="spinner-grow spinner-grow-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Wait...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-12 th-16 pt-4 text-center th-md-grey">
                Don't have an account?
                <Link to="/signup">
                  <div className="th-green">Sign Up here!</div>
                </Link>
              </div>
              <div className="col-12 th-16 pt-2 text-center th-md-grey pb-2">
                Forgot Password
                <span
                  className="th-green"
                  onClick={() => {
                    this.forgotPassword();
                  }}
                >
                  {" "}
                  Reset Password
                </span>
              </div>
            </div>
          </div>
        </MyMobile>
      </MyLayout>
    );
  }
}
export default withRouter(Login);
