export async function handleImageError(imgElement, mediaName) {
  console.log('imgElement :>> ', imgElement);
  console.log('mediaName :>> ', mediaName);
  const cloudFunctionUrl = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLS3?media_name=${mediaName}`;
  try {
    const response = await fetch(cloudFunctionUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    imgElement.target.src = data.signedUrl;
  } catch (error) {
    console.error("Error fetching signed URL:", error);
  }
}
