import { useState, useEffect, useRef } from "react";
import { Offcanvas } from "react-bootstrap";
import MyLayout from "../../Components/MyLayout";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import Web_Banner from "../../Assets/img/AboutPage/Web_Banner.svg";
import Mobile_Banner from "../../Assets/img/AboutPage/Banner_MGMD.jpg";
import MenuIcon from "../../Assets/img/icons/menu.svg";
import "./index.css";
import { Link } from "react-router-dom";

// images facts
import fact1 from "../../Assets/img/Facts/fact1.png";
import fact2 from "../../Assets/img/Facts/fact2.png";
import fact3 from "../../Assets/img/Facts/fact3.png";
import fact4 from "../../Assets/img/Facts/fact4.png";

//about page returning simple paragraphed text
const About = () => {
  const [show, setShow] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [selected, setSelected] = useState("Vision");

  // content refs for scroll and listing
  const scrollToref = useRef();
  const scrollToref_mission = useRef();
  const scrollToref_object = useRef();
  const scrollToref_programme = useRef();
  const scrollToref_gaon = useRef();
  const scrollToref_work = useRef();
  const scrollToref_fact = useRef();
  const scrollToref_links = useRef();
  const scrollToref_pratibha = useRef();
  const topicList = [
    {
      item: "Vision",
      className: "vision_ref",
      ref: scrollToref,
    },
    {
      item: "Mission Mandate",
      className: "mission_ref",
      ref: scrollToref_mission,
    },
    {
      item: "Objectives",
      className: "objective_ref",
      ref: scrollToref_object,
    },
    {
      item: "Three Interlinked Programmes",
      className: "programme_ref",
      ref: scrollToref_programme,
    },
    {
      item: "Mera Gao Meri Dharohar",
      className: "features_ref",
      ref: scrollToref_gaon,
    },
    {
      item: "National Cultural Work Place",
      className: "features_ref",
      ref: scrollToref_work,
    },
    {
      item: "SANSKRITIK PRATIBHA KHOJ",
      className: "pratibha_ref",
      ref: scrollToref_pratibha,
    },
    {
      item: "Interesting Facts",
      className: "features_ref",
      ref: scrollToref_fact,
    },
    {
      item: "Other Resourceful Links",
      className: "features_ref",
      ref: scrollToref_links,
    },
  ];

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const setScroll = () => {
      setScrollTop(window.pageYOffset);
    };
    document
      .querySelector(".th-90-height-scroll")
      ?.addEventListener("scroll", setScroll);
    return () => {
      document
        .querySelector(".th-90-height-scroll")
        ?.removeEventListener("scroll", setScroll);
    };
  }, [scrollTop]);

  const scrollToSection = (event, ref) => {
    event.preventDefault();
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <MyLayout className="about_layout">
      <MyDesktop>
        <section className="container-fluid">
          <div style={{ position: "relative" }}>
            <img
              src={Mobile_Banner}
              alt="Web_Banner"
              width="100%"
              height="auto"
              className="custom-rounded-class-about-screen "
            />
            <div className="video-about-banner embed-responsive embed-responsive-21by9">
              <iframe
                src="https://www.youtube.com/embed/g825-m52XaE"
                className="iframe-video about-page-iframe embed-responsive-item"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                oallowfullscreen="true"
                msallowfullscreen="true"
                title="video"
                loading="lazy"
                style={{ borderRadius: "10px" }}
                marginHeight="0"
                marginWidth="0"
                scrolling="auto"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
                allowfullscreen="true"
              />
            </div>
          </div>
          <div>
            <div className="bg-white custom-rounded-class-about-screen my-3 p-5">
              <div className="row">
                <div
                  className="col-md-3 col-sm-2"
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "rgb(152 136 177 / 25%) 14px 2px 30px 4px",
                    borderRadius: "15px",
                    height: "76vh",
                    width: "20%",
                    overflow: "auto",
                    marginLeft: "5px",
                  }}
                >
                  <span style={{ padding: "5px" }}>
                    {topicList.map((item, index) => {
                      return (
                        <div
                          className={`${
                            item.className
                          } th-pointer action-btn my-2 text-capitalize col ${
                            selected == item.item
                              ? "th-fw-600 col selectiveColor"
                              : null
                          }`}
                          onClick={(event) => {
                            setSelected(item.item);
                            scrollToSection(event, item.ref);
                          }}
                        >
                          {item.item}
                        </div>
                      );
                    })}
                  </span>
                </div>
                <div className="col-md-9 px-2 about_layout">
                  <div
                    className="th-bg-white th-border-bottom th-border-top pt-5 px-4"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="first_part" ref={scrollToref}>
                      <h5>Vision</h5>
                      <div className="text-secondary">
                        <p className="text-secondary">
                          <b> National Mission on Cultural Mapping (NMCM)</b>{" "}
                          has been set up by the Ministry of Culture, Government
                          of India, with an aim to identify and document India’s
                          cultural heritage and its creative potential to
                          regenerate and revitalize rural economies, thereby
                          making Village India self-reliant.
                        </p>
                        <h6 className="pt-3">
                          The Cultural Mapping will function at three levels:{" "}
                        </h6>
                        <li className="py-1">
                          National Directories of artists and living human
                          treasures of cultural industries.
                        </li>
                        <li className="py-1">
                          Creation of National Digital Inventories/ Registers of
                          art expression and artist communities/bearers of
                          tradition.
                        </li>
                        <li className="py-1">
                          Evolve policies toward the preservation of art
                          practices and develop welfare schemes for their
                          practitioners.
                        </li>
                      </div>
                    </div>

                    <div className="pt-3 mission" ref={scrollToref_mission}>
                      <h5>Mission Mandate</h5>
                      <div>
                        <p className="text-secondary">
                          <li className="py-1">
                            To envision and address the necessity of preserving
                            the threads of rich Indian Art and Cultural
                            Heritage.
                          </li>
                          <li className="py-1">
                            To create a national database through Cultural
                            Mapping with the aid of extensive ground surveys and
                            documentation.
                          </li>
                          <li className="py-1">
                            To Design a mechanism to fulfill the aspirations of
                            the whole artiste community of the nation.
                          </li>
                          <li className="py-1">
                            To Preserve, safeguard, revitalize and disseminate
                            the rich cultural heritage of this country for
                            future generations.
                          </li>
                          <li className="py-1">
                            To create a strong <b>“Cultural Vibrancy”</b>{" "}
                            throughout the nation with the help of digital
                            platforms and outreach activities.
                          </li>
                        </p>
                      </div>
                    </div>

                    <div className="pt-3 objectives" ref={scrollToref_object}>
                      <h5>Objectives</h5>
                      <div className="text-secondary">
                        <p>
                          <li className="py-1">
                            To <b>culturally map</b> the art traditions and
                            cultural practices along with the <b>artists</b> and{" "}
                            <b>craft-persons</b> engaged in the preservation of
                            these art forms.
                          </li>
                          <li className="py-1">
                            To create awareness about the strengths of cultural
                            heritage of diverse communities of India and develop
                            an interface between culture and identity.
                          </li>
                          <li className="py-1">
                            To provide a single platform for dissemination of
                            knowledge in arts, and various welfare schemes of
                            the Government for the art practitioners and
                            enhancement of cultural resources.
                          </li>
                          <li className="py-1">
                            The above-mentioned objectives will be carried out
                            with the help of three interlinked programmes.
                          </li>
                        </p>
                      </div>
                    </div>

                    <div className="programme" ref={scrollToref_programme}>
                      <div className="heading">
                        <h5 className=" d-flex align-item-center justify-content-center my-2">
                          Three Interlinked Programmes
                        </h5>
                        <div
                          className="row pt-3 pb-5"
                          style={{ margin: "0px 40px", gap: "40px" }}
                        >
                          <div className="col content">
                            <div
                              className="p-1 text-center"
                              style={{ margin: "5px" }}
                            >
                              <h6 className="pt-4 pb-3 font-bold d-flex justify-content-center">
                                Sanskritik Pratibha khoj
                              </h6>
                              <p>
                                {" "}
                                <span className="d-flex justify-content-center text-secondary">
                                  National Cultural Awareness Campaign,talent
                                  hunt, and revitalization of folk and trabal
                                  heritage.
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col content">
                            <div className="p-1 text-center">
                              <h6 className="pt-4 pb-3 font-bold d-flex justify-content-center">
                                Hamari Sanskriti Hamari Pahchan Abhiyan
                              </h6>
                              <p className="text-secondary">
                                Cultural Mapping of art practices and
                                identifying artists and crafts persons.
                              </p>
                            </div>
                          </div>
                          <div className="col content">
                            <div className="p-1 text-center">
                              <h6 className="pt-4 pb-3 font-bold d-flex justify-content-center">
                                National Cultural Workplace(NCWP)
                              </h6>
                              <p className="text-secondary">
                                {" "}
                                NCWP will be an interactive Web portal for
                                artists and crafts persons, culminating in an
                                online platform for cultural Service Provider.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div ref={scrollToref_gaon} className="gaon">
                      <h5 className="font-bold">
                        {" "}
                        Meri Sanskrti Meri Pehchan /Mera Gaon Meri Dharohar
                        (MGMD)
                      </h5>
                      <div className="text-secondary">
                        <p>
                          National Cultural Work Place (NCWP)/ MGMD Portal
                          provides open access to the following facilities:
                        </p>
                        <li className="py-1">
                          {" "}
                          <b>Registration of art forms and artists.</b>
                        </li>
                        <li className="py-1">
                          <b>
                            Unique Identification Code (UIC) for the Artists.
                          </b>
                        </li>
                        <li className="py-1">
                          <b> Link to welfare schemes of the Government.</b>
                        </li>
                        <li className="py-1">
                          <b>
                            {" "}
                            Uploading facility for cultural events/ and art and
                            craft products/ Artists’ workshops
                          </b>
                        </li>
                        <li className="py-1">
                          <b>
                            {" "}
                            Editing and updating of village data by the users.
                          </b>
                        </li>
                        <li className="py-1">
                          <b> Virtual living museum.</b>
                        </li>
                        <li className="py-1">
                          <b>Village travel passport.</b>
                        </li>
                        <li className="py-1">
                          <b> Digital Indi-badges for online village travel.</b>
                        </li>
                      </div>
                    </div>
                    <div className="pt-3 work" ref={scrollToref_work}>
                      <h5 className="font-bold">
                        National Cultural Work Place (NCWP)
                      </h5>
                      <div className="text-secondary">
                        <li className="py-1">
                          {" "}
                          Mera Gaon Meri Dharohar (MGMD) is a component of of
                          NMCM. undertaken as a part of Azadi Ka Amrit Mahotsav
                          (AKAM).
                        </li>
                        <li className="py-1">
                          Under the MGMD cultural mapping of 6.5 lakh villages
                          is being carried out and more than 2 Lakh villages
                          have already been mapped and uploaded on the Mission
                          portal that serves as the National Cultural Work
                          Place.
                        </li>
                        <li className="py-1">
                          The cultural mapping covers several domains covering{" "}
                          <b>geographic, developmental and cultural profile </b>{" "}
                          including{" "}
                          <b>
                            village stories, traditional knowledge and wisdom
                            traditions, customs, ornaments, food, fairs and
                            festivals, rituals, village gods and goddesses,
                            physical and historical landmarks, architecture,
                            places of worship, art forms – oral, visual,
                            performed and crafted (folk songs, folk dances, folk
                            tales, legends, epics, folk theatre, handlooms,
                            handicrafts).
                          </b>
                        </li>
                        <li className="py-1">
                          <b>
                            360 degree angle video shoots of 750 villages has
                            been done under the project
                          </b>
                        </li>
                        <li className="py-1">
                          <b>
                            A webportal has been developed under the MGMD to
                            serve as the National Cultural Workplace and provide
                            a single window online platform for culture service
                            providers
                          </b>
                        </li>
                      </div>
                    </div>

                    <div className="pt-3 pratibha" ref={scrollToref_pratibha}>
                      <h5 className="font-bold">SANSKRITIK PRATIBHA KHOJ:</h5>
                      <div className="text-secondary">
                        <li className="py-1">
                          {" "}
                          Sanskritik Pratibha Khoj aims to launch a massive
                          awareness campaign and National Talent Hunt Talent in
                          the the country with a view to generate Cultural
                          Vibrancy in the country.
                        </li>
                        <li className="py-1">
                          Hold Village, Block District , State and National
                          Level Compititions, Seminar, Workshops, Exhibitions
                          etc.
                        </li>
                        <li className="py-1">
                          Prepare National Registers of Artists and Art Forms
                        </li>
                        <li className="py-1">
                          Branding and Ranking of Artists with a Unique
                          Identification Code
                        </li>
                      </div>
                    </div>

                    <div className="pt-3 facts" ref={scrollToref_fact}>
                      <div className="">
                        <h5 className="d-flex align-item-center justify-content-center my-2 font-bold">
                          Interesting Facts
                        </h5>
                        <h5 className="d-flex align-item-center justify-content-center my-2 font-bold">
                          From the villages surveyed so far
                        </h5>
                        <div className="row pt-5 pb-5 text-secondary">
                          <div className="col-md-6 col-lg-3">
                            <div className="p-1 text-center">
                              <div>
                                <img
                                  src={fact1}
                                  style={{ width: "100%", height: "auto" }}
                                />
                              </div>
                              <h6 className="pt-2 pb-2 font-bold ">
                                {" "}
                                <span className="d-flex justify-content-center text-align-center">
                                  Shani Shignapur, Ahmednagar,<br></br>
                                  Maharastra
                                </span>
                              </h6>
                              <p>
                                {" "}
                                <span className="d-flex justify-content-center">
                                  None of the house in the village have doors as
                                  they belive that Lord shani will protect them
                                  from theft and burglary.the village also has a
                                  famous Shani Temple.
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="p-1 text-center">
                              <img
                                src={fact2}
                                style={{ width: "100%", height: "auto" }}
                              />
                              <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                                Thiruchigadi, Nilgiri,<br></br> Tamilnadu
                              </h6>
                              <p>
                                {" "}
                                <span className="d-flex justify-content-center">
                                  This village is famous for the community of
                                  women potters. In the Nilgiri mountains of
                                  southern India. Only women of the Kota tribe
                                  have been engaged in the craft of pottery.{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="p-1 text-center">
                              <img
                                src={fact3}
                                style={{ width: "100%", height: "auto" }}
                              />
                              <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                                Khonoma, Kohima,<br></br>Nagaland
                              </h6>
                              <p>
                                Khonoma is India’s first green village. Khonoma
                                is an Angami Naga village situated near the
                                Indo-Myanmar border.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="p-1 text-center">
                              <img
                                src={fact4}
                                style={{ width: "100%", height: "auto" }}
                              />
                              <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                                Suketi, Siramaur,<br></br>Himachal Pradesh
                              </h6>
                              <p>
                                Asias oldest fossil park and also associated
                                with the Pajhota agitation.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-3 facts" ref={scrollToref_links}>
                      <div className="">
                        <h5 className="d-flex align-item-center justify-content-center my-2 font-bold">
                          Other Resourceful Links
                        </h5>
                        <div className="row pt-5 pb-5 text-secondary">
                          <div className="col-md-6 col-lg-3">
                            <div className="p-1 text-center">
                              <a href="https://vedicheritage.gov.in/">
                                <img
                                  src={"/Vedic Heritage.jpg"}
                                  style={{ width: "100%", height: "auto" }}
                                />
                              </a>
                              <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                                Vedic Heritage Portal
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="p-1 text-center">
                              <a href="https://ncaa.gov.in/repository/">
                                <img
                                  src={"/NCAA.jpg"}
                                  style={{ width: "100%", height: "auto" }}
                                />
                              </a>

                              <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                                National Cultural Audiovisual Archives
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="p-1 text-center">
                              <a href="https://www.namami.gov.in/">
                                <img
                                  src={"/National Mission for Manuscripts.jpg"}
                                  style={{ width: "100%", height: "auto" }}
                                />
                              </a>
                              <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                                National Mission for Manuscripts
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="p-1 text-center">
                              <a href="https://aatmanignca.org/">
                                <img
                                  src={"/ABCD.png"}
                                  style={{ width: "100%", height: "auto" }}
                                />
                              </a>

                              <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                                Aatmanirbhar Bharat Centre For Design (ABCD)
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </section>
      </MyDesktop>
      <MyMobile>
        <section className="About_mobile">
          <div className="container-fluid" style={{ padding: "0px" }}>
            <div
              id="offCanv"
              className="col-2 mt-3 th-bg-primary "
              style={{
                borderRadius: " 0px 10px 10px 0px",
                zIndex: "1000",
                top: "58px",
                position: "fixed",
                left: "-20px",
              }}
            >
              <div className="p-2 align-items-center d-flex justify-content-center">
                <div className="th-pointer " onClick={handleShow}>
                  <img
                    height="25px"
                    style={{ marginLeft: "17px" }}
                    src={MenuIcon}
                    alt="..."
                  />
                </div>
              </div>

              <Offcanvas
                show={show}
                onHide={handleClose}
                style={{ overflowY: "scroll" }}
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                  {topicList.map((item, index) => {
                    return (
                      <div
                        className={`${
                          item.className
                        } th-pointer action-btn my-2 text-capitalize col ${
                          selected == item.item
                            ? "th-fw-600 col selectiveColor"
                            : null
                        }`}
                        onClick={(event) => {
                          setSelected(item.item);
                          scrollToSection(event, item.ref);
                          handleClose();
                        }}
                      >
                        {item.item}
                      </div>
                    );
                  })}
                </Offcanvas.Body>
              </Offcanvas>
            </div>

            <div style={{ position: "relative" }}>
              <img
                src={Mobile_Banner}
                alt="Mobile_Banner"
                width="100%"
                height="auto"
                className="custom-rounded-class-about-screen"
              />
              <div className="video-about-banner">
                <iframe
                  src="https://www.youtube.com/embed/g825-m52XaE"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  title="video"
                  className="iframe-video responsive-iframe"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>

            <div className="bg-white px-3 pt-3 mb-4 mobile_about_allsection">
              <div className="mobile_first_part_about" ref={scrollToref}>
                <h5>Vision</h5>
                <p className="text-secondary">
                  <b> National Mission on Cultural Mapping (NMCM)</b> has been
                  set up by the Ministry of Culture, Government of India, with
                  an aim to identify and document India’s cultural heritage and
                  its creative potential to regenerate and revitalize rural
                  economies, thereby making Village India self-reliant.
                </p>
                <h6 className="pt-2">
                  The Cultural Mapping will function at three levels:{" "}
                </h6>
                <li className="py-1">
                  <span className="text-secondary">
                    National Directories of artists and living human treasures
                    of cultural industries.
                  </span>
                </li>
                <li className="py-1">
                  <span className="text-secondary">
                    Creation of National Digital Inventories/ Registers of art
                    expression and artist communities/bearers of tradition.
                  </span>
                </li>
                <li className="py-1">
                  Evolve policies toward the preservation of art practices and
                  develop welfare schemes for their practitioners.
                </li>
              </div>

              <div
                className="pt-3 mobile_intro_mission"
                ref={scrollToref_mission}
              >
                <h5 className="pb-2">Mission Mandate</h5>
                <div>
                  <p className="text-secondary">
                    <li className="py-1">
                      To envision and address the necessity of preserving the
                      threads of rich Indian Art and Cultural Heritage.
                    </li>
                    <li className="py-1">
                      To create a national database through Cultural Mapping
                      with the aid of extensive ground surveys and
                      documentation.
                    </li>
                    <li className="py-1">
                      To Design a mechanism to fulfill the aspirations of the
                      whole artiste community of the nation.
                    </li>
                    <li className="py-1">
                      To Preserve, safeguard, revitalize and disseminate the
                      rich cultural heritage of this country for future
                      generations.
                    </li>
                    <li className="py-1">
                      To create a strong <b>“Cultural Vibrancy”</b> throughout
                      the nation with the help of digital platforms and outreach
                      activities.
                    </li>
                  </p>
                </div>
              </div>

              <div
                className="pt-3 mobile_intro_objective"
                ref={scrollToref_object}
              >
                <h5>Objectives</h5>
                <div>
                  <p className="text-secondary">
                    <li className="py-1 ">
                      To <b>culturally map</b> the art traditions and cultural
                      practices along with the artists and craft-persons engaged
                      in the preservation of these art forms.
                    </li>
                    <li className="py-1">
                      To create awareness about the strengths of cultural
                      heritage of diverse communities of India and develop an
                      interface between culture and identity.
                    </li>
                    <li className="py-1">
                      To provide a single platform for dissemination of
                      knowledge in arts, and various welfare schemes of the
                      Government for the art practitioners and enhancement of
                      cultural resources.
                    </li>
                    <li className="py-1">
                      The above-mentioned objectives will be carried out with
                      the help of three interlinked programmes.
                    </li>
                  </p>
                  <div
                    className="mobile_first_part_programme"
                    ref={scrollToref_programme}
                  >
                    <div className="heading">
                      <h5 className=" d-flex align-item-center justify-content-center my-2">
                        Three Interlinked Programmes
                      </h5>
                      <div
                        className="row gy-3 pt-5 pb-5 "
                        style={{ paddingLeft: "40px", paddingRight: "40px" }}
                      >
                        <div className="col-12 content">
                          <div className="p-2 text-center">
                            <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                              Sanskritik Pratibha khoj
                            </h6>
                            <p>
                              {" "}
                              <span className="d-flex justify-content-center text-secondary">
                                National Cultural Awareness Campaign,talent
                                hunt, and revitalization of folk and trabal
                                heritage.
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-12 content pt-2">
                          <div className="p-2 text-center">
                            <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                              Hamari Sanskriti Hamari Pahchan Abhiyan
                            </h6>
                            <p className="text-secondary">
                              Cultural Mapping of art practices and identifying
                              artists and crafts persons.
                            </p>
                          </div>
                        </div>
                        <div className="col-12 content pt-2">
                          <div className="p-2 text-center">
                            <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                              National Cultural Workplace(NCWP)
                            </h6>
                            <p className="text-secondary">
                              NCWP will be an interactive Web portal for artists
                              and crafts persons, culminating in an online
                              platform for cultural Service Provider.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-3 mobile_gaon" ref={scrollToref_gaon}>
                <h5>
                  Meri Sanskrti Meri Pehchan /Mera Gaon Meri Dharohar (MGMD)
                </h5>
                <div className="text-secondary">
                  <p>
                    <div>
                      <li className="py-1">
                        Mera Gaon Meri Dharohar (MGMD) is a component of of
                        NMCM. undertaken as a part of Azadi Ka Amrit Mahotsav
                        (AKAM).{" "}
                      </li>
                      <li className="py-1">
                        Under the MGMD cultural mapping of 6.5 lakh villages is
                        being carried out and more than 2 Lakh villages have
                        already been mapped and uploaded on the Mission portal
                        that serves as the National Cultural Work Place.
                      </li>
                      <li className="py-1">
                        The cultural mapping covers several domains covering{" "}
                        <b>geographic, developmental and cultural profile</b>{" "}
                        including{" "}
                        <b>
                          village stories, traditional knowledge and wisdom
                          traditions, customs, ornaments, food, fairs and
                          festivals, rituals, village gods and goddesses,
                          physical and historical landmarks, architecture,
                          places of worship, art forms – oral, visual, performed
                          and crafted (folk songs, folk dances, folk tales,
                          legends, epics, folk theatre, handlooms, handicrafts).
                        </b>
                      </li>
                      <li className="py-1">
                        <b>
                          360 degree angle video shoots of 750 villages has been
                          done under the project
                        </b>
                      </li>
                      <li className="py-1">
                        <b>
                          A webportal has been developed under the MGMD to serve
                          as the National Cultural Workplace and provide a
                          single window online platform for culture service
                          providers
                        </b>
                      </li>
                    </div>
                  </p>
                </div>
              </div>

              <div className="pt-3 mobile_work" ref={scrollToref_work}>
                <h5>National Cultural Work Place (NCWP)</h5>
                <div className="text-secondary">
                  <p>
                    National Cultural Work Place (NCWP)/ MGMD Portal provides
                    open access to the following facilities:
                    <div>
                      <li className="py-1">
                        {" "}
                        <b>Registration of art forms and artists.</b>
                      </li>
                      <li className="py-1">
                        <b>Unique Identification Code (UIC) for the Artists.</b>
                      </li>
                      <li className="py-1">
                        <b> Link to welfare schemes of the Government.</b>
                      </li>
                      <li className="py-1">
                        <b>
                          {" "}
                          Uploading facility for cultural events/ and art and
                          craft products/ Artists’ workshops
                        </b>
                      </li>
                      <li className="py-1">
                        <b>
                          {" "}
                          Editing and updating of village data by the users.
                        </b>
                      </li>
                      <li className="py-1">
                        <b> Virtual living museum.</b>
                      </li>
                      <li className="py-1">
                        <b>Village travel passport.</b>
                      </li>
                      <li className="py-1">
                        <b> Digital Indi-badges for online village travel.</b>
                      </li>
                    </div>
                  </p>
                </div>
              </div>

              <div className="pt-3 mobile_pratibha" ref={scrollToref_pratibha}>
                <h5>SANSKRITIK PRATIBHA KHOJ:</h5>
                <div className="text-secondary">
                  <p>
                    Sanskritik Pratibha Khoj aims to launch a massive awareness
                    campaign and National Talent Hunt Talent in the the country
                    with a view to generate Cultural Vibrancy in the country.
                    <div>
                      <li className="py-1">
                        {" "}
                        <b>
                          Hold Village, Block District , State and National
                          Level Compititions, Seminar, Workshops, Exhibitions
                          etc.{" "}
                        </b>
                      </li>
                      <li className="py-1">
                        <b>
                          Prepare National Registers of Artists and Art Forms
                        </b>
                      </li>
                      <li className="py-1">
                        <b>
                          {" "}
                          Branding and Ranking of Artists with a Unique
                          Identification Code
                        </b>
                      </li>
                    </div>
                  </p>
                </div>
              </div>

              <div className="pt-3 mobile_facts" ref={scrollToref_fact}>
                <div>
                  <h5 className="d-flex align-item-center justify-content-center my-2 font-bold">
                    Interesting Facts
                  </h5>
                  <h5 className="d-flex align-item-center justify-content-center my-2 font-bold">
                    From the villages surveyed so far
                  </h5>
                  <div className="row gy-3 pt-5 pb-5 text-secondary px-1">
                    <div className="col-12 content">
                      <div className="p-1 text-center">
                        <div>
                          <img
                            src={fact1}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                        <h6 className="pt-2 pb-2 font-bold ">
                          {" "}
                          <span className="d-flex justify-content-center text-align-center">
                            Shani Shignapur, Ahmednagar,<br></br>Maharastra
                          </span>
                        </h6>
                        <p>
                          {" "}
                          <span className="d-flex justify-content-center">
                            None of the house in the village have doors as they
                            belive that Lord shani will protect them from theft
                            and burglary.the village also has a famous Shani
                            Temple.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-12 content">
                      <div className="p-1 text-center">
                        <img
                          src={fact2}
                          style={{ width: "100%", height: "auto" }}
                        />
                        <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                          Thiruchigadi, Nilgiri,<br></br> Tamilnadu
                        </h6>
                        <p>
                          {" "}
                          <span className="d-flex justify-content-center">
                            This village is famous for the community of women
                            potters. In the Nilgiri mountains of southern India.
                            Only women of the Kota tribe have been engaged in
                            the craft of pottery.{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-12 content">
                      <div className="p-1 text-center">
                        <img
                          src={fact3}
                          style={{ width: "100%", height: "auto" }}
                        />
                        <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                          Khonoma, Kohima,<br></br>Nagaland
                        </h6>
                        <p>
                          Khonoma is India’s first green village. Khonoma is an
                          Angami Naga village situated near the Indo-Myanmar
                          border.
                        </p>
                      </div>
                    </div>
                    <div className="col-12 content">
                      <div className="p-1 text-center">
                        <img
                          src={fact4}
                          style={{ width: "100%", height: "auto" }}
                        />
                        <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                          Suketi, Siramaur,<br></br>Himachal Pradesh
                        </h6>
                        <p>
                          Asias oldest fossil park and also associated with the
                          Pajhota agitation.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-3 mobile_facts" ref={scrollToref_links}>
                <div>
                  <h5 className="d-flex align-item-center justify-content-center my-2 font-bold">
                    Other Resourceful links
                  </h5>
                  <div className="row gy-3 pt-5 pb-5 text-secondary px-1">
                    <div className="col-12 content">
                      <div className="p-1 text-center">
                        <a href="https://vedicheritage.gov.in/">
                          <img
                            src={"/Vedic Heritage.jpg"}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </a>
                        <h6 className="pt-2 pb-2 font-bold ">
                          <span className="d-flex justify-content-center text-align-center">
                            Vedic Heritage Portal
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 content">
                      <div className="p-1 text-center">
                        <a href="https://ncaa.gov.in/repository/">
                          <img
                            src={"/NCAA.jpg"}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </a>
                        <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                          National Cultural Audiovisual Archives
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 content">
                      <div className="p-1 text-center">
                        <a href="https://www.namami.gov.in/">
                          <img
                            src={"/National Mission for Manuscripts.jpg"}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </a>
                        <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                          National Mission for Manuscripts
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 content">
                      <div className="p-1 text-center">
                        <a href="https://aatmanignca.org/">
                          <img
                            src={"/ABCD.png"}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </a>
                        <h6 className="pt-2 pb-2 font-bold d-flex justify-content-center">
                          Aatmanirbhar Bharat Centre For Design (ABCD)
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MyMobile>
    </MyLayout>
  );
};

export default About;
