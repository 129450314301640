import React, { Component } from "react";

class MenuSelect extends Component {
  onChange = (event) => {
    const value = event.target.value;
    this.props?.onChangeFun_(value, this.props.f_name);

    this.props?.onChangeFunc_ &&
      this.props?.onChangeFunc_(value, this.props.f_name);
  };

  render() {
    const { passedDownItems, selectedValue, f_name } = this.props;
    const sortedItems = passedDownItems?.sort(function (a, b) {
      let x = a.value.toLowerCase();
      let y = b.value.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    return (
      <select
        className={`${this.props?.className} menu-select`}
        data-live-search="true"
        onChange={(e) => {
          this.onChange(e);
        }}
        style={{ marginRight: "5px" }}
        value={selectedValue}
      >
        <option disabled value={""}>
          {f_name === "State" ? "State/UT" : f_name}
        </option>
        {passedDownItems?.map((item, index) => (
          <option
            className="text-uppercase"
            style={{ textTransform: "uppercase" }}
            key={index}
            value={item.value}
          >
            {item.value}
          </option>
        ))}
      </select>
    );
  }
}

export default MenuSelect;
